import React, { useState } from 'react'

// assets
import * as images from "../../assets/images/icons";
import YouTubeModal from "../../components/YouTubeModal";

// constants
import { LANG } from "../../constants";


const VideoGallery = ({ slice, context }) => {
  const [show, setShow] = useState(false)
  const [modalDetails, setModalDetails] = useState({})
  const sectionTitle = slice.primary.title?.text || ''
  const sectionDescription = slice.primary.description?.text || ''
  const galleryItems = slice.items

  const pageLang = context.lang === LANG.EN ? LANG.EN : LANG.FR

  const onPlayBtnClick = (data) => {
    setShow(true)
    setModalDetails(data)
  }

  const onCloseBtnClick = ()=>{
    setShow(false)
    setModalDetails({})
  }

  return <section className="video-gallery">
    <div className="video-gallery__header">
      <h2>{ sectionTitle }</h2>
      <p> { sectionDescription } </p>
    </div>
    <div className="video-gallery__list">
      { galleryItems.map(({gallery_image, title, description, youtube_src})=>{

        const modalDetails = {
          title: title.richText,
          description: description.text,
          url: youtube_src.text
        }

        return <div className="video-gallery__item" key={title.text}>
          <div className="video-gallery__item-img">
            <div className="video-gallery__item-play-btn">
              <img src={images.playVideoBtnWebp} alt="Play Button" className="play-btn" onClick={() => onPlayBtnClick(modalDetails)}/>
            </div>
            <img className="video-gallery__item-thumb" src={gallery_image.url} alt={gallery_image.alt}/>
          </div>
          <div className="video-gallery__item-title">{title?.richText[0]?.text} | {title?.richText[1]?.text}</div>
        </div>
      })}
    </div>
    { show && modalDetails.url && <YouTubeModal modalDetails={modalDetails} closeModal={onCloseBtnClick} lang={pageLang}/> }
  </section>
}


export default VideoGallery