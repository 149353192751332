// core
import React, { useEffect, useState } from "react";


// constants
import { NUTRITIONAL_TABLE_TEXT_EN, NUTRITIONAL_TABLE_TEXT_FR } from "../../constants";
import parse from "html-react-parser";


const NutritionAndIngredientsTable = ({ slice }) => {
  const [currentLang, setCurrentLang] = useState('')
  // console.log(slice)
  const item = slice.items[0];

  const translation = currentLang === "EN" ? NUTRITIONAL_TABLE_TEXT_EN  : NUTRITIONAL_TABLE_TEXT_FR

  const values = {
    calories : item.calories || 100,
    fat_grams: item.fat_g || 0,
    fat_percentage: item.fat_percentage || 0,
    saturated_grams: item.saturated_g || 0,
    saturated_percentage:item.saturated_percentage || 0,
    trans_grams: item.trans_g || 0,
    trans_percentage: item.trans_percentage || 0,
    carbohydrate_grams: item.carbohydrate_g|| 0,
    fiber_grams: item.fibre_g || 0,
    fiber_percentage: item.fibre_percentage || 0,
    sugars_grams: item.sugars_g || 0,
    sugars_percentage: item.sugars_percentage || 0,
    protein_grams: item.protein_g || 0,
    cholesterol_mgrams: item.cholesterol_mg|| 0,
    sodium_mgrams: item.sodium_mg || 0,
    sodium_percentage: item.sodium_percentage || 0,
    potassium_mgrams: item.potassium_mg || 0,
    potassium_percentage: item.potassium_percentage || 0,
    calcium_mgrams: item.calcium_mg|| 0,
    calcium_percentage: item.calcium_percentage || 0,
    iron_mgrams: item.iron_mg || 0,
    iron_percentage: item.iron_percentage || 0,


    daily_value: item.daily_value.text || parse(translation.dailyValue),
    ingredients: item.ingredients.text || ''
  }

  useEffect(()=>{
    setCurrentLang(item.page_lang)
  }, [])

  return (
      <>
        <tbody>
        <tr className="product-nutrition__row product-nutrition__row--no-underline">
          <td className="product-nutrition__row-left">{translation.amountPerServing}</td>

          <td className="product-nutrition__row-right"></td>
        </tr>
        <tr className="product-nutrition__row product-nutrition__row--large-header product-nutrition__row--dark-underline product-nutrition__row--bold-text-left">
          <td className="product-nutrition__row-left">Calories</td>
          <td className="product-nutrition__row-right">
            { values.calories }
          </td>
        </tr>
        <tr className="product-nutrition__row product-nutrition__row--light-underline">
          <td className="product-nutrition__row-left"></td>
          <td className="product-nutrition__row-right">{translation.dailyValuePercentage}</td>
        </tr>
        <tr className="product-nutrition__row product-nutrition__row--light-underline product-nutrition__row--bold-text-left">
          <td className="product-nutrition__row-left">
            {translation.fat} { values.fat_grams } g
          </td>
          <td className="product-nutrition__row-right">
            { values.fat_percentage } %
          </td>
        </tr>
        <tr className="product-nutrition__row product-nutrition__row--indented product-nutrition__row--light-underline">
          <td className="product-nutrition__row-left">
            {translation.saturated} { values.saturated_grams } g
          </td>
          <td className="product-nutrition__row-right">
            { values.saturated_percentage } %
          </td>
        </tr>
        <tr className="product-nutrition__row product-nutrition__row--indented product-nutrition__row--light-underline">
          <td className="product-nutrition__row-left">
            {translation.trans} { values.trans_grams } g
          </td>
          <td className="product-nutrition__row-right">
            { values.trans_percentage } %
          </td>
        </tr>
        <tr className="product-nutrition__row product-nutrition__row--light-underline product-nutrition__row--bold-text-left">
          <td className="product-nutrition__row-left">
            {translation.carbohydrate} { values.carbohydrate_grams } g
          </td>
          <td className="product-nutrition__row-right"></td>
        </tr>
        <tr className="product-nutrition__row product-nutrition__row--indented product-nutrition__row--light-underline">
          <td className="product-nutrition__row-left">
            {translation.fiber} { values.fiber_grams } g
          </td>
          <td className="product-nutrition__row-right">
            { values.fiber_percentage } %
          </td>
        </tr>
        <tr className="product-nutrition__row product-nutrition__row--indented product-nutrition__row--light-underline">
          <td className="product-nutrition__row-left">
            {translation.sugars} { values.sugars_grams } g
          </td>
          <td className="product-nutrition__row-right">
            { values.sugars_percentage } %
          </td>
        </tr>
        <tr className="product-nutrition__row product-nutrition__row--light-underline product-nutrition__row--bold-text-left">
          <td className="product-nutrition__row-left">
            {translation.protein} { values.protein_grams } g
          </td>
          <td className="product-nutrition__row-right"></td>
        </tr>
        <tr className="product-nutrition__row product-nutrition__row--light-underline product-nutrition__row--bold-text-left">
          <td className="product-nutrition__row-left">
            {translation.cholesterol} { values.cholesterol_mgrams } mg
          </td>
          <td className="product-nutrition__row-right"></td>
        </tr>
        <tr className="product-nutrition__row product-nutrition__row--dark-underline product-nutrition__row--bold-text-left">
          <td className="product-nutrition__row-left">
            {translation.sodium} { values.sodium_mgrams } mg
          </td>
          <td className="product-nutrition__row-right">
            { values.sodium_percentage } %
          </td>
        </tr>
        <tr className="product-nutrition__row product-nutrition__row--light-underline product-nutrition__row--bold-text-left">
          <td className="product-nutrition__row-left">
            {translation.potassium} { values.potassium_mgrams } mg
          </td>
          <td className="product-nutrition__row-right">
            { values.potassium_percentage } %
          </td>
        </tr>
        <tr className="product-nutrition__row product-nutrition__row--light-underline product-nutrition__row--bold-text-left">
          <td className="product-nutrition__row-left">
            {translation.calcium} { values.calcium_mgrams } mg
          </td>
          <td className="product-nutrition__row-right">
            { values.calcium_percentage } %
          </td>
        </tr>
        <tr className="product-nutrition__row product-nutrition__row--dark-underline product-nutrition__row--bold-text-left">
          <td className="product-nutrition__row-left">
            {translation.iron} { values.iron_mgrams } mg
          </td>
          <td className="product-nutrition__row-right">
            { values.iron_percentage } %
          </td>
        </tr>
        <tr className="product-nutrition__footer product-nutrition__footer--first">
          <td colSpan="2">
            <p>
              { values.daily_value }
            </p>
          </td>
        </tr>
        <tr className="product-nutrition__footer">
          <td colSpan="2"><h3>{translation.ingredients}</h3></td>
        </tr>
        <tr className="product-nutrition__footer">
          <td colSpan="2"><p>
            { values.ingredients }
          </p></td>
        </tr>
        </tbody>
      </>
  );
};

export default NutritionAndIngredientsTable