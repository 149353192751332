import React, {useEffect, useRef} from 'react'
import {Link} from "gatsby";
import { useLocation } from "@reach/router";

//assets
import useWindowSize from "../../assets/js/useWindowSize";

// data-layer
import { setLearnMoreClickedEvent } from "../../assets/js/data-layer";

const HeroVideoSection = ({ slice }) => {
  const location = useLocation()
  const { width } = useWindowSize();
  const playerRef = useRef()
  const button = {
    href: slice.primary.button_link.url,
    text: slice.primary.button_text.text,
  }

  const video = {
    src: slice.primary.video_src.url,
    mobileSrc: slice.primary.video_mobile_src.url
  }

  useEffect(()=>{
    if (!playerRef?.current) return

    playerRef.current.addEventListener('suspend', () => {
      playerRef.current.play()
    });

  },[])

  return (
      <section className='campaign-video-hero'>
        <video className='campaign-video-hero__video'
               ref={playerRef}
               src={`${ width <= 768 ? video.mobileSrc : video.src}`}
               width="100%"
               height="100%"
               playsInline
               autoPlay
               muted
               loop
               poster=""/>
        <div className='helper-bottom'></div>
        <div className='helper-right'></div>
        <Link
            to={button.href || ''}
            target="_blank"
            title={button.text}
            className="button-white campaign-video-hero__button"
            onClick={()=>{
              const dl = {
                url: button.href,
                referrer: location.pathname,
                name: button.text.toLowerCase()
              }
              setLearnMoreClickedEvent(dl)
          }}
        >
          {button.text}
        </Link>
      </section>
  )
}

export default HeroVideoSection