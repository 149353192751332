import React,{useState} from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import useWindowSize from '../../hooks/useWindowSize'

const OurStoryNav=({pageData,index,element,handleSecondLevelMenuClick,activeDropdownMenu,closeOurStoryNavBar,hoverMenu}) => {
    const { width } = useWindowSize()
    return (
        <li key={index} className="nav-item"
            onClick={(e) => handleSecondLevelMenuClick(e,index)}
            onMouseOver={(e) => {
                if(width>=991&&hoverMenu===true) {
                    handleSecondLevelMenuClick(e,index)
                }
            }}
        >
            <button className="nav-item__dd" onClick={(e) => handleSecondLevelMenuClick(e,index)}
                onMouseOver={(e) => {
                    if(width>=991&&hoverMenu===true) {
                        handleSecondLevelMenuClick(e,index)
                    }
                }}
            >
                <span className='nav-item__dd-trigger'>{element.primary.link_text}</span>
                <span>
                    {
                        activeDropdownMenu===index?
                            <i className="arrow up"></i>
                            :<i className="arrow down"></i>
                    }
                </span>
            </button>
            {
                activeDropdownMenu!==null&&activeDropdownMenu===index&&
                <ul className='sub-menu mega-menu mega-menu-column-4 our-story '>
                    {
                        pageData.body[activeDropdownMenu]?.items.map((childElm,childElmIndex) => {
                            return (
                                <li key={'child-our-story'+childElmIndex} className='list-item'>
                                    {childElmIndex===0&&<div className='our-story-mobile'></div>}
                                    <Link
                                        to={childElm.third_level_link.url}
                                        target="_self"
                                        title="White Claw"
                                        onClick={() => closeOurStoryNavBar()}
                                    >
                                        <img src={childElm.third_level_link_image?.url} className='img-fluid' alt={childElm.third_level_link_image?.alt} />
                                    </Link>
                                    <h4 className='title'>
                                        <Link
                                            to={childElm.third_level_link.url}
                                            target="_self"
                                            title="White Claw"
                                            onClick={() => closeOurStoryNavBar()}
                                        >
                                            {childElm.third_level_link_text}
                                        </Link>
                                    </h4>
                                </li>
                            )
                        })
                    }
                </ul>
            }
            <FontAwesomeIcon icon={ faChevronRight } className="fa-solid nav-item__chevron_right" size="xl" aria-hidden="false"/>
        </li>
    )
}

export default OurStoryNav
