// core
import React, { useRef, useEffect } from 'react'
import { graphql } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpRightFromSquare} from "@fortawesome/free-solid-svg-icons/faUpRightFromSquare";
import {faDownload} from "@fortawesome/free-solid-svg-icons/faDownload";

// assets
import useWindowSize from '../../hooks/useWindowSize'
import { downloadMedia } from '../../assets/js/downloadMedia';
import { getButtonCustomStyles } from '../../assets/js/getButtonCustomStyles';
import { getFontSize } from '../../assets/js/getFontSize';
import useVisibility from '../../utils/visibilityHook';
// constants
import { COLOR } from "../../constants/colors";

import {AnchorLink} from "../../components/AnchorLink";



export const SplitBanner = ({ slice }) => {
  // console.log('SplitBanner', slice)

  const { width } = useWindowSize()
  const playerRef = useRef()
  const isMobile = width <= 768;
  const componentRef = useRef(null);
  const isVisible = useVisibility(componentRef);
  const sectionId = slice.primary.section_id?.text || slice.id
  const anchorLink = slice.primary.anchor_link?.text || ''
  

  // Links
  const isDownloadLink = slice.primary.cta_download_button
  const isTargetBlank = slice.primary.cta_button_open_in_new_tab
  const target = isTargetBlank ? '_blank' : '_self'

  // Toggles
  const showTitle = slice.primary.show_title
  const showSubTitle = slice.primary.show_subtitle
  const showParagraph = slice.primary.show_paragraph
  const showCTAButton = slice.primary.show_cta_button
  const showLabel = slice.primary.show_label
  const githubVideoFilename = width < 768 ? slice.primary?.github_video_filename_mobile : slice.primary?.github_video_filename_desktop;
  const githubVideoFilenameTrimmed = githubVideoFilename ? githubVideoFilename.trim() : ''


  const contentReverse = slice.primary.reverse_content ? 'reverse': ''
  const contentTypeImage = slice.primary.content_type
  const allowTransition = slice.primary.allow_transition

  // Text
  const label = slice.primary.label?.text || ''

  const title = slice.primary.title?.richText || []
  const titleTextColor = slice.primary.title_text_color || COLOR.BLACK
  const titleTextSizeDesktop = slice.primary.title_font_size_desktop || '64'
  const titleTextSizeMobile = slice.primary.title_font_size_mobile || '32'
  const titleTextFontSize = getFontSize(width, titleTextSizeDesktop, titleTextSizeMobile)


  const subTitle = slice.primary.subtitle?.richText || []
  const subTitleTextColor = slice.primary.subtitle_text_color || COLOR.BLACK
  const subTitleFontSizeDesktop = slice.primary.subtitle_font_size_desktop || '38'
  const subTitleFontSizeMobile = slice.primary.subtitle_font_size_mobile || '20'
  const subTitleFontSize = getFontSize(width, subTitleFontSizeDesktop, subTitleFontSizeMobile)


  const paragraph = slice.primary.paragraph?.richText || []
  const paragraphTextColor = slice.primary.paragraph_text_color || COLOR.BLACK
  const paragraphSpacingDesktop = slice.primary.paragraph_spacing_desktop || '0'
  const paragraphSpacingMobile = slice.primary.paragraph_spacing_mobile || '0'
  const paragraphTextSizeDesktop = slice.primary.paragraph_font_size_desktop || '24'
  const paragraphTextSizeMobile = slice.primary.paragraph_font_size_mobile || '20'
  const paragraphH6TextSizeDesktop = slice.primary.paragraph_h6_font_size_desktop || '12'
  const paragraphH6TextSizeMobile = slice.primary.paragraph_h6_font_size_mobile || '16'
  const paragraphTextSizeSize = getFontSize(width, paragraphTextSizeDesktop, paragraphTextSizeMobile)
  const paragraphH6TextSizeSize = getFontSize(width, paragraphH6TextSizeDesktop, paragraphH6TextSizeMobile)
  const paragraphRemoveSpacingMobile = slice.primary.remove_paragraph_spacing_mobile

  // CTA Button
  const ctaButtonText = slice.primary.cta_button_text?.text || ''
  const ctaDownloadButtonFileName = slice.primary.cta_download_file_name || ''

  // CTA Button Link
  const ctaButtonLink = slice.primary.cta_button_link?.url || ''
  const ctaButtonDownloadLink = slice.primary.cta_download_button_file?.url || ''

  // styles
  const sectionStyles = {
    backgroundColor: slice.primary.section_bg_color || COLOR.WHITE,
  }

  const labelStyles = {
    color: slice.primary.label_text_color || COLOR.BLACK,
    backgroundColor: slice.primary.label_bg_color || COLOR.WHITE,
    border: `3px solid ${slice.primary.label_border_color || COLOR.BLACK}`,
  }

  const titleStyles = {
    color: titleTextColor,
    fontSize: titleTextFontSize
  }

  const subTitleStyles = {
    color: subTitleTextColor,
    fontSize: subTitleFontSize
  }

  const paragraphStyles = {
    color: paragraphTextColor,
    fontSize: paragraphTextSizeSize,
    display: paragraphRemoveSpacingMobile && isMobile ? 'inline' : 'block',
    marginBottom: isMobile ? `${paragraphSpacingMobile}px` : `${paragraphSpacingDesktop}px`
  }

  const paragraphH6Styles = {
    color: paragraphTextColor,
    fontSize: paragraphH6TextSizeSize,
    display: paragraphRemoveSpacingMobile && isMobile ? 'inline' : 'block',
    marginBottom: isMobile ? `${paragraphSpacingMobile}px` : `${paragraphSpacingDesktop}px`
  }
  const titleTransition = showTitle && allowTransition ?( contentReverse?'animation-right':'animation-left'):''
   const subTitleTransition = showSubTitle && allowTransition?( contentReverse?'animation-right':'animation-left'):''
  // CTA Styles
  const buttonColorSet = {
    color: slice.primary.cta_button_text_color,
    bg: slice.primary.cta_button_bg_color,
    bgT: slice.primary.cta_button_bg_color_transparency,
    border: slice.primary.cta_button_border_color,
    colorHover: slice.primary.cta_button_text_color_hover,
    bgHover: slice.primary.cta_button_bg_color_hover,
    bgHoverT: slice.primary.cta_button_bg_color_hover_transparency,
    borderHover: slice.primary.cta_button_border_color_hover,
  }

  const { ctaButtonStyle } = getButtonCustomStyles(buttonColorSet)


  // image
  const image = {
    src: width < 768 ? slice.primary.banner?.thumbnails?.mobile.url : slice.primary.banner?.url,
    alt: slice.primary.banner?.alt || ''
  }

  // Determine the video source
  const videoSrc = githubVideoFilenameTrimmed ? `/videos/${githubVideoFilenameTrimmed}` : ''

  useEffect(() => {
    if (!playerRef?.current) return

    playerRef.current.addEventListener('suspend', () => {
      playerRef.current?.play()
    });

  }, [])

  return (<section ref={componentRef} className="split-banner" style={sectionStyles} id={`split-banner-${sectionId}`}>
    { anchorLink && <AnchorLink link={anchorLink}/> }

    <div className={`split-banner__wrapper ${contentReverse}`}>
      <div className='split-banner__column split-banner__column--content'>
        <div className={`split-banner__content-wrapper ${contentReverse}`}>

        {showLabel && <p className='split-banner__label' style={labelStyles}> {label}</p> }

        {showTitle && <h3 className={`split-banner__title ${allowTransition ?(contentReverse?'transition-right':'transition-left'):''} ${isVisible?titleTransition:''}`}>
          {title.map((row, index) => {
            return <span key={index} style={titleStyles}>
              <PrismicRichText field={[row]}/><br />
            </span>
          })
          }
        </h3>}

        {showSubTitle && <h4 className={`split-banner__subtitle ${allowTransition ?(contentReverse?'transition-right':'transition-left'):''} ${isVisible?subTitleTransition:''}`}>
          {subTitle.map((row, index) => {
            return <span key={index} style={subTitleStyles}>{row.text}<br /></span>
          })
          }
        </h4>}

        {showParagraph && <p className={`split-banner__paragraph  ${allowTransition?'fade-in-animation-enable-split':""} ${isVisible && allowTransition ?'fade-in-bottom-up-split':''}`}>
          {paragraph.map((row, index) => {
            return (
              <span key={index} style={row.type === 'heading6' ? paragraphH6Styles : paragraphStyles}>
                {row.text}
                <br style={{ display: paragraphRemoveSpacingMobile && isMobile ? 'none' : 'block' }} />
              </span>
            )
          })
          }
        </p>}

        {showCTAButton && <>
          { !isDownloadLink && <a href={ctaButtonLink} title={ctaButtonText} className={`split-banner__cta-button  ${allowTransition?'fade-in-animation-enable-split':""} ${isVisible && allowTransition ?'fade-in-bottom-up-split':''}`} style={ctaButtonStyle} target={target}>
            { ctaButtonText }
            { isTargetBlank && <FontAwesomeIcon icon={faUpRightFromSquare} className='ml-2'/>}
          </a> }

          { isDownloadLink && <button title={ctaButtonText} className={`split-banner__cta-button   ${allowTransition?'fade-in-animation-enable-split':""} ${isVisible && allowTransition ?'fade-in-bottom-up-split':''}`} style={ctaButtonStyle}
                                      onClick={()=>downloadMedia(ctaButtonDownloadLink, ctaDownloadButtonFileName)}
          >
            { ctaButtonText }
            <FontAwesomeIcon icon={faDownload} className='ml-2'/>
          </button> }
            </>
        }
        </div>
      </div>
      <div className='split-banner__column split-banner__column--media'>
        { contentTypeImage  && <img src={image.src} alt={image.alt} className='split-banner__img'/> }
        { !contentTypeImage && <video className='split-banner__video' playsInline autoPlay muted loop width='100%' height='100%' ref={playerRef} src={videoSrc}/> }
      </div>
    </div>
  </section>);

}

export const fragment = graphql`
  fragment PageDataBodySplitBanner on PrismicGenericPageDataBodySplitBanner  {
    id
    primary {
      section_id{
        text
      }
      anchor_link{
        text
      }
      github_video_filename_mobile
      github_video_filename_desktop
      banner {
        thumbnails {
          mobile {
            alt
            url
          }
        }
        url(imgixParams: {q: 100, dpi: 300, dpr: 2, auto: "enhance"})
        alt
      }
      cta_button_link {
        url
      }
      reverse_content
      content_type
      show_cta_button
      show_title
      show_subtitle
      show_paragraph
      show_label
      allow_transition
      cta_same_window
      cta_button_bg_color
      cta_button_bg_color_hover
      cta_button_border_color
      cta_button_border_color_hover
      cta_button_text {
        text
        richText
      }
      cta_button_text_color
      cta_button_text_color_hover
      cta_button_bg_color_transparency
      cta_button_bg_color_hover_transparency
      cta_button_open_in_new_tab
      cta_download_button
      cta_download_button_file {
        url
      }
      cta_download_file_name
      title {
        text
        richText
      }
      title_text_color
      title_font_size_desktop
      title_font_size_mobile
      subtitle {
        text
        richText
      }
      subtitle_text_color
      subtitle_font_size_desktop
      subtitle_font_size_mobile
      paragraph {
        text
        richText
      }
      remove_paragraph_spacing_mobile
      paragraph_spacing_mobile
      paragraph_spacing_desktop
      paragraph_text_color
      paragraph_h6_font_size_desktop
      paragraph_h6_font_size_mobile
      paragraph_font_size_desktop
      paragraph_font_size_mobile
      label {
        text
        richText
      }
      label_text_color
      label_bg_color
      label_border_color
      section_bg_color
    }
  }`