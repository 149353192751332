import React from 'react'

// assets
import useWindowSize  from "../../assets/js/useWindowSize";

// components
import ImgWithFallback from "../../components/ImgWithFallback";

const ProductRangeSection = ({ slice }) => {
  const { width } = useWindowSize()

  const titleRows = slice.primary.title.richText
  const productItems = slice.items

  const bgImageUrl = {
    mobile: slice.primary.background_image_mobile.url,
    screen: slice.primary.background_image.url
  }

  return (
      <section className='range'
               style={{backgroundImage: `${ width <= 992 ? `url(${bgImageUrl.mobile})` : `url(${bgImageUrl.screen})`}`}}
      >
        <div className='container range__container'>
          <div className='range__content'>
            <div className='range__title-container'>
              <h3 className='range__title pb-0'>
                {titleRows && titleRows.map((row) => {
                  return  <span key={row.text}>{row.text}<br/></span>
                }) }
              </h3>
            </div>
            <div className='range__items-container'>
              {productItems?.length && productItems.map(({product_image, product_image_webp}) => {
                return < ImgWithFallback
                    classNameImg='range__item'
                    src={product_image.url}
                    alt={product_image.alt}
                    fallbackSrc={product_image.url}
                    key={product_image.url}
                />
              })}
            </div>
          </div>
        </div>
      </section>
  )
}

export default ProductRangeSection