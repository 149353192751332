
import React, { useState } from 'react'

// assets
import * as images from "../../assets/images/icons";
import { PrismicRichText } from "@prismicio/react";
import YouTubeModal from "../../components/YouTubeModal";

// constants
import { LANG } from "../../constants";


const HeroBannerWithModal = ({ slice, context }) => {
  const [show, setShow] = useState(false)
  const [modalDetails, setModalDetails] = useState({})
  const pageLang = context.lang === LANG.EN ? LANG.EN : LANG.FR
  const sectionTitle = slice.primary.title?.richText || ''
  const sectionTitleIntro = slice.primary.title_intro?.richText || ''
  const sectionSubTitle = slice.primary.subtitle?.richText || ''

  const sectionDescription = slice.primary.description?.text || ''
  const youTubeSrc = slice.primary.youtube_src?.text || ''

  const heroImage = {
    src: slice.primary.banner_image?.url || '',
    alt: slice.primary.banner_image?.alt || ''
  }

  const onPlayBtnClick = (data) => {
    setShow(true)
    setModalDetails(data)
  }

  const onCloseBtnClick = ()=>{
    setShow(false)
    setModalDetails({})
  }

  return <section className="video-banner__hero">
    <div className="video-banner__hero-text">
      { sectionTitleIntro && <div className="video-banner__hero-intro"><PrismicRichText field={sectionTitleIntro}/></div> }
      { sectionTitle && <h1><PrismicRichText field={sectionTitle}/></h1> }
      { sectionSubTitle && <div className="video-banner__hero-subtitle mb-0">{sectionSubTitle[0]?.text} - {sectionSubTitle[1]?.text}</div> }

      {sectionDescription && <p>{sectionDescription}</p>}
    </div>
    <div className="video-banner__hero-play-btn">
      <img  src={images.playVideoBtnPng} alt="Play Button" className="play-btn" onClick={
        ()=> onPlayBtnClick({title: sectionSubTitle , description: sectionDescription, url: youTubeSrc })
      }/>
    </div>
    <div className="video-banner__hero-bg">
      <img src={heroImage.src} alt={heroImage.alt}/>
    </div>
    { show && modalDetails.url && <YouTubeModal modalDetails={modalDetails} closeModal={onCloseBtnClick} lang={pageLang}/> }
  </section>
}

export default HeroBannerWithModal