// core
import React, { useState, useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import { useLocation } from "@reach/router";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { PrismicRichText, SliceZone } from '@prismicio/react'

// components
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import ImgWithFallback from '../components/ImgWithFallback'
import { pdpComponents } from '../slices/product-details'
import ProductDetailsModules from '../components/ProductDetailsModules'
import checkMissingData from '../utils/checkMissingData'
import displayMissingFieldWarning from '../utils/displayMissingDataWarning'

// resources
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

import {
  setLearnMoreClickedEvent,
  setProductFlavorClickedEvent,
  setProductFlavorViewedEvent,
  setNutritionIngClickedEvent
} from "../assets/js/data-layer";

// constants
import {
  REGION,
  REGION_SUBTITLE_EN,
  REGION_SUBTITLE_EN_NO_BEERSTORE,
  REGION_SUBTITLE_FR,
  REGION_SUBTITLE_FR_NO_BEERSTORE,
  PRODUCT_SIZES_EN,
  PRODUCT_SIZES_FR,
  PRODUCT_SIZES_MAP,
  NUTRITIONAL_TABLE_TEXT_EN,
  NUTRITIONAL_TABLE_TEXT_FR, LANG
} from "../constants";
import AwardListComponent from '../components/AwardListComponent';

const ProductDetails = ( {data} ) => {
  const location = useLocation()
  const pageContent = data.prismicProductDetailsPage
  const pageData = data.prismicProductDetailsPage.data
  const sliceData = pageData.body
  const awardsPage=sliceData?.find(
    (item) => item.slice_type==="award_list"
  )
  const awardPageData = awardsPage?.items
  const beerStore = pageContent.uid === 'black-cherry' || pageContent.uid === 'mango' || pageContent.uid === 'ruby-grapefruit' || pageContent.uid === 'natural-lime' ? true : undefined
  const category = pageData.product_category.document?.data.category_name.toLowerCase() || ''

  const currentCategory = {
    vodkaSoda: category === 'vodka-soda',
    vodka: category === 'vodka',
    surge: category === 'surge'
  }

  const productType = {
    can: pageData.product_type === 'Can',
    bottle: pageData.product_type === 'Bottle',
    pack: pageData.product_type === 'Pack',
    lgPack: ['megawave-mixed-variety-pack','variety-pack-24-pack','variety-pack-30-pack'].includes(pageContent.uid)
  }
  // console.log('pageContent ' , pageContent)



  const { lang, type, url } = pageContent
  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  const regionSubtitle = lang === "en-ca"
      ? (beerStore ? REGION_SUBTITLE_EN : REGION_SUBTITLE_EN_NO_BEERSTORE)
      : (beerStore ? REGION_SUBTITLE_FR : REGION_SUBTITLE_FR_NO_BEERSTORE)
  const tableText = lang === "en-ca" ? NUTRITIONAL_TABLE_TEXT_EN : NUTRITIONAL_TABLE_TEXT_FR
  const productSizes = lang === "en-ca" ? PRODUCT_SIZES_EN : PRODUCT_SIZES_FR

  const isIcedTeaStrawberry = pageContent.uid === 'iced-tea-strawberry'
  const defaultRtbSize = lang === "en-ca" ? `per can (${isIcedTeaStrawberry ? "473 mL" : "355 mL"})` : `par canette (${isIcedTeaStrawberry ? "473 mL" : "355 mL"})`

  const [selectedNutritionSlice, setSelectedNutritionSlice] = useState([])
  const [selectedRegion, setSelectedRegion] = useState(REGION.CANADA)
  const [dropdownSizes, setDropdownSizes] = useState([])
  const [isTableActive, setTableActive] = useState("false")
  const [isServingActive, setServingActive] = useState(false)
  const [userSelection, setUserSelection] = useState(null)

  const ToggleTableClass = () => {
    setTableActive(!isTableActive)

    // nutrition_ingredients_clicked data-layer
    const product  = {
      name: pageData.product_name.text,
      category: pageData.product_category.document?.data.category_name.toLowerCase()
    }
    setNutritionIngClickedEvent(product)

    if(isServingActive){
      setServingActive(!isServingActive)
    }
  }

  const ToggleServingClass = () => {
    setServingActive(!isServingActive)
  }

  const checkForSlice = (sliceToCheck) => {
    let sliceExist = false
    sliceData.map((element) => {
      if(element.slice_type === sliceToCheck) {
        sliceExist = true
      }
    })
    return sliceExist
  }

  const getNutritionSliceToRender = (selectedSize, region) => {

    const filtered =  sliceData.reduce((acc, slice)=> {
      if (slice.slice_type === 'nutrition_and_ingredients_table'){
        let n = {...slice, items: slice.items.filter((item)=> {
            return item.location_type === region && item.product_size === selectedSize})
        }
        acc.push(n)
      }

      return acc
    }, [])

    setSelectedNutritionSlice(filtered)
    return filtered
  }

  // set default value for sizes dropdown
  useEffect(() => {
    setUserSelection(productSizes.can355)

    const category = pageContent.data.product_category.document?.data.category_name.toLowerCase() || ''
    const flavor = pageContent.data.taxonomy_product_flavor
    const size = pageContent.data.taxonomy_product_size

    setProductFlavorViewedEvent({ flavor, category, size })

  }, [])

  // set default value for sizes dropdown, if region does not have current product size
  useEffect(()=>{
    const regionSizes =  sliceData.reduce((acc, slice)=> {

      if (slice.slice_type === 'nutrition_and_ingredients_table'){
        slice.items.filter((sl)=>{
          if(selectedRegion === sl.location_type)
            acc.push({ size: `${tableText.oneCan} (${sl.product_size})` })
        })
      }
      return acc
    }, [])

    setDropdownSizes(regionSizes)

    const includesSize = regionSizes.some((el) => el.size === userSelection)

    if(!includesSize){
      setUserSelection(regionSizes[0]?.size || productSizes.can355)
    }
  },[selectedRegion])

  // set new slice when region or size was changed
  useEffect(()=>{
    if(userSelection && selectedRegion) {
      const formatSize = PRODUCT_SIZES_MAP.get(userSelection)
      getNutritionSliceToRender(formatSize, selectedRegion)
    }
  },[selectedRegion, userSelection])

  const renderSliceType = (type) => {
    const sliceToRender = []
    sliceData.map((element) => {
      if(element.slice_type === type ) {
        sliceToRender.push(element)
      }
    })
    return sliceToRender
  }

  const convertToDashSeparated = (str) => {
    return str.replace(/\s+/g, '-');
  }
  return (
      <Layout currentPage='product-details' activeDocMeta={activeDoc}>
        <Seo
            title={pageData.meta_title?.text }
            description={pageData.meta_description?.text}
            image={pageData.social_card?.url}
            currentPage={convertToDashSeparated(pageData.taxonomy_product_flavor)}
        />
        <section className="inside-content product-details">
          <div className="container-fluid inside-content-row py-0">
            <div className="row">
              <div className="col-lg-6 p-0">
                <div className={`product-image${productType.can ? ' product-image--can': ''}${ productType.bottle ? ' product-image--bottle': ''}${productType.pack ? ' product-image--pack': ''}${productType.bottle ? ' product-image--bottle': ''}${productType.lgPack ? ' product-image--lg-pack': ''}`}>
                  <ImgWithFallback
                      classNamePicture={"product-image__bg-pct"}
                      classNameImg={"product-image__bg-img"}
                      src={pageData.product_bg_png.url}
                      fallbackSrc={pageData.product_bg_png.url}
                      alt={pageData.product_bg_png.alt}
                  />
                  <ImgWithFallback
                      classNamePicture={"product-image__item-pct"}
                      classNameImg={"product-image__item-img"}
                      src={pageData.product_image_png.url}
                      fallbackSrc={pageData.product_image_png.url}
                      alt={pageData.product_image_png.alt}
                  />
                </div>
              </div>
              <div className="col-lg-6 p-0">
                <div className="product-content">
                  <h1 className="product-name-title" style={{color: pageData.product_name_color}}>
                    <PrismicRichText field={pageData.product_name.richText}/>
                  </h1>
                  { pageData.product_action_link?.url && <a
                      href={pageData.product_action_link.url}
                      className="product-action-btn"
                      onClick={() => {
                        const dl = {
                          url: pageData.cta_button_link.url,
                          referrer: location.pathname,
                          name: pageData.cta_button_text.text.toLowerCase()
                        }
                        setLearnMoreClickedEvent(dl)
                      }
                      }
                  >
                    {pageData.product_action_title }
                  </a>}
                  <p className="product-description">
                    {pageData.product_description.richText.map((row) => {
                      return <span key={row.text}>
                          <PrismicRichText field={[row]}/><br />
                        </span>
                    })
                    }
                  </p>
                  {
                    pageData.product_info.length !== 0 ?
                        checkMissingData(pageData.product_category.document) ?
                            displayMissingFieldWarning('pageData.product_category.document')
                            :
                            <div className={`product-info${pageData.product_category.document?.data.category_info_subtitle.richText.length === 0 ? ' mb-5' : ''}`}>
                              {pageData.product_info.map((element, index) => {
                                const productInfoSmallText = element.product_info_small_text.text? element.product_info_small_text.text : defaultRtbSize
                                return (
                                    <div className="product-info__item" key={index}>
                                      <img className="product-info__icon" src={element.product_info_icon.url} alt={element.product_info_icon.alt} />
                                      <div className="product-info__name">{element.product_info_name}</div>

                                      {index === 0 && !currentCategory.vodka && (pageContent.uid !== 'green-apple-surge')
                                      || index === 2 && !currentCategory.vodka && (pageContent.uid !== 'megawave-mixed-variety-pack')
                                      || (index === 1 && currentCategory.surge && (pageContent.uid === 'green-apple-surge'))  ?
                                          <div className="product-info__serving-size">{productInfoSmallText.trim()? productInfoSmallText : defaultRtbSize}</div>
                                          : null
                                      }
                                    </div>
                                );
                              })}
                            </div>
                        :
                        checkMissingData(pageData.product_category.document) ?
                            displayMissingFieldWarning('pageData.product_category.document')
                            :
                            <div className={`product-info${pageData.product_category.document?.data.category_info_subtitle.richText.length === 0 ? ' mb-5' : ''}`}>
                              {pageData.product_category.document?.data.category_info.map((element, index) => {
                                const productInfoSmallText = element.info_per_size.text? element.info_per_size.text : defaultRtbSize
                                return (
                                    <div className="product-info__item" key={index}>
                                      <img className="product-info__icon" src={element.info_image.url} alt={element.info_image.alt} />
                                      <div className="product-info__name">{element.info_text}</div>
                                      {index === 0 && !currentCategory.vodka && !currentCategory.surge  || index === 2 && !currentCategory.vodka && !currentCategory.surge
                                          ? <div className="product-info__serving-size">{productInfoSmallText.trim()? productInfoSmallText : defaultRtbSize}</div>
                                          : null}
                                      {index === 1 && currentCategory.surge
                                          ? <div className="product-info__serving-size">{productInfoSmallText.trim()? productInfoSmallText : defaultRtbSize}</div>
                                          : null}
                                    </div>
                                );
                              })}
                            </div>
                  }
                  {
                    checkMissingData(pageData.product_category.document) ?
                        displayMissingFieldWarning('pageData.product_category.document')
                        :
                        pageData.product_category.document?.data.category_info_subtitle.richText.length !== 0 ?
                            <div className='product-info__subtitle'>
                              <PrismicRichText field={pageData.product_category.document?.data.category_info_subtitle.richText}/>
                            </div>
                            : null
                  }
                  {
                    checkForSlice('nutrition_and_ingredients_table') ?
                        <section className="product-nutrition">
                          <h2>
                            <button className="product-nutrition__toggle" onClick={ToggleTableClass}>
                              {pageData.table_title}
                              <FontAwesomeIcon
                                  icon={faChevronDown}
                                  className="fa-solid"
                                  size="lg"
                                  flip={!isTableActive ? "vertical" : false}
                                  aria-hidden="false"/>
                            </button>
                          </h2>
                          <div className='product-nutrition__tab-group'>
                            { !isTableActive && pageData.tab.map((t)=>{
                              return <button key={t.tab_title.text} value={t.tab_type} className={`product-nutrition__tab ${ selectedRegion === t.tab_type && 'active' }`} onClick={(e)=> {
                                setSelectedRegion(e.target.value)
                              }}>
                                {t.tab_title.text}
                              </button>
                            })}
                          </div>
                          { !isTableActive && pageData.tab.length !== 1 && <div className='product-nutrition__tab-subtitle'>{regionSubtitle.get(selectedRegion)}</div> }
                          <table id="product-nutrition-table" className={`product-nutrition__table${!isTableActive ? " is-open" : ""}`} data-serving-size-selected>
                            <tbody>
                            <tr className="product-nutrition__row product-nutrition__row--dark-underline">
                              <td className="product-nutrition__row-left product-nutrition__serving-title">{pageData.serving_size_title}</td>
                              <td className="product-nutrition__row-right">
                                <div role="button"
                                     className="product-nutrition__serving-toggle d-flex justify-content-end align-items-center"
                                     onClick={ToggleServingClass}>
                                  <div id="product-nutrition-selected" className="product-nutrition__serving-selected" aria-selected="true">
                                    {userSelection}
                                  </div>
                                  { dropdownSizes.length !== 1 && <FontAwesomeIcon
                                      icon={faChevronDown}
                                      className="fa-solid ml-1"
                                      size="sm"
                                      flip={ isServingActive ? "vertical" : false}
                                      aria-hidden="false"/> }
                                  <div role="menu" aria-expanded="true" className={`product-nutrition__serving-menu${isServingActive ? " is-open" : ""}`}>
                                    {dropdownSizes.map((element, index) => {
                                      if(dropdownSizes.length !== 1) {
                                        return (
                                            <div
                                                role="menuitem"
                                                key={index}
                                                className="product-nutrition__serving-item"
                                                onClick={(e) => {
                                                  const value = e.target.textContent;
                                                  document.getElementById("product-nutrition-selected").textContent = value;
                                                  setUserSelection(value)
                                                }}
                                            >
                                              {element.size}
                                            </div>
                                        );
                                      }
                                    })}
                                  </div>
                                </div>
                              </td>
                            </tr>
                            </tbody>

                            { selectedNutritionSlice[0]?.items.length !== 0 &&
                                <SliceZone slices={selectedNutritionSlice} components={pdpComponents} />
                            }
                          </table>
                        </section>
                        : null
                  }
                  <section className="product-quantities">
                    {pageData.product_sizes.map((element, index) => {
                      if (index === 0) {
                        return (
                            <div key={index} className="product-quantities__row product-quantities__row--first-sizes">
                              <div className="product-quantities__col">
                                <h2 className="product-quantities__title">{pageData.product_sizes_title}</h2>
                              </div>
                              <div className="product-quantities__col product-quantities__col--icon">
                                <img src={element.product_size_icon.url} alt={element.product_size_icon.alt} className="product-quantities__icon product-quantities__icon--sizes" />
                              </div>
                              <div className="product-quantities__col">
                                <div className="product-quantities__text">{element.product_size}</div>
                              </div>
                            </div>
                        );
                      } else {
                        return (
                            <div key={index} className="product-quantities__row">
                              <i></i>
                              <div className="product-quantities__col product-quantities__col--icon">
                                <img src={element.product_size_icon.url} alt={element.product_size_icon.alt} className="product-quantities__icon product-quantities__icon--sizes" />
                              </div>
                              <div className="product-quantities__col">
                                <div className="product-quantities__text">{element.product_size}</div>
                              </div>
                            </div>
                        );
                      }
                    })}
                    {pageData.product_packs.map((element, index) => {
                      if (index === 0) {
                        return (
                            <div key={index} className="product-quantities__row product-quantities__row--first-packs">
                              <div className="product-quantities__col">
                                <h2 className="product-quantities__title">{pageData.product_packs_title}</h2>
                              </div>
                              <div className="product-quantities__col product-quantities__col--icon">
                                <img src={element.product_pack_icon.url} alt={element.product_pack_icon.alt} className="product-quantities__icon product-quantities__icon--packs" />
                              </div>
                              <div className="product-quantities__col">
                                <div className="product-quantities__text">{element.product_pack}</div>
                              </div>
                            </div>
                        );
                      } else {
                        return (
                            <div key={index} className="product-quantities__row">
                              <i></i>
                              <div className="product-quantities__col product-quantities__col--icon">
                                <img src={element.product_pack_icon.url} alt={element.product_pack_icon.alt} className="product-quantities__icon product-quantities__icon--packs" />
                              </div>
                              <div className="product-quantities__col">
                                <div className="product-quantities__text">{element.product_pack}</div>
                              </div>
                            </div>
                        );
                      }
                    })}
                  </section>
                {lang===LANG.EN?
                  checkForSlice("award_list")&&awardsPage.primary.show_awards&&awardPageData.length>0?
                    <AwardListComponent awardPageData={awardPageData}/>
                    :null
                  :null
                }
                  {
                    pageData.product_type === 'Pack' && checkForSlice('all_flavors') ?
                        <>
                          {/* <div>from slice</div> */}
                          <SliceZone slices={renderSliceType("all_flavors")} components={pdpComponents} />
                        </>
                        :
                        <div className="product-all-flavors">
                          {/* <div>from CR</div> */}
                          {
                            checkMissingData(pageData.product_category.document) ?
                                displayMissingFieldWarning('pageData.product_category.document')
                                :
                                lang === LANG.EN
                                    ? <h2 className="product-all-flavors__header">{pageData.product_category.document?.data.category_name_prefix} {pageData.product_category.document?.data.category_name} {pageData.product_category.document?.data.category_name_suffix}</h2>
                                    : <h2 className="product-all-flavors__header">{pageData.product_category.document?.data.category_name_prefix}  {pageData.product_category.document?.data.category_name_suffix} {pageData.product_category.document?.data.category_name}</h2> }
                          <div className="product-all-flavors__list">
                            {
                              checkMissingData(pageData.product_category.document) ?
                                  displayMissingFieldWarning('pageData.product_category.document')
                                  :
                                  checkMissingData(pageData.product_category.document) ?
                                      displayMissingFieldWarning('pageData.product_category.document')
                                      :
                                      pageData.product_category.document?.data.category_item.map((element, index) => {
                                        if(checkMissingData(element.category_item_link.document, index)){
                                          return displayMissingFieldWarning('element.category_item_link.document')
                                        } else {
                                          const itemType = element.category_item_link.document?.data.product_type
                                          if(itemType !== 'Pack') {
                                            return (
                                                <Link
                                                    key={index}
                                                    to={element.category_item_link.document?.url}
                                                    target="_self"
                                                    className="product-all-flavors__item"
                                                    onClick={()=>{
                                                      const dl = {
                                                        flavor: element.category_item_link.document?.data.taxonomy_product_flavor,
                                                        category,
                                                        position: index + 1,
                                                        url: element.category_item_link.document?.url,
                                                        location: 'product details page',
                                                        size: element.category_item_link.document?.data.taxonomy_product_size
                                                      }
                                                      setProductFlavorClickedEvent(dl)
                                                    }}
                                                >
                                                  <img className="product-all-flavors__item-image" src={element.category_item_link.document?.data.product_image_png.url} alt={element.category_item_link.document?.data.product_image_png.alt ? element.category_item_link.document?.data.product_image_png.alt : element.category_item_link.document?.data.product_name} />
                                                  <span className="product-all-flavors__item-name">{element.category_item_link.document?.data.product_name.text}</span>
                                                  <span className="product-all-flavors__item-tag">{
                                                    checkMissingData(pageData.product_category.document) ?
                                                        displayMissingFieldWarning('pageData.product_category.document')
                                                        :
                                                        pageData.product_category.document?.data.category_new_text
                                                            ? pageData.product_category.document?.data.category_new_text
                                                            : element.category_item_link.document?.data.product_new_text
                                                                ? element.category_item_link.document?.data.product_new_text
                                                                : null
                                                  }</span>
                                                </Link>
                                            );
                                          }
                                        }
                                      })}
                          </div>
                        </div>
                  }
                </div>
              </div>
            </div>
            <ProductDetailsModules sliceDataOverRide={sliceData} activeDocMeta={activeDoc} vodkaSoda={currentCategory.vodkaSoda} />
          </div>
        </section>
      </Layout>
  );
}

export const query = graphql`
  query ProductDetailsPageQuery($uid: String, $id: String, $lang: String) {
    prismicProductDetailsPage(uid: { eq: $uid }, id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      uid
      type
      id
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        social_card {
          url
        }
        meta_description {
          text
        }
        meta_title {
          text
        }
        product_name {
          text
          richText
        }
        product_name_color
        product_type
        product_section {
          document {
            ... on PrismicProductSection {
              id
              data {
                section_name
              }
            }
          }
        }
        product_category {
          document {
            ... on PrismicProductCategory {
              id
              data {
                category_name_prefix
                category_name
                category_name_suffix
                category_new_text
                category_info {
                  info_image {
                    url
                    alt
                  }
                  info_text
                  info_per_size{
                    text
                  }
                }
                category_info_subtitle {
                  richText
                }
                category_item {
                  category_item_link {
                    document {
                      ... on PrismicProductDetailsPage {
                        id
                        url
                        data {
                          product_name {
                            text
                            richText
                          }
                          product_image_png {
                            url
                            alt
                          }
                          product_type
                          product_new_text
                          taxonomy_product_size
                          taxonomy_product_flavor
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        product_image_png {
          url
          alt
        }
        product_bg_png {
          url
          alt
        }
        product_action_title
        product_action_link {
          url
        }
        product_description {
          richText
        }
        product_info {
          product_info_icon {
            url
            alt
          }
          product_info_name
          product_info_small_text{
            text
          }
        }
        product_sizes_title
        product_sizes {
          product_size
          product_size_icon {
            url
            alt
          }
        }
        product_packs_title
        product_packs {
          product_pack
          product_pack_icon {
            url
            alt
          }
        }
        table_title
        serving_size_title
        serving_size_list {
          sizes
        }
        taxonomy_product_size
        taxonomy_product_flavor
        tab {
          tab_type
          tab_title {
            richText
            text
          }
        }
        body {
          ... on PrismicProductDetailsPageDataBodyNutritionTable {
            id
            slice_type
            slice_label
            items {
              row_dark_underline
              row_indented
              row_large_header
              row_left
              row_no_underline
              row_bold_text_left
              row_right
            }
            primary {
              footer {
                richText
              }
            }
          }
          ... on PrismicProductDetailsPageDataBodyAllFlavors {
            id
            slice_type
            slice_label
            items {
              item_link {
                document {
                  ... on PrismicProductDetailsPage {
                    id
                    url
                    data {
                      product_new_text
                      product_name {
                        text
                        richText
                      }
                      product_image_png {
                        url
                        alt
                      }
                      taxonomy_product_size
                      taxonomy_product_flavor
                      product_category {
                        document {
                          ... on PrismicProductCategory {
                            id
                            data {
                              category_new_text
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            primary {
              header
            }
          }
          ... on PrismicProductDetailsPageDataBodyAwardList {
            id
            slice_type
            primary {
              show_awards
            }
            items {
              award_image {
                url
                alt
              }
              award_sub_image {
                alt
                url
              }
              award_sub_title {
                text
              }
              award_title {
                text
              }
            }
          }
          ... on PrismicProductDetailsPageDataBodyInstagramSection {
            id
            slice_type
            primary {
              title {
                text
              }
              description {
                text
              }
              container_class {
                text
              }
              background_color {
                text
              }
              copy_color {
                text
              }
            }
          }
          ... on PrismicProductDetailsPageDataBodyFeaturedProducts {
            id
            slice_type
            primary {
             header_text{
               text
               richText
              }
              header_subtext{
               text
               richText
              }
            }
            items {
              item_name
              item_image {
                url
                alt
              }
              item_tag
              item_link {
                url
              }
            }
          }
          ... on PrismicProductDetailsPageDataBodyNutritionAndIngredientsTable {
            id
            items {
              calories
              calcium_mg
              calcium_percentage
              carbohydrate_g
              cholesterol_mg
              fat_g
              fat_percentage
              fibre_g
              fibre_percentage
              daily_value {
                richText
                text
              }
              ingredients {
                richText
                text
              }
              iron_mg
              iron_percentage
              location_type
              page_lang
              potassium_mg
              potassium_percentage
              product_size
              protein_g
              saturated_g
              saturated_percentage
              sodium_mg
              sodium_percentage
              sugars_g
              sugars_percentage
              trans_g
              trans_percentage
            }
            slice_type
            slice_label
          }
        }
      }
    }
  }
`;
export default withPrismicPreview(ProductDetails)