// core
import React,{ useRef , useEffect } from 'react'
import {graphql} from "gatsby";
import {PrismicRichText} from "@prismicio/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpRightFromSquare} from "@fortawesome/free-solid-svg-icons/faUpRightFromSquare";
import {faDownload} from "@fortawesome/free-solid-svg-icons/faDownload";
import {useLocation} from "@reach/router";

import {AnchorLink} from "../../components/AnchorLink";
import useWindowSize from "../../hooks/useWindowSize";
import {useConsent} from "../../components/ConsentContext";

import {downloadMedia, getButtonCustomStyles, getFontSize} from "../../assets/js";

// constants
import {COLOR} from "../../constants/colors";

// data-layer
import {setBuyNowClickedEvent, setLearnMoreClickedEvent, setDownloadClickedEvent } from "../../assets/js/data-layer";
import useVisibility from '../../utils/visibilityHook';

export const FindNearYou = ({slice}) => {
  const location = useLocation()
  const {width} = useWindowSize()
  const componentRef = useRef(null);
  const consentAccepted = useConsent()
  const isVisible = useVisibility(componentRef);
  const sectionId = slice.primary.section_id?.text || slice.id
  const anchorLink = slice.primary.anchor_link?.text || ''

  // Toggles
  const showTitle = slice.primary.show_title
  const showSubTitle = slice.primary.show_subtitle
  const showCTAButton = slice.primary.show_cta_button
  const allow_transition = slice.primary.allow_transition

  //Links
  const isDownloadLink = slice.primary.cta_download_button
  const isTargetBlank = slice.primary.cta_button_open_in_new_tab
  const target = isTargetBlank ? '_blank' : '_self'

  // Text
  const title = slice.primary.title?.richText || []
  const titleTextColor = slice.primary.title_text_color || COLOR.WHITE
  const titleTextSizeDesktop = slice.primary.title_font_size_desktop || '40'
  const titleTextSizeMobile = slice.primary.title_font_size_mobile || '32'
  const titleTextFontSize = getFontSize(width, titleTextSizeDesktop, titleTextSizeMobile)


  const subTitle = slice.primary.subtitle?.richText || []
  const subTitleTextColor = slice.primary.subtitle_text_color || COLOR.WHITE
  const subTitleFontSizeDesktop = slice.primary.subtitle_font_size_desktop || '24'
  const subTitleFontSizeMobile = slice.primary.subtitle_font_size_mobile || '20'
  const subTitleFontSize = getFontSize(width, subTitleFontSizeDesktop, subTitleFontSizeMobile)


  // CTA Button
  const ctaButtonText = slice.primary.cta_button_text?.text || ''
  const ctaDownloadButtonFileName = slice.primary.cta_download_file_name || ''

  // CTA Button Link
  const ctaButtonLink = slice.primary.cta_button_link?.url || ''
  const ctaButtonDownloadLink = slice.primary.cta_download_button_file?.url || ''


  // styles
  const sectionStyles = {
    backgroundColor: slice.primary.section_bg_color || COLOR.WHITE,
  }

  const titleStyles = {
    color: titleTextColor,
    fontSize: titleTextFontSize
  }

  const subTitleStyles = {
    color: subTitleTextColor,
    fontSize: subTitleFontSize
  }


  // CTA Styles
  const buttonColorSet = {
    color: slice.primary.cta_button_text_color,
    bg: slice.primary.cta_button_bg_color,
    bgT: slice.primary.cta_button_bg_color_transparency,
    border: slice.primary.cta_button_border_color,
    colorHover: slice.primary.cta_button_text_color_hover,
    bgHover: slice.primary.cta_button_bg_color_hover,
    bgHoverT: slice.primary.cta_button_bg_color_hover_transparency,
    borderHover: slice.primary.cta_button_border_color_hover,
    borderRadius: slice.primary.cta_button_border_radius || "0",
  }

  const {ctaButtonStyle} = getButtonCustomStyles(buttonColorSet)

  const icons = slice.items || []

  const onBuyNowClick = (url, referrer, name, consent) =>{
    const dataLayer = { url, referrer, name: name.toLowerCase()}
    setLearnMoreClickedEvent(dataLayer, consent)
    setBuyNowClickedEvent(dataLayer,consent)
  }

   const onCTABtnClick = (url, referrer, name, consent) =>{
   const dataLayer = { url, referrer, name: name.toLowerCase()}
   setLearnMoreClickedEvent(dataLayer, consent)
  }

  useEffect(() => {
    if(isVisible == true && allow_transition){
      applyFadeInEffect();
    }
    if(isVisible == false && allow_transition){
      removeFadeInEffect();
    }
  }, [isVisible])
  const applyFadeInEffect = () => {
    const items = document.querySelectorAll('.find-near-you__icon');
    items.forEach((item, index) => {
      setTimeout(() => {
          item.classList.add('fade-in');
      }, index * 1000); // Each box fades in one second after the previous one
  });
  };
  const removeFadeInEffect = () => {
    const items = document.querySelectorAll('.find-near-you__icon');
    items.forEach((item, index) => {
      setTimeout(() => {
        if(item.classList.contains('fade-in')){
          item.classList.remove('fade-in');
        }   
      }, index * 1000);
  });
  };
  return (
      <section ref={componentRef} className='find-near-you find-near-you-padding' style={sectionStyles} id={`find-near-you-${sectionId}`}>
        {anchorLink && <AnchorLink link={anchorLink}/>}

        <div className='find-near-you__wrapper'>
          {/* <div className={isVisible && allow_transition?'bounce-in-bottom':''}> */}
          {showTitle && <h2 className={`find-near-you__title ${allow_transition?'fade-in-animation-enable':''} ${allow_transition && isVisible?"fade-in-bottom-up":''}`}>
            {title.map((row) => {
              return <span key={row.text} style={titleStyles}>
              <PrismicRichText field={[row]}/><br/>
            </span>
            })}
          </h2>}

          {showCTAButton && <>
            {!isDownloadLink && <a href={ctaButtonLink} title={ctaButtonText} className={`find-near-you__cta-button ${allow_transition?'fade-in-animation-enable':''} ${allow_transition && isVisible?"fade-in-bottom-up":''}`}
                                   style={ctaButtonStyle} target={target}
                                   onClick={()=> onBuyNowClick(ctaButtonLink, location.pathname,  ctaButtonText, consentAccepted)}
            >
              {ctaButtonText}
              {isTargetBlank && <FontAwesomeIcon icon={faUpRightFromSquare} className='ml-2'/>}
            </a>}

            {isDownloadLink &&
                <button title={ctaButtonText} className={`find-near-you__cta-button ${allow_transition?'fade-in-animation-enable':''} ${allow_transition && isVisible?"fade-in-bottom-up":''}`} style={ctaButtonStyle}
                        onClick={() => {
                          downloadMedia(ctaButtonDownloadLink, ctaDownloadButtonFileName)

                          // 'download_clicked' data layer event
                          setDownloadClickedEvent({
                            fileName: ctaDownloadButtonFileName,
                            clickUrl: ctaButtonDownloadLink,
                            clickText: ctaButtonText,
                            pageUrl: location.href
                          }, consentAccepted)
                        }}
                >
                  {ctaButtonText}
                  <FontAwesomeIcon icon={faDownload} className='ml-2'/>
                </button>}
          </>
          }

            {showSubTitle && <h4 className={`find-near-you__subtitle ${allow_transition?'fade-in-animation-enable':''} ${allow_transition && isVisible?"fade-in-bottom-up":''}`}>
              {subTitle.map((row) => {
                return <span key={row.text} style={subTitleStyles}><PrismicRichText field={[row]}/><br/></span>
              })
              }
            </h4>}
          {/* </div> */}
          

          <div className={`find-near-you__icons-wrapper`}>
            {icons.map((item) => {
              const iconSrc = width < 768 ? item.icon?.thumbnails?.mobile.url : item.icon?.url
              const iconAlt = item.icon?.alt
              const iconText = item.icon_text?.text
              const iconUrl = item.clickable ? item.icon_link.url : ''

              const styles = {
                color: item.icon_text_color || COLOR.WHITE,
                cursor: item.clickable ? 'pointer' : 'unset',
                pointerEvents: item.clickable ? 'visible' : 'none'
              }

              return <a key={iconText} className={`find-near-you__icon ${allow_transition?'fade-animation-loop-enable-find-near-you':''}`} href={iconUrl}
                        onClick={()=> onCTABtnClick(iconUrl, location.pathname,  iconText, consentAccepted)}
              >
                <img src={iconSrc} title={iconText} className='find-near-you__img' alt={iconAlt}/>
                <p className='find-near-you__icon-text' style={styles}>{iconText}</p>
              </a>
            })}
          </div>

        </div>
      </section>
  )
}

export const fragment = graphql`
  fragment PageDataBodyFindNearYou on PrismicGenericPageDataBodyFindNearYou  {
    id
    primary {
      section_id{
        text
      }
      anchor_link{
        text
      }
      cta_button_link {
        url
      }
      allow_transition
      show_cta_button
      show_title
      show_subtitle
      cta_button_bg_color
      cta_button_bg_color_hover
      cta_button_border_color
      cta_button_border_radius
      cta_button_border_color_hover
      cta_button_text {
        text
        richText
      }
      cta_button_text_color
      cta_button_text_color_hover
      cta_button_bg_color_transparency
      cta_button_bg_color_hover_transparency
      cta_button_open_in_new_tab
      cta_download_button
      cta_download_button_file {
        url
      }
      cta_download_file_name
      title {
        text
        richText
      }
      title_text_color
      title_font_size_desktop
      title_font_size_mobile
      subtitle {
        text
        richText
      }
      subtitle_text_color
      subtitle_font_size_desktop
      subtitle_font_size_mobile
      section_bg_color
    }
    items {
      clickable
      icon {
        thumbnails {
            mobile {
              alt
              url
            }
          }
          url
          alt
      }
      icon_text {
        text
        richText
      }
      icon_text_color
      icon_link {
        url
      }
    }
  }`