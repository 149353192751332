/**
 * This file is used for setting up and connecting to Prismic.
 */

module.exports = {
  // The domain name of your Prismic repository. This can be found in the URL of
  prismicRepo: 'wc-ca',

  // The default language for content in your Prismic repository.
  defaultLanguage: 'en-ca',

  // All available languages for content in your Prismic repository.
  langs: ['en-ca', 'fr-ca'],
}
