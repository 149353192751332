import React from 'react'
import { PrismicRichText } from "@prismicio/react";

const TaglineSection = ({ slice }) => {

  const description = slice.primary.section_description?.richText || []

  return (
      <section id="tagline" className="tagline" >
        {slice.primary?.section_description?.text && <p className='tagline__description text-center' >
          { description.map((row) => {
            return  <span key={row.text}><PrismicRichText field={[row]}/><br/></span>})
          }
        </p>}
      </section>
  )
}

export default TaglineSection