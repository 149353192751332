import React from "react";
import {PrismicRichText} from "@prismicio/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpRightFromSquare} from "@fortawesome/free-solid-svg-icons/faUpRightFromSquare";
import {faDownload} from "@fortawesome/free-solid-svg-icons/faDownload";

// components
import CustomLink from "../../../components/CustomLink";

// assets and utils
import useWindowSize from "../../../hooks/useWindowSize";
import {downloadMedia, getButtonCustomStyles, getFontSize} from "../../../assets/js";


// constants
import { COLOR } from "../../../constants/colors";


export const ImageSlide = ({item}) => {
  // console.log(item)
  const { width } = useWindowSize()
  const CTALink = item.cta_link?.url || ''

  const showButton = item.show_button
  const showSubTitle = item.show_subtitle
  const showTitle = item.show_title
  const showParagraph = item.show_paragraph
  const showContentBlock = showSubTitle || showTitle || showParagraph

  const reverseButtonClass = item.reverse_content ? "hero-carousel__cta-button--right" : "hero-carousel__cta-button--left"
  const reverseContentClass = item.reverse_content ? "hero-carousel__content--right" : "hero-carousel__content--left"

  // Links
  const isDownloadLink = item.cta_download_button
  const isTargetBlank = item.cta_button_open_in_new_tab
  const target = isTargetBlank ? '_blank' : '_self'

  // Text
  const subTitle = item.subtitle?.richText || []
  const subTitleTextColor = item.subtitle_text_color || COLOR.BLACK
  const subTitleFontSizeDesktop = item.subtitle_font_size_desktop || '38'
  const subTitleFontSizeMobile = item.subtitle_font_size_mobile || '20'
  const subTitleFontSize = getFontSize(width, subTitleFontSizeDesktop, subTitleFontSizeMobile)


  const title = item.title?.richText || []
  const titleTextColor =item.title_text_color || COLOR.BLACK
  const titleTextFontSizeDesktop = item.title_font_size_desktop || '82'
  const titleTextFontSizeMobile = item.title_font_size_mobile || '40'
  const titleTextFontSize = getFontSize(width, titleTextFontSizeDesktop, titleTextFontSizeMobile)


  const paragraph = item.paragraph?.richText || []
  const paragraphTextColor = item.paragraph_text_color || COLOR.BLACK
  const paragraphFontSizeDesktop = item.paragraph_font_size_desktop || '24'
  const paragraphFontSizeMobile = item.paragraph_font_size_mobile || '20'
  const paragraphFontSize = getFontSize(width, paragraphFontSizeDesktop, paragraphFontSizeMobile)


  // text content styles
  const subTitleStyles = {
    color: subTitleTextColor,
    fontSize: subTitleFontSize

  }

  const titleStyles = {
    color: titleTextColor,
    fontSize: titleTextFontSize
  }

  const paragraphStyles = {
    color: paragraphTextColor,
    fontSize: paragraphFontSize
  }

  // image
  const image = {
    src: width < 768 ? item.banner?.thumbnails?.mobile.url : item.banner?.url,
    alt: item.banner?.alt
  }

  // CTA Button
  const ctaButton = {
    text: item.cta_button_text?.text || '',
  }

  const ctaDownloadButtonFileName = item.cta_download_file_name || ''
  const ctaButtonDownloadLink = item.cta_download_button_file?.url || ''

  // CTA Styles
  const buttonColorSet = {
    color: item.cta_button_text_color,
    bg: item.cta_button_bg_color,
    bgT: item.cta_button_bg_color_transparency,
    border: item.cta_button_border_color,
    colorHover: item.cta_button_text_color_hover,
    bgHover: item.cta_button_bg_color_hover,
    bgHoverT: item.cta_button_bg_color_hover_transparency,
    borderHover: item.cta_button_border_color_hover,
  }

  const { ctaButtonStyle } = getButtonCustomStyles(buttonColorSet)


  return (<>
        <CustomLink to={CTALink}>
          <img className='hero-carousel__img' src={image.src} alt={image.alt} />
        </CustomLink>

        { showContentBlock && <div className={`hero-carousel__content ${reverseContentClass}`}>
          {showTitle && <h1 className='hero-carousel__title'>
            {title.map((row) => {
              return <span key={row.text} style={titleStyles}>
                <PrismicRichText field={[row]}/><br/>
              </span>
            })
            }
          </h1>}

          {showSubTitle && <h2 className='hero-carousel__subtitle'>
            {subTitle.map((row) => {
              return <span key={row.text} style={subTitleStyles}>{row.text}<br/></span>
            })
            }
          </h2>}

          {showParagraph && <p className='hero-carousel__paragraph'>
            {paragraph.map((row) => {
              return <span key={row.text} style={paragraphStyles}>{row.text}<br/></span>
            })
            }
          </p>}
        </div>}

        {showButton && <>

          {!isDownloadLink && <a href={CTALink} title={ctaButton.text}
                                 className={`hero-carousel__cta-button ${reverseButtonClass} mt-4`}
                                 style={ctaButtonStyle} target={target}>
            {ctaButton.text}
            {isTargetBlank && <FontAwesomeIcon icon={faUpRightFromSquare} className='ml-2'/>}
          </a>}

          {isDownloadLink &&
              <button title={ctaButton.text} className={`hero-carousel__cta-button ${reverseButtonClass} mt-4`}
                      style={ctaButtonStyle}
                      onClick={() => downloadMedia(ctaButtonDownloadLink, ctaDownloadButtonFileName)}
              >
                {ctaButton.text}
                <FontAwesomeIcon icon={faDownload} className='ml-2'/>
              </button>}
        </>}
      </>
  )
}