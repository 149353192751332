import React, { useEffect, useState } from 'react';
import AccordionCard from './accordianCard/accordianCard'
import { graphql } from "gatsby";
import { AnchorLink } from "../../components/AnchorLink";
import useWindowSize from "../../hooks/useWindowSize";
import { PrismicRichText } from "@prismicio/react";
import Modal from '../../components/Modal';

export const AccordianComponent = ({ slice }) => {
   
    const items = slice.items || []
    const [schemaData, setSchemaData] = useState(null);
    const { width } = useWindowSize()
    const sectionId =  slice?.id
    const anchorLink = slice.primary?.anchor_link?.text || ''

    const title = slice.primary.accordion_title?.richText || []
    const subTitle = slice.primary.accordion_subtitle?.richText || []
    const sectionSpacingTop = slice.primary?.section_margin_top || '0'
    const sectionSpacingBottom = slice.primary?.section_margin_bottom || '0'

    const isMobile = width <= 768;
    const sectionStyles = {
    marginTop:`${sectionSpacingTop}px`,
    marginBottom:`${sectionSpacingBottom}px`
    }
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalImg, setModalImg] = useState(''); 
    useEffect(() => {
    if (items.length > 0) {
      const newSchemaData = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": []
      };
      items.forEach((item, index) => {
        const questionAnswer = {
          "@type": "Question",
          "name": item.question.text,
          "acceptedAnswer": {
            "@type": "Answer",
            "text": item.answer.text
          }
        };
        newSchemaData.mainEntity.push(questionAnswer);
      });
      setSchemaData(newSchemaData);
    }
  }, [items]);
  const handleOpenModal = (event, image) => {
    event.preventDefault()
    setModalImg(image)
    setModalIsOpen(true)
  };
  const handleCloseModal = () => {
    setModalIsOpen(false)
  }
  return (
    <section className='accordian-component' id={`accordian-component-${sectionId}`} style={sectionStyles}>
    { anchorLink && <AnchorLink link={anchorLink}/> }
    <div className='container'>
    {schemaData && (
        <script type="application/ld+json">
          {JSON.stringify(schemaData)}
        </script>
      )}
        <h3 className={`custom-accordion-title`}>
          {title.map((row, index) => {
            return <span key={`${row.text}-${index}`}>
            <PrismicRichText field={[row]}/><br />
          </span>
          })
          }
      </h3>
      <h4 className='custom-accordion-subtitle'>
        { subTitle.map((row, index) => {
          return  <span key={index}>
          <PrismicRichText field={[row]}/><br/>
        </span>})
        }
  </h4>
  
  <AccordionCard items={items} handleOpenModal={handleOpenModal} />
    </div>
    {modalIsOpen &&
        <Modal children={modalImg} closeModal={handleCloseModal} />
      }
    </section>
  );
};
export const fragment = graphql`
        fragment PageDataBodyAccordion  on PrismicGenericPageDataBodyAccordion {
          id
          slice_type
          primary {
            accordion_subtitle {
              html
              raw
              richText
              text
            }
            accordion_title {
              html
              raw
              richText
              text
            }
            section_margin_bottom
            section_margin_top
          }
          items {
            answer {
              html
              raw
              richText
              text
            }
            question {
              html
              raw
              text
              richText
            }
          }
        }
`;
