const AGEGATE_FORM_VALIDATION_EN = {
  whereLocated: "WHERE ARE YOU LOCATED?",
  dob: "WHAT IS YOUR DATE OF BIRTH?",
  invalidProvince: "Please select where you are located",
  monthRequired: "Month is required",
  invalidYear: 'The year must be exactly 4 characters',
  invalidMonth: 'Month must be less than or equal to 12',
  invalidDay: "Invalid day for selected month",
  dayRequired: "Day is required",
  invalidAge: "To visit this website, you must be of legal drinking age.",
  yearRequired: "Year is required",
  submitBtn: "Confirm",
}

const AGEGATE_FORM_VALIDATION_FR = {
  whereLocated: "OÙ ES-TU SITUÉ(E)?",
  dob: "QUELLE EST TA DATE DE NAISSANCE?",
  invalidProvince: "Veuillez indiquer où vous êtes situé(e).",
  monthRequired: "Le mois est requis",
  invalidYear: 'L’année doit comporter exactement quatre chiffres',
  invalidMonth: 'Le mois doit être inférieur ou égal à 12',
  invalidDay: "Jour invalide pour le mois sélectionné",
  dayRequired: "La journée est requise",
  invalidAge: "Pour visiter ce site Web, vous devez avoir l'âge légal pour boire de l’alcool.",
  yearRequired: "L’année est requise",
  submitBtn: "Confirmer",    
}

export { AGEGATE_FORM_VALIDATION_EN, AGEGATE_FORM_VALIDATION_FR }