// core
import React, { useState, useEffect } from 'react'
import { useLocation } from '@reach/router';
import { useCookies } from 'react-cookie'
import {LANG} from "../constants";

// Components
import Header from './Header'
import Footer from './Footer'
import AgeGate from './AgeGate'

import {setGlobalLangAndCountryEvent} from "../assets/js/data-layer";

const Layout = ({children, currentPage, customPage, activeDocMeta}) => {
  const location = useLocation()
  const [showAgeGate, setShowAgeGate] = useState(false)
  const [cookies, setCookie] = useCookies()

  const activeLangClass = activeDocMeta.lang === LANG.EN ? 'en-lang' : "fr-lang"

  useEffect(()=>{
    if(activeDocMeta.lang){
      setGlobalLangAndCountryEvent(activeDocMeta.lang)
    }
  }, [activeDocMeta.lang])


  // Get Date
  let currentDate = new Date()
  currentDate.setDate(currentDate.getDate() + 7)
  let aWeekFromCurrentDate = currentDate

  const ageGateValid = () => {
    setCookie('adult', true, {expires: aWeekFromCurrentDate, path: '/'})
  }

  useEffect(() => {
    if(cookies?.adult){
      setShowAgeGate(false)
      document.body.classList.remove('overflow-hidden')
    } else {
      document.body.classList.add('overflow-hidden')
      setShowAgeGate(true)
    }

    setCookie('wcUserURL', location?.pathname)

  }, [cookies?.adult, cookies?.wcUserURL])


  return (
      <>
        {/* Header */}
        <Header customPage={currentPage || ''} activeDocMeta={activeDocMeta} />
        { showAgeGate && <AgeGate setAgeValid={ageGateValid} activeDocMeta={activeDocMeta}/>}
        { currentPage ? <main id={currentPage} className={activeLangClass}>{children}</main> : <main>{children}</main>}

        {/* Footer */}
        <Footer activeDocMeta={activeDocMeta} />
      </>
  )
}

export default Layout