// core
import React ,{useEffect, useState} from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { useLocation } from '@reach/router'
import {scroller} from "react-scroll";

// components
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { SliceZone } from '@prismicio/react'
import { components } from "../slices/generic-page";


const GenericPage = ({ data }) => {
  const { meta_title, meta_description, social_card, body: slices } = data.prismicGenericPage.data
  const pageData = data.prismicGenericPage.data
  const pageContent = data.prismicGenericPage
  const { lang, type, url, uid } = data.prismicGenericPage
  const alternateLanguages = data.prismicGenericPage.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }
  const location = useLocation()
  const headerNavType = pageData.transparent_header_nav
  const [isPageLoaded, setIsPageLoaded] = useState(false)
  useEffect(() => {
    window.onload = () => setIsPageLoaded(true)
  }, []);
  
  useEffect(()=>{
    // 👇 add attr to body element
    document.body.setAttribute('data-page', 'generic-template')

    return () => {
      // 👇 removing attr from body element
      // when the component unmounts
      document.body.removeAttribute('data-page')
    }
  },[])
  useEffect(() => {
    if(location.hash){
      const id = location.hash.replace('#', '');
      scroller.scrollTo(`anchor-link-${id}`, {
        duration: 1000,
        delay: 50,
        smooth: true,
      });
    }
  }, [location, isPageLoaded])

  return (
      <Layout currentPage={uid} activeDocMeta={activeDoc} headerNavType={headerNavType} className='generic-template'>
        <Seo
            title={meta_title?.text}
            description={meta_description?.text}
            image={pageData.social_card?.url}
            social_card={social_card?.url}
            activeDocMeta={activeDoc}
            currentPage={uid}
        />
        <SliceZone slices={slices} components={components} context={{data: pageContent, activeDoc}}/>
      </Layout>
  )
}


export const query = graphql`
query GenericPageQuery ($uid: String, $id: String, $lang: String) {
  prismicGenericPage (uid: { eq: $uid }, id: { eq: $id }, lang: { eq: $lang }) {
    _previewable
    alternate_languages {
      id
      lang
      type
      uid
    }
    data {
      transparent_header_nav
      body {
        ... on PrismicSliceType {
          slice_type
        }
        ... on PrismicGenericPageDataBodyHeroBannerWithModal {
          id
          slice_type
          primary {
            banner_image {
              url
              alt
            }
            banner_image_mobile {
              url
              alt
            }
            description {
              text
              richText
            }
            subtitle {
              text
              richText
            }
            title {
              text
              richText
            }
            title_intro {
              text
              richText
            }
            youtube_src {
              text
            }
          }
        }
        ... on PrismicGenericPageDataBodyInstagramSection {
          id
          slice_label
          slice_type
          primary {
            background_color
            copy_color
            title {
              text
              html
            }
            description {
              text
              html
            }
          }
        }
        ... on PrismicGenericPageDataBodyVideoGallery {
          id
          items {
            description {
              text
              richText
            }
            title {
              text
              richText
            }
            youtube_src {
              text
            }
            gallery_image {
              alt
              url
            }
          }
          primary {
            description {
              text
              richText
            }
            title {
              text
              richText
            }
          }
          slice_type
        }
        ...PageDataBodyYoutubeVideoSection
        ...PageDataBodyFullwidthTextWithButton
        ...PageDataBodySplitBanner
        ...PageDataBodyProductCarousel
        ...PageDataBodyContentPanel
        ...PageDataBodyHero
        ...PageDataBodyTabbedRange
        ...PageDataBodyAccordion
        ...PageDataBodyFindNearYou
      }
      meta_description {
        richText
        text
        html
      }
      meta_title {
        html
        richText
        text
      }
      social_card {
        url
        alt
      }
    }
    uid
    type
    lang
    id
    url
  }
} `


export default withPrismicPreview(GenericPage)