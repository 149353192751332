// core
import React from 'react'
import Modal from 'react-bootstrap/Modal'

const DecipherBatchNumber = ({ showDecipherBatchNumber, closeDecipherBatchNumber }) => {

  return (
    <>
      <Modal show={showDecipherBatchNumber} onHide={closeDecipherBatchNumber} animation={false} className="contact-us">
        <Modal.Body>
        <>
        <h3>Batch Code Example:<br/>
        A0119XXXX XX:XX FL</h3>

        <p>A = month = January<br/>
        01 = date = 1st<br/>
        19 = year = 2019</p>

        <p>XX:XX = time<br/>
        XXXX= Internal digits<br/>
        FL= production facility code</p>

        <p>A = January; B = February; C = March, D = April; E = May; F = June; G = July; H = August; J = September; K = October; L = November; M = December</p>
        </>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn modal-close-btn mr-0" type="button" onClick={closeDecipherBatchNumber} onKeyDown={closeDecipherBatchNumber}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default DecipherBatchNumber