import { useState, useEffect } from "react";
import { useCookies } from 'react-cookie'

// Hook
function useConsentCheck() {
  const [consentAccepted, setconsentAccepted] = useState(false)
  const isBrowser = typeof window !== "undefined";
  const [cookies] = useCookies()

  useEffect(() => {
    if(isBrowser && cookies?.adult) {
      const checkKetchConsent = setInterval(() => {
        if (window.ketchConsent) {

          if(window.ketchConsent.behavioral_advertising){
            setconsentAccepted(true)
          }

          window.semaphore.push(['onHideExperience', function() {

            if(window.ketchConsent.behavioral_advertising) {
              // console.log('behav ad true')
              setconsentAccepted(true)
            } else {
              // console.log('behav ad false')
              setconsentAccepted(false)
            }
          }])
          clearInterval(checkKetchConsent);
        }
      }, 300);
      return () => clearInterval(checkKetchConsent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consentAccepted, cookies?.adult]);
  return consentAccepted;
}

export default useConsentCheck