import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useSiteMetadata } from "../hooks/use-site-metadata";

import { useLocation } from '@reach/router';
// constants
import { HREFLANG_ALT, LANGUAGE_COUNTRY_LIST } from '../constants/languages';

const Seo = ({ description, title, image, activeDocMeta = { alternateLanguages: null }, children, currentPage = null }) => {
  const location = useLocation();
  const { site, featuredImage } = useSiteMetadata();

  const ogImage = featuredImage && featuredImage?.childImageSharp?.gatsbyImageData;

  const { lang } = activeDocMeta ? activeDocMeta : "en-ca";

  let modifiedPathname = location.pathname;

  if (currentPage !== "homepage") {
    if (!modifiedPathname.endsWith("/")) {
      modifiedPathname += "/";
    }
  } else if (modifiedPathname.endsWith("/")) {
    modifiedPathname = modifiedPathname.slice(0, -1);
  }
  if ((modifiedPathname === '' || modifiedPathname.length == 0) && currentPage == "homepage") {
    modifiedPathname = '/'
  }

  const seo = {
    siteUrl: site.siteMetadata.siteUrl,
    title: title || site.siteMetadata.title,
    description: description || site.siteMetadata.description,
    ogTitle: title || site.siteMetadata.title,
    ogDescription: description || site.siteMetadata.description,
    ogImage: image || `${site.siteMetadata.siteUrl}${ogImage?.images.fallback.src}`,
    ogUrl: site.siteMetadata.siteUrl,
    ogImageSecureUrl: image || `${site.siteMetadata.siteUrl}${ogImage?.images.fallback.src}`,
    twitterCard: site.twitterCard,
    twitterSite: site.twitterSite,
    twitterTitle: title || site.siteMetadata.title,
    twitterImage: image || `${site.siteMetadata.siteUrl}${ogImage?.images.fallback.src}`,
    twitterDescription: description || site.siteMetadata.description
  };
  React.useEffect(() => {
    setTimeout(() => {
      const elements = document.querySelectorAll('[data-react-helmet="true"]');
      elements.forEach(element => {
        element.removeAttribute('data-react-helmet');
      });
    }, 100);
  }, []);
  return (
    <Helmet>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

      {/* Hreflang tags */}
      <link rel="canonical" href={`${seo.siteUrl}${modifiedPathname}`} />

      {currentPage && HREFLANG_ALT[currentPage]?.map((item, index) => {
        if (lang === item) return null;
        return (<link key={index} rel="alternate" href={item.href} hreflang={item.lang} />);
      })}

      {/* Open Graph */}
      <meta property="og:title" content={seo.ogTitle} />
      <meta property="og:description" content={seo.ogDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`${seo.siteUrl}${modifiedPathname}`} />

      {seo.ogImage && <meta property="og:image" content={seo.ogImage} />}
      {seo.ogImage && <meta property="og:image:secure_url" content={seo.ogImage} />}
      {seo.ogImage && <meta property="og:image:width" content={image ? "1200" : seo.ogImage.width} />}
      {seo.ogImage && <meta property="og:image:height" content={image ? "630" : seo.ogImage.height} />}


      { /*Twitter */ }
      <meta name="twitter:card" content={seo.twitterCard} />
      <meta name="twitter:site" content={seo.twitterSite} />
      <meta name="twitter:title" content={seo.twitterTitle} />
      <meta name="twitter:description" content={seo.twitterDescription} />
      <meta name="twitter:image" content={seo.twitterImage} />

      { /*Pinterest*/}
      <meta name="p:domain_verify" content={seo.pinterestDomainVerify} />

      { /*Google Search Console */}
      <meta name="google-site-verification" content={seo.googleSearchConsole} />

      { /*Bing WebMaster */}
      <meta name="msvalidate.01" content={seo.bingWebMaster} />
     {children}
    </Helmet>
  );
};
export default Seo;