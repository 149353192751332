// core
import React, { useState, useEffect, useRef } from 'react'
import { PrismicRichText } from "@prismicio/react";
import { graphql, Link } from 'gatsby'

// resources
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

import useWindowSize from "../../hooks/useWindowSize";

// import Swiper core and required modules
import { Navigation } from "swiper";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import CustomLink from "../../components/CustomLink";
import {COLOR} from "../../constants/colors";
import {getButtonCustomStyles, getFontSize} from "../../assets/js";
import {AnchorLink} from "../../components/AnchorLink";


export const TabbedRange = ({ slice }) => {
  const { width } = useWindowSize()
  const playerRef = useRef()
  const [activeCat, setActiveCat] = useState('')
  const [activeSection, setActiveSection] = useState('')

  const sections = slice.items || []
  const sectionId = slice.primary.section_id?.text || slice.id
  const anchorLink = slice.primary.anchor_link?.text || ''

  const navigation = {
    nextEl: '.swiper-next-custom', prevEl: '.swiper-prev-custom'
  }

  const swiperBreakPoints = {
    0: {
      slidesPerView: 1
    }, 768: {
      slidesPerView: 2
    }, 992: {
      slidesPerView: 3
    }, 1200: {
      slidesPerView: 4
    },
  };

  const handleFirstLevelClick = (e) => {
    const clickedElement = e.target
    const clickedSectionValue = clickedElement.dataset.section
    document.querySelectorAll('.product-range-menu__first-level .product-range-menu__item').forEach((el) => {
      el.classList.remove('product-range-menu__item--active')
    })
    clickedElement.classList.add('product-range-menu__item--active')
    setActiveSection(clickedSectionValue)
    sections.forEach((element) => {
      const sectName = element.section.document.data.section_name
      if (sectName === clickedSectionValue) {
        const sectCats = element.section.document.data.section_categories
        setActiveCat(sectCats[0].product_category.document.data.category_name)
      }
    })
  }

  const handleSecondLevelClick = (e) => {
    const clickedElement = e.target
    const clickedCatValue = clickedElement.dataset.category;
    document.querySelectorAll('.product-range-menu__second-level .product-range-menu__item').forEach((el) => {
      el.classList.remove('product-range-menu__item--active')
    })
    clickedElement.classList.add('product-range-menu__item--active')
    setActiveCat(clickedCatValue)
  }

  useEffect(() => {
    setActiveSection(sections[0].section.document?.data?.section_name)
    setActiveCat(sections[0].section.document?.data?.section_categories[0].product_category.document.data.category_name)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!playerRef?.current) return

    playerRef.current.addEventListener('suspend', () => {
      playerRef.current?.play()
    });

  }, [])


  const tabCustomSettings = (item)  => {
    // Toggles
    const showSubTitle = item.show_subtitle
    const showTitle = item.show_title
    const showParagraph = item.show_paragraph
    const showCTAButton = item.show_cta_button

    // Text
    const subTitle = item.subtitle?.richText || []
    const subTitleTextColor = item.subtitle_text_color || COLOR.BLACK
    const subTitleFontSizeDesktop = item.subtitle_font_size_desktop || '38'
    const subTitleFontSizeMobile = item.subtitle_font_size_mobile || '20'
    const subTitleFontSize = getFontSize(width, subTitleFontSizeDesktop, subTitleFontSizeMobile)

    const title = item.title?.richText || []
    const titleTextColor = item.title_text_color || COLOR.BLACK
    const titleTextFontSizeDesktop = item.title_font_size_desktop || '66'
    const titleTextFontSizeMobile = item.title_font_size_mobile || '40'
    const titleTextFontSize = getFontSize(width, titleTextFontSizeDesktop, titleTextFontSizeMobile)

    const paragraph = item.paragraph?.richText || []
    const paragraphTextColor = item.paragraph_text_color || COLOR.BLACK
    const paragraphFontSizeDesktop = item.paragraph_font_size_desktop || '24'
    const paragraphFontSizeMobile = item.paragraph_font_size_mobile || '20'
    const paragraphFontSize = getFontSize(width, paragraphFontSizeDesktop, paragraphFontSizeMobile)

    const subTitleStyles = {
      color: subTitleTextColor,
      fontSize: subTitleFontSize
    }

    const titleStyles = {
      color: titleTextColor,
      fontSize: titleTextFontSize
    }

    const paragraphStyles = {
      color: paragraphTextColor,
      fontSize: paragraphFontSize
    }

    // CTA Button
    const ctaButton = {
      text: item.cta_button_text?.text || '',
      url: item.cta_button_link?.url || ''
    }

    // CTA Styles
    const buttonColorSet = {
      color: item.cta_button_text_color,
      bg: item.cta_button_bg_color,
      bgT: item.cta_button_bg_color_transparency,
      border: item.cta_button_border_color,
      colorHover: item.cta_button_text_color_hover,
      bgHover: item.cta_button_bg_color_hover,
      bgHoverT: item.cta_button_bg_color_hover_transparency,
      borderHover: item.cta_button_border_color_hover,
    }


    const { ctaButtonStyle } = getButtonCustomStyles(buttonColorSet)

    return  {
      showTitle,
      showSubTitle,
      showParagraph,
      showCTAButton,
      title,
      subTitle,
      paragraph,
      titleStyles,
      subTitleStyles,
      paragraphStyles,
      ctaButton,
      ctaButtonStyle,
    }

  }

  return <section className="product-details" id={`tabbed-range-${sectionId}`}
    >
    { anchorLink && <AnchorLink link={anchorLink}/> }

    <div className="container-fluid inside-content-row py-0">
      <div className="row">
        <div className="col-12 p-0 mt-5">
          <div className="product-range-menu" data-active-section={activeSection}>
            <div className="product-range-menu__first-level">
              {sections.map((sectElement, sectIndex) => {
                const sectionItemText = sectElement.section.document?.data.section_name || '';
                return (<button
                  className={`product-range-menu__item${sectIndex === 0 ? " product-range-menu__item--active" : ""}`}
                  key={sectIndex}
                  data-section={sectionItemText}
                  onClick={(e) => {
                    handleFirstLevelClick(e)
                  }}
                >
                  {sectionItemText}
                </button>);
              })}
            </div>
            <div className="product-range-menu__second-level-wrapper">
              <div className="product-range-menu__second-level" data-active-cat={activeCat}>
                {sections.map((sectElement) => { // eslint-disable-line array-callback-return
                  const sectionItemText = sectElement.section.document?.data.section_name;
                  const sectionItemNewText = sectElement.section.document?.data.section_new_text;
                  if (sectionItemText === activeSection) {
                    return sectElement.section.document?.data.section_categories.map((catEl, catIdx) => {
                      const catItemText = catEl.product_category.document.data.category_name;
                      const catItemNewText = catEl.product_category.document.data.category_new_text;
                      const catItemColor = catEl.product_category.document.data.category_color;

                      return (<button
                        key={catIdx}
                        className={`product-range-menu__item${catIdx === 0 ? " product-range-menu__item--active" : ""}`}
                        data-category={catItemText}
                        data-section={sectionItemText}
                        onClick={(e) => {
                          handleSecondLevelClick(e)
                        }}
                      >
                        <span className="product-range-menu__item-text-content" style={{borderColor: `${catItemColor}`}}>
                                {sectionItemNewText ? (
                                    <span className="product-range-menu__item-new">{sectionItemNewText}</span>
                                ) : catItemNewText ? (
                                    <span className="product-range-menu__item-new">{catItemNewText}</span>
                                ) : null}
                                <span className="product-range-menu__item-text">{catItemText}</span>
                              </span>
                      </button>);
                    });
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 p-0">
          {sections.map((sectEl) => { // eslint-disable-line array-callback-return
            const sectName = sectEl.section.document?.data.section_name
            const sectCats = sectEl.section.document?.data.section_categories

            if (sectName === activeSection) {
              return sectCats?.map((catEl, catIdx) => { // eslint-disable-line array-callback-return
                const { data } = catEl.product_category.document
                const catName = data.category_name
                const catTagline = data.category_tagline
                const catLogo = data.category_logo
                const catBgImg = data.category_bg_image
                const catBgImgMob = data.category_bg_image_mobile

                const catInfo = data.category_info_range.length !== 0 ? data.category_info_range : data.category_info

                const catInfoSubtitle = data.category_info_subtitle
                const catItem = data.category_item
                const catNewText = data.category_new_text
                const contentTypeImage = data.content_type

                // video
                const githubVideoFilename = width < 768 ? data?.github_video_filename_mobile : data?.github_video_filename_desktop;
                const githubVideoFilenameTrimmed = githubVideoFilename?.trim() || ''
                const video = githubVideoFilenameTrimmed ? `/videos/${githubVideoFilenameTrimmed}` : ''

                // console.log(data)
                const { showTitle, showSubTitle, showParagraph, showCTAButton, title, subTitle, paragraph, titleStyles, subTitleStyles, paragraphStyles, ctaButton, ctaButtonStyle } = tabCustomSettings(data)

                if (catName === activeCat) {
                  return (<div key={catIdx} className={`product-range-content product-range-content--${catName}`}
                    data-cat={catName}>
                    <div className="product-range-header">
                      <h1 className="visually-hidden">{catName}</h1>
                      <div className="product-range-header__image-pct">
                        <img className={"product-range-header__image-img"} src={catLogo.url} alt={catLogo.alt} />
                      </div>

                      <div className='product-range__text-wrap'>
                        {showSubTitle && <h4 className='product-range__subtitle'>
                          {subTitle.map((row) => {
                            return <span key={row.text} style={subTitleStyles}>{row.text}<br /></span>
                          })
                          }
                        </h4>}

                        {showTitle && <h3 className='product-range__title'>
                          {title.map((row) => {
                            return <span key={row.text} style={titleStyles}>
                              <PrismicRichText field={[row]} /><br />
                            </span>
                          })
                          }
                        </h3>}

                        {showParagraph && <p className='product-range__paragraph'>
                          {paragraph.map((row) => {
                            return <span key={row.text} style={paragraphStyles}>{row.text}<br /></span>
                          })
                          }
                        </p>}

                        {showCTAButton && <Link to={ctaButton.url} title={ctaButton.text} className="product-range__cta-button mt-4" style={ctaButtonStyle}>
                            {ctaButton.text}
                        </Link>}
                      </div>
                      {catTagline && <div className="product-range-header__tagline">{catTagline}</div>}

                      <div
                        className={`product-range-header__info${catInfoSubtitle.richText.length === 0 ? ' mb-5' : ''}`}>
                        {catInfo.map((catInfoElement, catInfoIndex) => {
                          // console.log("cat catInfoElement", catInfoElement);
                          return (<div key={catInfoIndex} className="product-range-header__info-item">
                            <img className="product-range-header__info-icon" src={catInfoElement.info_image.url}
                              alt={catInfoElement.info_image.alt} />
                            <div className="product-range-header__info-title">{catInfoElement.info_text}</div>
                            <div className='product-range-header__info-rtd-subtext'>{ catInfoElement.info_per_size?.richText[0]?.text }</div>
                          </div>);
                        })}
                      </div>
                      {catInfoSubtitle.richText.length !== 0 ? <div className='product-range-header__info-subtitle'>
                        <PrismicRichText field={catInfoSubtitle.richText} />
                      </div> : null}
                    </div>
                    <div
                      className={`product-range-content__body${catInfoSubtitle.richText.length === 0 ? ' mt-5' : ''}`}>
                      <div className='product-range-content__bg-image-pct'>
                        { <img
                          className="product-range-content__bg-image-img"
                          src={catBgImg.url}
                          alt={catBgImg.alt}
                        />}
                      </div>
                      <div className='product-range-content__bg-image-mobile-pct'>
                        {  <img
                          className="product-range-content__bg-image-mobile-img"
                          src={catBgImgMob.url}
                          alt={catBgImgMob.alt}
                        />}
                      </div>

                      <Swiper
                        modules={[Navigation]}
                        navigation={navigation}
                        initialSlide={0}
                        speed={500}
                        breakpoints={swiperBreakPoints}
                      >
                        {catItem.map((catItemElement, catItemIndex) => { // eslint-disable-line array-callback-return
                          const catItemURL = catItemElement.category_item_link.document.url
                          const catItemImg = catItemElement.category_item_link.document.data.product_image_carousel.url ? catItemElement.category_item_link.document.data.product_image_carousel : catItemElement.category_item_link.document.data.product_image_png
                          const catItemName = catItemElement.category_item_link.document.data.product_name.richText
                          const catItemNameTitle = catItemElement?.category_item_link?.document?.data?.product_name?.richText
                          const catItemNewText = catNewText ? catNewText : catItemElement.category_item_link.document.data.product_new_text
                          const catItemType = catItemElement.category_item_link.document.data.product_type
                          // const flavor = catItemElement.category_item_link.document.data.taxonomy_product_flavor
                          // const size = catItemElement.category_item_link.document.data.taxonomy_product_size

                          if (catItemType !== "Pack") {
                            return (<SwiperSlide key={catItemIndex}>
                              <CustomLink
                                className={`product-range-content__slide${catItemNewText ? " product-range-content__slide--new" : ""}`}
                                to={catItemURL}
                              >
                                <div className='product-range-content__image-pct'>
                                  <img
                                    className="product-range-content__image-img"
                                    src={catItemImg.url}
                                    alt={catItemImg.alt}
                                 />
                                </div>
                                <div className="product-range-content__name">
                                  {
                                    catItemNameTitle === null || catItemNameTitle?.length==0?
                                      <div className="product-range-content__name-text"><PrismicRichText
                                        field={catItemName} /></div>
                                      :
                                      <div className="product-range-content__name-text">
                                        {catItemNameTitle.map((item) => {
                                          return <div key={item.text}><PrismicRichText field={[item]} /></div>
                                        })}
                                      </div>
                                  }
                                  {catItemNewText ? <div
                                    className="product-range-content__name-new">{catItemNewText}</div> : null}
                                </div>
                              </CustomLink>
                            </SwiperSlide>);
                          }
                        })}
                      </Swiper>
                      <button className="swiper-prev-custom">
                        <span className="visually-hidden">Prev</span>
                        <FontAwesomeIcon icon={faChevronLeft} className="fa-solid" size="2xl" aria-hidden="false" />
                      </button>
                      <button className="swiper-next-custom">
                        <span className="visually-hidden">Next</span>
                        <FontAwesomeIcon icon={faChevronRight} className="fa-solid" size="2xl" aria-hidden="false" />
                      </button>
                    </div>
                  </div>);
                }
              })
            }
          })}
        </div>
      </div>
    </div>
  </section>
}


export const fragment = graphql`
fragment PageDataBodyTabbedRange on PrismicGenericPageDataBodyTabbedRange {
  id
  primary{
    section_id{
      text
    }
    anchor_link{
      text
    }
  }
  items {
    section {
      document {
        ... on PrismicProductSection {
          id
          data {
            section_new_text
            section_name_suffix
            section_name_prefix
            section_name
            section_categories {
              product_category {
                document {
                  ... on PrismicProductCategory {
                    id
                    data {
                      category_name
                      category_color
                      category_new_text
                      category_tagline
                      category_logo {
                        url
                        alt
                      }
                      category_info {
                        info_text
                        info_image {
                          url
                          alt
                        }
                      }
                      category_info_subtitle {
                        richText
                      }
                      category_info_range {
                        info_text
                        info_image {
                          url
                          alt
                        }
                      }
                      category_color
                      category_bg_image {
                        url
                        alt
                      }
                      category_bg_image_mobile {
                        url
                        alt
                      }
                      category_item {
                        category_item_link {
                          document {
                            ... on PrismicProductDetailsPage {
                              id
                              url
                              data {
                                product_name {
                                  text
                                  richText
                                }
                               
                                product_type
                                product_new_text
                                product_image_png {
                                  url
                                  alt
                                }
                                product_image_carousel {
                                  url
                                  alt
                                }
                                taxonomy_product_size
                                taxonomy_product_flavor
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

`;