const FORM_OPTIONS = new Map(
  [
    ["100000000", "Other"],
    ["100000001", "Product/Flavor Feedback"],
    ["100000002", "Ingredients/Nutrition Info"],
    ["100000003", "Quality Concern"],
    ["100000005", "Sponsorship Request"],
    //["100000006", "Coupons/Rebates"],
    ["100000007", "Partnership/Media Inquiry"],
    ["100000008", "Expiration Date"],
    ["100000009", "International Distribution Request"],
    ["100000010", "Distributor Info Request"],
    ["100000011", "Sweeps/Contest Inquiry"],
    ["100000012", "Can't Find Products"]
  ]
);

export { FORM_OPTIONS }