import React, { useState, useEffect, useRef } from 'react'

// components
import CountrySelector from './CountrySelector'
import ImgWithFallback from './ImgWithFallback'
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"

// assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import globe from '../assets/images/icons/globe.svg'
import globeWhite from '../assets/images/icons/globe-white.svg'
import whiteclawLogoSrc from '../assets/images/logo-blk.webp';
import whiteclawLogoFallbackSrc from '../assets/images/logo-blk.png';
import { PROVINCES_EN, PROVINCES_FR } from '../constants/provinceList'
import { AGEGATE_FORM_VALIDATION_EN, AGEGATE_FORM_VALIDATION_FR } from '../constants/ageGateFormValidations'
import { AGEGATE_TEXT_EN, AGEGATE_TEXT_FR } from '../constants/translations'


const AgeGate = ({setAgeValid , activeDocMeta}) => {
  const { lang } = activeDocMeta ? activeDocMeta : { lang: "en-ca" }
  const [minorMsg, setMinorMsg] = useState(false)
  const ageGateContent = []
  const dayInputRef = useRef()
  const monthInputRef = useRef()
  const yearInputRef = useRef()

  const validations = lang === "en-ca" ? AGEGATE_FORM_VALIDATION_EN : AGEGATE_FORM_VALIDATION_FR
  const translations = lang === "en-ca" ? AGEGATE_TEXT_EN : AGEGATE_TEXT_FR
  const provinces = lang === "en-ca" ? PROVINCES_EN : PROVINCES_FR

  // country select functions
  const [showCountrySelector, setShowCountrySelector] = useState(false)
  const closeCountrySelector = () => setShowCountrySelector(false)
  const openCountrySelector = () => setShowCountrySelector(true)
  const [storeStateOptions, setStoreStateOptions] = useState([])

  const [showProvinceField, setShowProvinceField] = useState(false);

  // Agegate validation
  const AGFormSchema = Yup.object().shape({
    month: Yup.number()
      .min(1, validations.invalidMonth)
      .max(12, validations.invalidMonth)
      .required(validations.monthRequired),
    day: Yup.number()
      .test("valid-day", validations.invalidDay, function (value) {
      const month = this.parent.month;
      if (!value || !month) {
          return true;
      }
      const year = this.parent.year || new Date().getFullYear();
      const daysInMonth = new Date(year, month, 0).getDate();
      return value >= 1 && value <= daysInMonth;
      })
      .required(validations.dayRequired),
    year: Yup.string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(4, validations.invalidYear)
      .max(4, validations.invalidYear)
      .required(validations.yearRequired),
    caProvinceList: Yup.string().when(['day', 'month', 'year'], {
      is: (day, month, year) => {
        // Calculate age based on current date
        const today = new Date();
        const birthDate = new Date(year, month - 1, day);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }

        if(!year || String(year)?.length === 4){
          setShowProvinceField(age < 19)
        }

        return age < 19;
      },
      then: Yup.string().required(validations.invalidProvince),
      otherwise: Yup.string(),
    }),
  });

  useEffect(() => {
    // set provinces list on first load
    setStoreStateOptions(provinces)

    if (dayInputRef.current) {
      dayInputRef.current.focus();
    }
  }, []);

  if(!minorMsg) { // age gate
    ageGateContent.push(
        <div key="1" className="container-fluid px-0">
          <div className="row w-lg-80 mx-auto">
            <div className="col-12 bg-black text-center py-3 px-3 py-lg-4 px-lg-4">
              <h1 className="my-2">{translations.heading}</h1>
            </div>
          </div>
          <div className="row w-lg-80 mx-auto age-gate-btns flex-column pt-3">
            <Formik
            initialValues={{ day: "", month: "", year: "", caProvinceList: "" }}
            validationSchema={AGFormSchema}
            onSubmit={(values) => {
                const validAge18List = ['Quebec', 'Québec', 'Alberta', 'Manitoba']
                const birthdate = new Date(values.year, values.month - 1, values.day)
                const birthdateYear = birthdate.getFullYear()
                const birthdateTimestamp = Math.floor(birthdate.getTime() / 1000)
                const todayTimestamp = Math.floor(Date.now() / 1000)
                const todayYear = new Date(todayTimestamp * 1000).getFullYear()
                const ageInSeconds = todayTimestamp - birthdateTimestamp
                const ageInYears = ageInSeconds / (60 * 60 * 24 * 365.25)
                const validAge18 = validAge18List.includes(values.caProvinceList)
                const validAgeWholeYears = validAge18 ? 18 : 19
                const ageValid = ageInYears >= validAgeWholeYears

                const isTodayBirthday =
                new Date(todayTimestamp * 1000).getDate() === birthdate.getDate() &&
                new Date(todayTimestamp * 1000).getMonth() === birthdate.getMonth() &&
                (todayYear - birthdateYear === validAgeWholeYears)

                if (ageValid || isTodayBirthday) {
                setAgeValid(true);
                } else {
                setMinorMsg(true);
                }
            }}
            >
            {({ values, errors, touched, handleChange, handleBlur }) => (
                <Form id="frm-contact-form" autoComplete="off">
                <div className="row">
                    <>
                      <div className="col-12 col-lg-4 form-group form-text-field">
                          {/* Day */}
                          <Field
                              innerRef={dayInputRef}
                              type="number"
                              id="form-birthdate-day"
                              className="form-control age-gate-text"
                              name="day"
                              placeholder="DD"
                              value={values.day}
                              min={1}
                              max={31}
                              onChange={e => {
                                e.target.value=e.target.value.slice(0,2);
                                if(e.target.value.length===2&&e.target.value>=1&&e.target.value < 32) {
                                  monthInputRef.current.focus()
                                }
                                handleChange(e);
                              }}
                              onBlur={(event) => {
                                handleBlur(event);
                                const { value } = event.target;
                                if (value.length === 1 && parseInt(value, 10) > 0) {
                                    handleChange({ target: { name: 'day', value: '0' + value } });
                                }
                            }}
                          />
                          {errors.day && touched.day && <div className="text-danger mt-2">{errors.day}</div>}
                      </div>
                      <div className="col-12 col-lg-4 form-group form-text-field">
                          {/* Month */}
                          <Field
                            innerRef={monthInputRef}
                            type="number"
                            id="form-birthdate-month"
                            className="form-control age-gate-text"
                            name="month"
                            placeholder="MM"
                            value={values.month}
                            min={1}
                            max={12}
                            onChange={event => {
                              event.target.value = event.target.value.slice(0, 2);
                              const { value } = event.target;
                              if (event.target.value.length === 1 && event.target.value >= 2) {
                                handleChange({ target: { name: 'month', value: '0' + value } });

                                yearInputRef.current.focus();
                            } else if (event.target.value.length === 2 && event.target.value >= 1 && event.target.value <= 12) {
                                yearInputRef.current.focus();
                                handleChange(event);
                            }
                            else
                            {
                              handleChange(event);
                            }
                            }}
                            onBlur={(event) => {
                              handleBlur(event);
                              const { value } = event.target;
                              if (value.length === 1 && parseInt(value, 10) > 0) {
                                handleChange({ target: { name: 'month', value: '0' + value } });
                              }
                            }}
                          />
                          {errors.month && touched.month && <div className="text-danger mt-2">{errors.month}</div>}
                      </div>
                      <div className="col-12 col-lg-4 form-group form-text-field">
                        {/* Year */}
                        <Field
                            innerRef={yearInputRef}
                            type="number"
                            id="form-birthdate-year"
                            className="form-control age-gate-text"
                            name="year"
                            placeholder="YYYY"
                            onChange={e => {
                              if (e.target.value.length <= 4) {
                                handleChange(e);
                              }
                            }}
                            onBlur={handleBlur}
                            value={values.year}
                        />
                        {errors.year && touched.year && <div className="text-danger mt-2">{errors.year}</div>}
                      </div>

                      { showProvinceField && <div className="col-12 form-group">
                        <label className="select">
                          {/* CA Provinces */}
                          <Field
                              as="select"
                              className="form-control age-gate-text"
                              name="caProvinceList"
                              value={values.caProvinceList}
                              onBlur={handleBlur}
                              onChange={e => {
                                handleChange(e)
                                dayInputRef.current.focus()
                              }}
                          >
                            <option value="" disabled>{validations.whereLocated}</option>
                            {storeStateOptions.map((element, index) => (
                                <option key={index}>{element.label}</option>
                            ))}
                          </Field>
                          <FontAwesomeIcon icon={faChevronDown} className="fa-solid" aria-hidden="false"/>
                          {errors.caProvinceList && !values.caProvinceList &&
                              <div className="text-danger mt-2">{errors.caProvinceList}</div>}
                        </label>
                      </div> }
                    </>
                </div>
                <div className="col-12 form-group form-text-field form-btn-container d-flex">
                    {/* Submit Btn */}
                    <button className="form-btn mx-auto" type="submit" id="submit-contact-form">
                    {validations.submitBtn}
                    </button>
                </div>
                </Form>
            )}
            </Formik>
        </div>
        </div>
    )
  } else { // minor entered
    ageGateContent.push(
        <div key="2" className="container-fluid px-0">
          <div className="row w-lg-80 mx-auto">
            <div className="col-12 bg-black text-center py-3 px-3 py-lg-4 px-lg-4">
              {lang === "en-ca" ?
                  <h1 className="my-2 age-limit">To visit this website, you must be of legal drinking age.</h1>
                  :
                  <h1 className="my-2 age-limit">Pour visiter ce site Web, vous devez avoir l'âge légal pour boire de l’alcool.</h1>
              }
            </div>
          </div>
        </div>
    )
  }
  return (
      <div className="modal modal-age-gate d-block overflow-auto">
        <div className="d-flex flex-wrap align-items-center h-100">
          <div className='container age-gate-wrapper text-center bg-white'>
            <div className='age-gate d-flex flex-wrap justify-content-center align-items-center'>
              <div className="row position-relative justify-content-center w-100">
                <div>
                  <ImgWithFallback
                      classNamePicture={"align-self-center mx-auto d-block img-fluid my-4 my-lg-5"}
                      classNameImg={"age-gate-logo"}
                      src={whiteclawLogoSrc}
                      fallbackSrc={whiteclawLogoFallbackSrc}
                      alt="White Claw Logo"
                  />
                </div>
                <div className="position-absolute country-selector" onClick={openCountrySelector} onKeyDown={openCountrySelector}>
                  <img src={globeWhite} className="globe-white" alt="Globe Icon" />
                  <img src={globe} className="globe-black" alt="Globe Icon" />
                </div>
              </div>
              {/* AGE GATE DYNAMIC CONTENT */}
              {ageGateContent}
              <div className="region-select us">
                <h4>
                  <img src={globe} alt="Globe Icon"/> Country / Language
                </h4>
                <div className="region-selected d-flex justify-content-center align-items-center" onClick={openCountrySelector} onKeyDown={openCountrySelector}>
                  <div className="region-selected__flag"></div>
                  <div className="region-selected__country">
                    <span className="country-name">CANADA</span>
                    <br/>
                    <span className="country-lang">{translations.lang}</span>
                  </div>
                  <FontAwesomeIcon icon={faChevronRight} className="fa-solid" aria-hidden="false"/>
                </div>
              </div>
              <div className="row d-flex justify-content-center age-gate-terms py-2">
                {translations.legal}
              </div>
            </div>
          </div>
        </div>
        <CountrySelector showCountrySelector={showCountrySelector} closeCountrySelector={closeCountrySelector} activeDocMeta={lang} />
      </div>
  )
}
export default AgeGate