const NUTRITIONAL_TABLE_TEXT_EN ={
  dailyValue: `* 5% or less is <strong>a little;</strong> 15% or more is <strong>a lot.<strong>`,
  amountPerServing : 'Amount per Serving',
  dailyValuePercentage: '% Daily Values*',
  ingredients: 'Ingredients',
  oneCan: "1 can",

  fat: 'Fat',
  saturated : 'Saturated',
  trans: 'Trans',
  carbohydrate: 'Carbohydrate',
  fiber: 'Fiber',
  sugars: 'Sugars',
  protein: 'Protein',
  cholesterol: 'Cholesterol',
  sodium: 'Sodium',
  potassium: 'Potassium',
  calcium: 'Calcium',
  iron: 'Iron'
}

const NUTRITIONAL_TABLE_TEXT_FR ={
  dailyValue: `* 5% ou moins <strong>c'est peu;</strong> 15% ou plus <strong>c'est beaucoup.</strong>`,
  amountPerServing: 'Quantité par portion',
  dailyValuePercentage: '% valeur quotidienne*',
  ingredients: 'Ingrédients',
  oneCan: "1 canette",

  fat: 'Lipides',
  saturated : 'Saturés',
  trans: 'Trans',
  carbohydrate: 'Glucides',
  fiber: 'Fibres',
  sugars: 'Sucres',
  protein: 'Protéines',
  cholesterol: 'Cholestérol',
  sodium: 'Sodium',
  potassium: 'Potassium',
  calcium: 'Calcium',
  iron: 'Fer'
}


const REGION_SUBTITLE_EN= new Map([
      ['Canada', 'For products found in Canada except at grocery and convenience stores in Quebec and at The Beer Store in Ontario.'],
      ['Quebec', 'For products found in Quebec grocery and convenience stores only.'],
      ['Ontario', 'For products found in The Beer Store across Ontario only.'],
    ]
);

const REGION_SUBTITLE_EN_NO_BEERSTORE= new Map([
    ['Canada', 'For products found in Canada except at grocery and convenience stores in Quebec.'],
    ['Quebec', 'For products found in Quebec grocery and convenience stores only.'],
    ['Ontario', ''],
  ]
);

const REGION_SUBTITLE_FR= new Map([
      ['Canada', 'Applicable aux produits achetés partout au Canada sauf dans les épiceries et dépanneurs du Québec et The Beer Store en Ontario.'],
      ['Quebec', 'Applicable aux produits achetés dans les épiceries et dépanneurs du Québec seulement.'],
      ['Ontario', 'Applicable aux produits achetés dans The Beer Store en Ontario seulement.'],
    ]
);

const REGION_SUBTITLE_FR_NO_BEERSTORE= new Map([
  ['Canada', 'Applicable aux produits achetés partout au Canada sauf dans les épiceries et dépanneurs du Québec.'],
  ['Quebec', 'Applicable aux produits achetés dans les épiceries et dépanneurs du Québec seulement.'],
  ['Ontario', ''],
]
);

const PRODUCT_SIZES_MAP = new Map(
    [
      ["1 can (355mL)", "355mL"],
      ["1 can (473mL)", "473mL"],
      ["1 canette (355mL)", "355mL"],
      ["1 canette (473mL)", "473mL"],
    ]
);

const PRODUCT_SIZES_EN = {
  can355:  '1 can (355mL)',
  can473: '1 can (473mL)',
}

const PRODUCT_SIZES_FR = {
  can355:  '1 canette (355mL)',
  can473: '1 canette (473mL)',
}

const REGION = {
  CANADA: "Canada",
  QUEBEC: "Quebec",
  ONTARIO: "Ontario"
}

export { NUTRITIONAL_TABLE_TEXT_EN ,NUTRITIONAL_TABLE_TEXT_FR, REGION_SUBTITLE_EN, REGION_SUBTITLE_EN_NO_BEERSTORE, REGION_SUBTITLE_FR, REGION_SUBTITLE_FR_NO_BEERSTORE, REGION, PRODUCT_SIZES_EN, PRODUCT_SIZES_FR, PRODUCT_SIZES_MAP }