// core
import React, { useState, useEffect,createRef,useRef } from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { useLocation } from "@reach/router";

// components
import CountrySelector from './CountrySelector'
import ImgWithFallback from './ImgWithFallback'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
// assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook,faInstagram,faXTwitter } from '@fortawesome/free-brands-svg-icons'
import whiteClawLogoPNG from '../assets/images/whiteclaw-logo-540x540.png'
import whiteClawLogoWEBP from '../assets/images/whiteclaw-logo-540x540.webp'

// preview
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

// data-layer
import {setSocialIconClickedEvent, setFAQViewedEvent} from "../assets/js/data-layer";

// constants
import { SOCIAL_PLATFORM } from "../constants/socials";
import MegaMenuNav from './navigation/MegaMenuNav';
import SecondLevelProductNav from './SecondLevelProductNav';
import caFlag from '../assets/images/flags/CA.svg'
import useWindowSize from '../hooks/useWindowSize';

const Header = ({disabledMenu, customPage, activeDocMeta}) => {
  // console.log(activeDocMeta)
  const { lang } = activeDocMeta ? activeDocMeta : { lang: "en-ca" }
  const location = useLocation()
  const { width } = useWindowSize()
  const queryData = useStaticQuery(graphql`
  {
    EN: prismicNavigation(lang: { eq: "en-ca" }) {
      id
      _previewable
      data {
        body {
          ... on PrismicNavigationDataBody1stLevel {
            slice_type
            primary {
              link_text
              nav_link {
                url
                slug
                id
                link_type
              }
            }
          }
          ... on PrismicNavigationDataBody2ndLevel {
            slice_type
            items {
              third_level_link_text
              third_level_link {
                slug
                url
                id
                link_type
              }
            }
            primary {
              link_text
            }
          }
          ... on PrismicNavigationDataBody2ndLevelMegaMenu {
            slice_type
            items {
              third_level_link {
                id
                slug
                url
              }
              third_level_link_image {
                alt
                url
              }
              third_level_link_text
            }
            primary {
              mega_menu
              link_text
            }
          }
          ... on PrismicNavigationDataBody2ndLevelProduct {
            id
            slice_type
            primary {
              nav_text
              second_level_products
              all_category_name
              all_category_link {
                uid
                type
                target
                id
                url
              }
              view_all_category_image {
                alt
                url
              }
            }
            items {
              category_name
              product_name
              product_url {
                id
                lang
                url
                type
              }
              product_image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
              label
            }
          }
        }
        hover_menu
      }
    }
    FR: prismicNavigation(lang: { eq: "fr-ca" }) {
      id
      _previewable
      data {
        body {
          ... on PrismicNavigationDataBody1stLevel {
            slice_type
            primary {
              link_text
              nav_link {
                url
                slug
                id
                link_type
              }
            }
          }
          ... on PrismicNavigationDataBody2ndLevel {
            slice_type
            items {
              third_level_link_text
              third_level_link {
                slug
                url
                id
                link_type
              }
            }
            primary {
              link_text
            }
          }
        }
      }
    }
  }
  `)
  const dropdownRef = useRef( null );
  const { data } = useMergePrismicPreviewData(queryData)
  const pageData = lang === 'en-ca' ?  data.EN.data :  data.FR.data
  const navigation = pageData.body
  const [showCollapsedNavbar, setShowCollapsedNavbar] = useState(false)
  const [scrollNavbar, setScrollNavbar] = useState(false)
  const [currentDropdownIndex, setCurrentDropdownIndex] = useState(null)
  // country select functions
  const [showCountrySelector, setShowCountrySelector] = useState(false)
  const closeCountrySelector = () => setShowCountrySelector(false)
  const openCountrySelector = () => setShowCountrySelector(true)
  const [megaMenu,setMegaMenu]=useState([])
  const hoverMenu=pageData?.hover_menu;
  useEffect(() => {
    const arrayMenu = []
    navigation.map((element,index) => {
      if(element.slice_type === "2nd_level_product"){
        arrayMenu.push({key:index,value:element.primary.nav_text})
      }
      if(element.slice_type==="2nd_level_mega-menu") {
        arrayMenu.push({key:index,value:element.primary.link_text})
      }
    })
    setMegaMenu(arrayMenu)
    
  },[navigation])  

  // scroll func
  const handleScroll = () => {
    window.scrollY < 90 ? setScrollNavbar(false) : setScrollNavbar(true)
  }

  const handleSecondLevelMenuClick = (e, index) => {
    setCurrentDropdownIndex(index)
  }
  const handleCloseOnWindowClick = (e) => {
    const trigger = document.querySelectorAll('.nav-item__dd-trigger')
    const menuButton = document.querySelectorAll('.nav-item__dd')
    const drop = document.querySelectorAll('.dropdown-menus')
    const arrowIcon = document.querySelectorAll('.arrow')
    const arrOfTargets = [...trigger, ...menuButton, ...drop,...arrowIcon]
    const isElTarget = arrOfTargets.some((el)=> {
      return e.target === el
    })

    if(!isElTarget){
      setCurrentDropdownIndex(null)
    }
  }
  const groupedByCategoryFunction = (data)=>{
    const groupedByCategory = data.reduce((acc, item) => {
    const category = item.category_name;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(item);
      return acc;
    }, {});
    const categories = Object.values(groupedByCategory);
    return  categories

  }



  useEffect(() => {
    if(!showCollapsedNavbar) {
      window.addEventListener("click", handleCloseOnWindowClick);
    } else {
      window.removeEventListener("click", handleCloseOnWindowClick);
    }
      return () => window.removeEventListener("click", handleCloseOnWindowClick);
  }, [showCollapsedNavbar])

  useEffect(() => {
    // add event listener
    window.addEventListener("scroll", handleScroll)
    document.body.classList.remove('overflow-hidden')
    document.documentElement.className = ''
    return () => window.removeEventListener("scroll", handleScroll)
  }, [scrollNavbar])

  let headerClass = (scrollNavbar) ? ' scrolled' : ''

  const onSocialIconClick = (platform) => {
    const dl = {
      platform,
      location: 'header'
    }
    setSocialIconClickedEvent(dl)
  }
  const closeOurProductNavBar = () => {
    document.body.classList.remove( 'overflow-hidden' )
    document.documentElement.className = ''
    window.removeEventListener( "click", handleCloseOnWindowClick )
    handleSecondLevelMenuClick();
    setShowCollapsedNavbar( false )
  }
  const handleSecondLevelMenuClickOnStory = ( e ) => {
    setCurrentDropdownIndex(null)
    setTimeout(() => {
      setCurrentDropdownIndex(false);
    }, 10);
  }
  // render header menus
  const closeMegaMenu=() => {
    document.body.classList.remove('overflow-hidden')
    document.documentElement.className=''
    window.removeEventListener("click",handleCloseOnWindowClick)
    handleSecondLevelMenuClickOnStory();
    setShowCollapsedNavbar(false)
 }
  const onCloseCrossIcon=() => {
    setShowCollapsedNavbar(false)
    document.body.classList.remove("go_back_container");
  }
  return (
    <>
    <header className={(customPage)
      ? "header d-flex align-items-center header--" + customPage + headerClass
      : "header d-flex align-items-center" + headerClass
    }>

      <div className="container-fluid">
        <nav className="navbar-expand-lg navbar" role="navigation">
          <div className="header__logo">
            <Link to={lang === 'en-ca' ? '/' : '/fr/'} target="_self" title="White Claw">
              <ImgWithFallback
                classNamePicture={"header__logo-pct"}
                classNameImg={"header__logo-img"}
                src={whiteClawLogoWEBP}
                fallbackSrc={whiteClawLogoPNG}
                alt="White Claw Logo"
              />
            </Link>
            {(lang==='en-ca'&& (megaMenu.filter((x)=>x.key === currentDropdownIndex)).length!=0)&&
              <div className='go_back_container'>
                <div className='header go_back_header'>
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                  <div
                    onClick={(e) => handleSecondLevelMenuClickOnStory(e)}
                    className='go-back_btn'>
                    <FontAwesomeIcon icon={faChevronLeft} className="fa-solid mt-1 back-icon" size="lg" aria-hidden="false"/>
                    <span className='current-menu-title'>{megaMenu.filter((x)=>x.key==currentDropdownIndex)[0]?.value}</span>
                  </div>
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                  <div
                    className="mt-2"
                    onClick={() => {onCloseCrossIcon()}}>
                    <span className='our-story-crossIcon'>&times;</span>
                  </div>
                </div>
              </div>
            }
          </div>


          {(disabledMenu)
            ? [] :
            <div className={(showCollapsedNavbar)
              ? 'collapse navbar-collapse d-lg-flex justify-content-lg-end show'
              : 'collapse navbar-collapse d-lg-flex justify-content-lg-end'}
              id="navbarNav"
            >
              <ul key = {"navbar-elements"} className="navbar-nav nav-item__nav-item-mobile" ref={ dropdownRef }>
                {
                  navigation.map((element, index) => {
                    if (element.slice_type === "1st_level") {
                      let dataEvent = ""
                      if (element.primary.nav_link.link_type === "Web") { dataEvent = "buy-now" }
                      if (element.primary.nav_link.slug === "faq-page") { dataEvent = "faq" }
                      return (
                        <li className="nav-item hover-effect" key={index}
                        onMouseOver={() => setCurrentDropdownIndex(null)}
                        >
                          <Link
                          to={element.primary.nav_link.url || '#'}
                          target="_self"
                          title={"White Claw " + (element.primary.link_text)}
                          data-event={dataEvent}
                          onClick={dataEvent === "faq" ? () => setFAQViewedEvent(location.pathname) : undefined}
                          >
                            {element.primary.link_text}
                          </Link>
                        </li>
                      )
                    }
                     else if (element.slice_type === "2nd_level") { // Secondary level will be added here fix needed.
                       return (
                        <li className="nav-item" key={index}
                        onMouseOver={() => {
                          if(width >= 991 && hoverMenu===true){
                            setCurrentDropdownIndex(index)
                          }
                        }}
                        >
                           <button className="nav-item__dd text-align"
                             onClick={(e) => handleSecondLevelMenuClick(e,index)}
                             onMouseOver={() => {
                               if(width>=991&&hoverMenu===true) {
                                 setCurrentDropdownIndex(index)
                               }
                             }}
                           >
                         <span className="nav-item__dd-trigger active" >{element.primary.link_text}</span>
                            <span>
                              {
                                currentDropdownIndex===index?
                                  <i className="arrow up"></i>
                                  :<i className="arrow down"></i>
                              }
                            </span>
                             { currentDropdownIndex === index && <ul className="dropdown-menus align-items" >
                               {
                                 element.items.map((childElm, childElmIndex) => {
                                 return (
                                       <li key={'child' + childElmIndex} className='nav-item__second_level'>
                                         <Link
                                             to={childElm.third_level_link.url}
                                             target="_self"
                                             title="White Claw"
                                             onClick={() => setShowCollapsedNavbar(false)}
                                         >
                                           {childElm.third_level_link_text}
                                        </Link>
                                       </li>
                                   )
                                 })
                               }
                             </ul>}
                           </button>
                            <FontAwesomeIcon icon={ faChevronRight } className="fa-solid nav-item__chevron_right" size="xl" aria-hidden="false"/>
                         </li>
                       )
                     }
                    else if(element.slice_type == "2nd_level_product"){
                      const categories = element.items? groupedByCategoryFunction(element.items):[]
                      return(
                        <SecondLevelProductNav
                            key = {index}
                            index = {index}
                            isActive={currentDropdownIndex === index}
                            element = {element}
                            categories = {categories}
                            closeOurProductNavBar = {closeOurProductNavBar}
                            handleSecondLevelMenuClick={handleSecondLevelMenuClick}

                            currentDropdownIndex ={currentDropdownIndex}
                            hoverMenu={hoverMenu}
                            />
                      )

                    }
                    else if(element.slice_type==="2nd_level_mega-menu"&&element.primary.mega_menu===true) {
                      return (
                        <MegaMenuNav pageData={pageData} index={index} element={element} handleSecondLevelMenuClick={handleSecondLevelMenuClick} activeDropdownMenu={currentDropdownIndex} closeOurStoryNavBar={closeMegaMenu} hoverMenu={hoverMenu}/>
                      )
                    }
                  })
                }
              </ul>

              <ul className="d-flex flex-row navbar-nav navbar-nav--social nav-item__social_mobile_view">
                <li className="nav-item text-center">
                  <a href="https://www.facebook.com/whiteclawcanada" target="_blank" rel='noopener noreferrer' onClick={()=>onSocialIconClick(SOCIAL_PLATFORM.FACEBOOK)}>
                    <FontAwesomeIcon icon={faFacebook} className="fa-icons" aria-hidden="false"/>
                  </a>
                </li>
                <li className="nav-item text-center">
                  <a href="https://www.instagram.com/whiteclawcanada" target="_blank" rel='noopener noreferrer' onClick={()=>onSocialIconClick(SOCIAL_PLATFORM.INSTAGRAM)}>
                    <FontAwesomeIcon icon={faInstagram} className="fa-icons" aria-hidden="false"/>
                  </a>
                </li>
                <li className="nav-item text-center">
                  <a href="https://www.twitter.com/whiteclawcanada" target="_blank" rel='noopener noreferrer' onClick={()=>onSocialIconClick(SOCIAL_PLATFORM.TWITTER)}>
                    <FontAwesomeIcon icon={faXTwitter} className="fa-icons" aria-hidden="false"/>
                  </a>
                </li>
              </ul>

              {/* Country Selector */}
              <div className="country-selector-btn-wrapper">
                <div className="country-selector-btn" onClick={openCountrySelector} onKeyDown={openCountrySelector}>
                  <img src={caFlag}  alt="Globe Icon" />
                </div>
              </div>
              <CountrySelector showCountrySelector={showCountrySelector} closeCountrySelector={closeCountrySelector} activeDocMeta={lang} />
            </div>
          }

          {/* Menu Hamburger */}
          {(disabledMenu)
            ? [] :
            <button className={(showCollapsedNavbar)
              ? "navbar-toggler show"
              : "navbar-toggler"
            } onClick={() => {
              //collapse navbar
              // console.log('showCollapsedNavbar ' , showCollapsedNavbar)
              if (showCollapsedNavbar) {
                //body not scrollable to scrollable
                document.body.classList.remove('overflow-hidden')
                document.documentElement.className = ''
              } else {
                //body scrollable to not scrollable
                document.body.classList.add('overflow-hidden')
                document.documentElement.className = 'overflow-hidden'
              }
              setShowCollapsedNavbar(!showCollapsedNavbar)
            }}>
              <div className="hamburger vertical-center">
                <div className="bar bar--1"></div>
                <div className="bar bar--2"></div>
                <div className="bar bar--3"></div>
              </div>
            </button>
          }
        </nav>
      </div>
    </header>
    </>
  )
}

export default Header
