const AGEGATE_TEXT_EN = {
  heading: 'ARE YOU OF LEGAL DRINKING AGE?',
  legal: 'Please drink responsibly. © 2024, ™ and ®: trademarks of White Claw Seltzer Co.',
  lang: 'English'
}

const AGEGATE_TEXT_FR = {
  heading: 'AVEZ-VOUS AVEZ L\'ÂGE LÉGAL POUR BOIRE DE L’ALCOOL?',
  legal: 'Consommez avec modération. © 2024, MC et MD: marques de commerce de White Claw Seltzer Co.',
  lang: 'Français'
}

export {AGEGATE_TEXT_EN, AGEGATE_TEXT_FR}