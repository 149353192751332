// core
import React, { useState } from "react";
import { graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { PrismicRichText } from "@prismicio/react";
import Accordion from "react-bootstrap/Accordion"
import parse from 'html-react-parser'

// components
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Modal from "../components/Modal";
import CommonPageBanner from '../components/CommonPageBanner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

// data-layer
import { setFAQTitleClickedEvent } from "../assets/js/data-layer";

const FAQ = ({ data }) => {
  const pageContent = data.prismicFaqPage;
  const pageData = data.prismicFaqPage.data;
  const [openItems, setOpenItems] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalImg, setModalImg] = useState('');
  const [activeKey, setActiveKey] = useState(null);
  // console.log('accordion ' , pageContent.data.accordion)

  const { lang, type, url } = pageContent;
  const alternateLanguages = pageContent.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };

  const handleAccordionToggle = (index, key) => {
    const newOpenItems = [];
    newOpenItems[index] = !newOpenItems[index];
    setActiveKey(index === activeKey ? null : index);
    setOpenItems(newOpenItems);
  };

  const handleOpenModal = (event, image) => {
    event.preventDefault()
    setModalImg(image)
    setModalIsOpen(true)
  };

  const handleCloseModal = () => {
    setModalIsOpen(false)
  }

  return (
    <Layout currentPage="faq" activeDocMeta={activeDoc}>
      <Seo title={pageData.meta_title?.text} description={pageData.meta_description?.text} image={pageData.social_card?.url} currentPage={"faq"}/>
      <section id="faq" className="inside-content faq">
        <CommonPageBanner title={pageData.banner_title.text} backgroundUrl={pageData.banner_background.url} subtitle={pageData.banner_subtitle.text} description={pageData.banner_description.html} />
        <div className="container-fluid inside-content-row">
          <div className="row">
            <div className="col-md-12">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <h1><PrismicRichText field={pageData.page_title.richText}/></h1>
                    <Accordion defaultActiveKey={["0"]} activeKey={activeKey} key={"0"} onSelect={handleAccordionToggle} onClick={(e) => {
                          // data layer
                          const faqTitle = e.target.innerText
                          setFAQTitleClickedEvent(faqTitle)
                        }
                      }>
                      {
                        pageData.accordion.map((element, index) => {
                          const accordCols = [
                            element.content_col_1,
                            element.content_col_2,
                            element.content_col_3
                          ]
                          const accordionIndent = element.indent
                          const accordionIndentSpacing = element.indent_spacing || "0"
                          const addIndent = accordionIndent && accordionIndentSpacing ? { marginLeft: accordionIndentSpacing } : undefined
                          return (
                            <Accordion.Item
                              eventKey={index}
                              key={index}
                              style={addIndent}
                            >
                              <Accordion.Header>
                                <FontAwesomeIcon icon={openItems[index] ? faMinus : faPlus} aria-hidden="false" size="lg" />
                                <span>{parse(element.title.richText[0].text)}</span>
                              </Accordion.Header>
                              <Accordion.Body>
                                {element.content_no_cols.html.length !== 0 && element.content_no_cols.html !== "<p></p>"? (
                                  element.content_no_cols.richText[0].type === "preformatted" ?
                                  (
                                    element.content_no_cols.richText.length>1?                                                                  
                                    <>
                                       {element.content_no_cols.richText.map((colsValue, colsIndex) => {
                                         if (colsValue.type === "heading6" && colsValue.spans.length !== 0)
                                               {
                                                const modalImgSrc = colsValue.spans[0].data?.url || '';
                                                const modalImgWidth = colsValue.spans[0].data?.width || '';
                                                const modalImgHeight = colsValue.spans[0].data?.height || '';
                                                const modalImgTag = <img src={modalImgSrc} width={modalImgWidth} height={modalImgHeight} alt="" />;
                                                return (
                                                  <a key={colsIndex} href="#" onClick={(event) => handleOpenModal(event, modalImgTag)}>
                                                    {colsValue.text}
                                                  </a>
                                                );
                                              } 
                                              else if (colsValue.spans[0] && colsValue.spans[0].type === "strong") {
                                                return (
                                                  <p key={colsIndex}>
                                                    <strong>{colsValue.text}</strong>
                                                  </p>
                                                );
                                              } else if (colsValue.type === "preformatted") {
                                                return <p key={colsIndex}>{parse(colsValue.text)}</p>;
                                              } else {
                                                return <p key={colsIndex}>{colsValue.text}</p>;
                                              }
                                    })}
                                    </>
                                    :<p>{ parse(element.content_no_cols.richText[0].text)}</p>
    
                                  )
                                  
                                  :
                                  parse(element.content_no_cols.html)
                                ) : (
                                  <div className="row">
                                    {accordCols.map((colsValue, colsIndex) => {
                                      const columnSize = element.content_col_3.richText.length === 0 || element.content_col_3.richText[0].text === "" ? 
                                        element.content_col_2.richText.length === 0 || element.content_col_2.richText[0].text === "" ? "" : "col-md-6" : "col-md-4"

                                      return (
                                        colsValue.richText.length !== 0 &&
                                        colsValue.richText[0].text !== "" && (
                                          <div
                                            key={colsIndex}
                                            className={`${columnSize} d-flex flex-column`}
                                          >
                                            {colsValue.richText.map((rtEl, rtIdx) => {
                                              if (rtEl.type === "heading6" && rtEl.spans.length !== 0) {
                                                const modalImgSrc = rtEl.spans[0].data?.url || '';
                                                const modalImgWidth = rtEl.spans[0].data?.width || '';
                                                const modalImgHeight = rtEl.spans[0].data?.height || '';
                                                const modalImgTag = <img src={modalImgSrc} width={modalImgWidth} height={modalImgHeight} alt="" />;
                                                return (
                                                  <a key={rtIdx} href="#" onClick={(event) => handleOpenModal(event, modalImgTag)}>
                                                    {rtEl.text}
                                                  </a>
                                                );
                                              } else if (rtEl.spans[0] && rtEl.spans[0].type === "strong") {
                                                return (
                                                  <p key={rtIdx}>
                                                    <strong>{rtEl.text}</strong>
                                                  </p>
                                                );
                                              } else if (rtEl.type === "preformatted") {
                                                return <p key={rtIdx}>{parse(rtEl.text)}</p>;
                                              } else {
                                                return <p key={rtIdx}>{rtEl.text}</p>;
                                              }
                                            })}
                                          </div>
                                        )
                                      );
                                    })}
                                  </div>
                                )}
                              </Accordion.Body>
                            </Accordion.Item>
                          );
                        })
                      }
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {modalIsOpen &&
        <Modal children={modalImg} closeModal={handleCloseModal} />
      }
    </Layout>
  );
};

export const query = graphql`
  query FaqPageQuery($uid: String, $id: String, $lang: String) {
    prismicFaqPage(uid: { eq: $uid }, id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      uid
      type
      id
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        banner_background {
          alt
          url
        }
        banner_description {
          text
        }
        banner_subtitle {
          text
        }
        banner_title {
          text
        }
        social_card {
          url
        }
        meta_description {
          text
        }
        meta_title {
          text
        }
        page_title {
          richText
        }
        accordion {
          indent
          indent_spacing
          title {
            richText
            html
          }
          content_no_cols {
            richText
            html
          }
          content_col_1 {
            richText
            html
          }
          content_col_2 {
            richText
            html
          }
          content_col_3 {
            richText
            html
          }
        }
      }
    }
  }
`;
export default withPrismicPreview(FAQ);
