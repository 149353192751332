const PROVINCES_EN = [
  {
    "value": "Alberta",
    "label": "Alberta"
  },
  {
    "value": "British Columbia",
    "label": "British Columbia"
  },
  {
    "value": "Manitoba",
    "label": "Manitoba"
  },
  {
    "value": "New Brunswick",
    "label": "New Brunswick"
  },
  {
    "value": "Newfoundland and Labrador",
    "label": "Newfoundland and Labrador"
  },
  {
    "value": "Northwest Territories",
    "label": "Northwest Territories"
  },
  {
    "value": "Nova Scotia",
    "label": "Nova Scotia"
  },
  {
    "value": "Nunavut",
    "label": "Nunavut"
  },
  {
    "value": "Ontario",
    "label": "Ontario"
  },
  {
    "value": "Prince Edward Island",
    "label": "Prince Edward Island"
  },
  {
    "value": "Quebec",
    "label": "Quebec"
  },
  {
    "value": "Saskatchewan",
    "label": "Saskatchewan"
  },
  {
    "value": "Yukon",
    "label": "Yukon"
  }
]

const PROVINCES_FR = [
  {
    "value": "Alberta",
    "label": "Alberta"
  },
  {
    "value": "British Columbia",
    "label": "Colombie-Britannique"
  },
  {
    "value": "Manitoba",
    "label": "Manitoba"
  },
  {
    "value": "New Brunswick",
    "label": "Nouveau-Brunswick"
  },
  {
    "value": "Newfoundland and Labrador",
    "label": "Terre-Neuve-et-Labrador"
  },
  {
    "value": "Northwest Territories",
    "label": "Territoires du Nord-Ouest"
  },
  {
    "value": "Nova Scotia",
    "label": "Nouvelle-Écosse"
  },
  {
    "value": "Nunavut",
    "label": "Nunavut"
  },
  {
    "value": "Ontario",
    "label": "Ontario"
  },
  {
    "value": "Prince Edward Island",
    "label": "Île-du-Prince-Édouard"
  },
  {
    "value": "Quebec",
    "label": "Québec"
  },
  {
    "value": "Saskatchewan",
    "label": "Saskatchewan"
  },
  {
    "value": "Yukon",
    "label": "Yukon"
  }
]

export {PROVINCES_EN, PROVINCES_FR}