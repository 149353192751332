import HeroBannerWithModal from "./HeroBannerWithModal";
import VideoGallery from "./VideoGallery";
import { YouTubeVideoSection } from "./YouTubeVideoSection"
import { FullWidthTextWithButton } from "./FullWidthTextWithButton";
import { SplitBanner } from "./SplitBanner";
import { ContentPanel } from "./ContentPanel";
import { ProductCarousel } from "./ProductCarousel";
import { Hero } from "./Hero";
import { TabbedRange } from "./TabbedRange";
import InstagramSection from "../instagram-section/InstagramSection";
import { AccordianComponent } from "./AccordianComponent"
import { FindNearYou } from "./FindNearYou"

export const components = {
  hero: Hero,
  hero_banner_with_modal: HeroBannerWithModal,
  video_gallery: VideoGallery,
  youtube_video_section: YouTubeVideoSection,
  fullwidth_text_with_button: FullWidthTextWithButton,
  split_banner: SplitBanner,
  content_panel: ContentPanel,
  product_carousel: ProductCarousel,
  tabbed_range:TabbedRange,
  instagram_section: InstagramSection,
  accordion:AccordianComponent,
  find_near_you: FindNearYou
}