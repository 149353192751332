const CONTACT_FORM_VALIDATION_EN = {
  provideEmail: "Please provide an email.",
  provideValidEmail: "Not a valid email address",
  email: "Email*",
  provideGender: "Gender*",
  genderM: "Male",
  genderF: "Female",
  genderO: "Other",
  genderSelect: "Please select a gender",
  postalCode: "Postal Code*",
  providePostalCode: "Please provide a Postal Code",
  provideValidPostalCode: "Ex A1A 1A1 or A1A1A1",
  selectTopic: "Please select a topic.",
  provideFirstName: "Please provide a first name.",
  provideLastName: "Please provide a last name.",
  tooShort: "Too Short!",
  tooLong: "Too Long!",
  onlyAlpha: "Only alphabetic characters are allowed.",
  enterBday: "Please enter your birthday.",
  validBday: "Expected format MM/DD/YYYY",
  provideMsg: "Please provide a value for Your Question",
  provideBatchCode: "Please provide a batch code",
  pleaseSelectStateOfPurchase: "Please select your province of purchase",
  pleaseSelectPackageType: "Please select package type.",
  selectPackageQuantity: "Please select package quantity.",
  provideFlavorName: "Please provide a flavour name.",
  dontHaveBatchNumber: "I don't have batch number",
  enterProductDetails: "Please enter product details",
  selectState: "Select Purchased From*",
  messageYes: "Your message has been received <br> and we'll respond as soon as we can.",
  messageNo: "It seems that our mail server is not responding. <br> Please try again later!",
  pleaseSelectTopic: "Please select a topic*",
  qualityConcern: "Quality Concern",
  sponsorRequest: "Sponsorship",
  partnerMedia: "PR Opportunity",
  ingredientNutr: "Ingredients/Nutrition Info",
  coupon: "Coupons/Rebates",
  prodFlavFeedback: "Product/Flavour",
  other: "Other",
  batchNumberCanBeFound: "Batch number can be found under any can.",
  viewImage: "VIEW IMAGE",
  batchNumberDD: "Batch Number*",
  naDD: "N/A",
  selectPackageTypeDD: "Select Package Type*",
  canDD: "Can",
  packageQuantityDD: "Select Package Quantity*",
  singleDD: "Single",
  sixPackDD: "6 pack",
  eightPackDD: "8 pack",
  twelvePackDD: "12 pack",
  twentyFourPackDD: "24 pack",
  flavorNameDD: "Flavour Name*",
  firstNameDD: "First Name*",
  lastNameDD: "Last Name*",
  birthDateDD: "Birthdate* MM/DD/YYYY*",
  msgDD: "Message*",
  submitBtn: "SUBMIT",
}

const CONTACT_FORM_VALIDATION_FR = {
  provideEmail: "Veuillez fournir un courriel valide",
  provideValidEmail: "Adresse e-mail invalide.",
  email: "Courriel*",
  provideGender: "Sexe*",
  genderM: "Homme",
  genderF: "Femme",
  genderO: "Autre",
  genderSelect: "Veuillez spécifier un sexe",
  postalCode: "Code postal*",
  providePostalCode: "Veuillez fournir un code postal valide",
  provideValidPostalCode: "Ex A1A 1A1 or A1A1A1",
  selectTopic: "Veuillez choisir un sujet",
  provideFirstName: "Veuillez indiquer un prénom",
  provideLastName: "Veuillez indiquer un nom de famille",
  tooShort: "Trop Court!",
  tooLong: "Trop Long!",
  onlyAlpha: "Seules les lettres alphabétiques sont autorisées.",
  enterBday: "Veuillez fournir une date de naissance",
  validBday: "MM/JJ/AAAA",
  provideMsg: "Veuillez saisir un message",
  provideBatchCode: "Veuillez indiquer un numéro de lot",
  pleaseSelectStateOfPurchase: "Veuillez sélectionner acheté chez",
  pleaseSelectPackageType: "Veuillez sélectionner le type d'emballage",
  selectPackageQuantity: "Veuillez sélectionner la quantité de l’emballage",
  provideFlavorName: "Veuillez indiquer nom de la saveur",
  dontHaveBatchNumber: "Je n'ai pas de numéro de lot",
  enterProductDetails: "Veuillez indiquer les détails du produit",
  selectState: "Sélectionnez acheté chez*",
  messageYes: "Nous avons reçu votre message <br> et nous vous répondrons dès que possible.",
  messageNo: "Il semble que notre serveur de messagerie ne réponde pas. <br> Veuillez réessayer plus tard.",
  pleaseSelectTopic: "Veuillez choisir un sujet*",
  qualityConcern: "Souci de la qualité",
  sponsorRequest: "Demande de parrainage",
  partnerMedia: "Demande de renseignements sur les partenariats et les médias",
  ingredientNutr: "Ingrédients et information nutritionnelle",
  coupon: "Coupons/remises",
  prodFlavFeedback: "Les produits et les saveurs",
  other: "Autre",
  batchNumberCanBeFound: "Le numéro de lot se trouve sous chaque cannette. ",
  viewImage: "Voir l'image",
  batchNumberDD: "Numéro de lot*",
  naDD: "N/A",
  selectPackageTypeDD: "Sélectionnez le type d'emballage*",
  canDD: "Cannette",
  packageQuantityDD: "Sélectionnez la quantité de l’emballage*",
  singleDD: "Single",
  sixPackDD: "6 pack",
  eightPackDD: "8 pack",
  twelvePackDD: "12 pack",
  twentyFourPackDD: "24 pack",
  flavourNameDD: "Nom de la saveur*",
  firstNameDD: "Prénom*",
  lastNameDD: "Nom de famille*",
  birthDateDD: "Date de naissance* MM/JJ/AAAA",
  msgDD: "Message*",
  submitBtn: "Soumettre",
}

export { CONTACT_FORM_VALIDATION_EN, CONTACT_FORM_VALIDATION_FR }