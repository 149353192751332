import React, { useRef} from 'react'

// assets
import useWindowSize  from "../../assets/js/useWindowSize";

//components
import ImgWithFallback from "../../components/ImgWithFallback";

// Import Swiper styles
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/mousewheel';
import {PrismicRichText} from "@prismicio/react";

const ProductDetailsSection = ({ slice }) => {
  const { width } = useWindowSize()
  const refSection = useRef(null)
  const bgImageUrl = {
    mobile : slice.primary.background_image_mobile.url || '',
    screen : slice.primary.background_image.url || ''
  }

  const slides = slice.items
  return (
      <>
        <section id='new-product-details' className="new-product-details"  ref={refSection}>

          <div className='container new-product-details__container'>
            <div className=''>
                {slides && slides.map((
                    { title,
                      title_color,
                      aroma_description,
                      aroma_title,
                      taste_description,
                      taste_title,
                      finish_description,
                      finish_title,
                      button_text,
                      button_link,
                      product_image
                    })=> {
                  return <div key={title.text} className='w-100'>
                    <div className='slide position-relative'>
                      <div className='new-product-details__bgr-container'
                           style={{backgroundImage: `${ width <= 992 ? `url(${bgImageUrl.mobile})` : `url(${bgImageUrl.screen})`}`}}
                      ></div>

                      <div className='slide__content-container'>
                        <div className='slide__content-wrap col-sm-8 col-lg-7 w-100'>
                      <h4 className='slide__title' style={{color: title_color}}>
                        <PrismicRichText field={title.richText} />
                      </h4>
                      <div>
                        <h5 className='slide__subtitle'>{aroma_title}</h5>
                        <p className='slide__description'>
                          {aroma_description.text}
                        </p>

                        <h5 className='slide__subtitle'>{taste_title}</h5>
                        <p className='slide__description'>
                          {taste_description.text}
                        </p>

                        <h5 className='slide__subtitle'>{finish_title}</h5>
                        <p className='slide__description'>
                          {finish_description.text}
                        </p>
                      </div>
                          { button_link?.url && <a href={button_link.url} className='button-white slide__button-white ml-0'>
                            {button_text.text}
                          </a> }
                    </div>
                        <div className='slide__image-wrap w-100 col-sm-4 col-lg-5'>
                          <ImgWithFallback
                            classNameImg='slide__product'
                            src={product_image.url}
                            alt={product_image.alt}
                            fallbackSrc={product_image.url}
                        />
                    </div>
                      </div>
                    </div>

                    { button_link?.url && <a href={button_link.url} className='btn button-black  slide__button-black slide__btn'>
                      {button_text.text}
                    </a> }
                  </div>
                })}


            </div>
          </div>
        </section>
      </>
  )
}

export default ProductDetailsSection

