// core
import React, { useState, useEffect } from 'react'
import axios from 'axios'

// form validation
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"

// components
import BatchImage from './BatchImage'
import HiddenFields from './HiddenFields'
import SuccessFailModal from "./SuccessFailModal"

// Resources
import caProvinceList from "../../assets/resources/ca-province-list.json"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

// Data layer
import { setContactUsFormSubmittedEvent } from "../../assets/js/data-layer";

// constants
import {
  FORM_OPTIONS,
  CONTACT_FORM_VALIDATION_EN,
  CONTACT_FORM_VALIDATION_FR,
  CONTACT_US_BASE_URL
} from "../../constants";

const ContactUsForm = ({ activeDocMeta }) => {
    const { lang } = activeDocMeta ? activeDocMeta : "en-ca"

    const validations = lang === "en-ca" ? CONTACT_FORM_VALIDATION_EN : CONTACT_FORM_VALIDATION_FR

    const ContactUsFormSchema = Yup.object().shape({
      email: Yup.string().required(validations.provideEmail).email(validations.provideValidEmail),
      inquiryTypeID: Yup.string().required(validations.selectTopic),
      gender: Yup.string().required(validations.genderSelect),
      postalCode: Yup.string()
        .required(validations.providePostalCode)
        .matches(/^[A-Za-z]\d[A-Za-z]\s?\d[A-Za-z]\d$/, validations.provideValidPostalCode),
      firstName: Yup.string()
        .required(validations.provideFirstName)
        .min(2, validations.tooShort)
        .max(32, validations.tooLong)
        .matches(/^[a-zA-Z\s]+$/, validations.onlyAlpha),
      lastName: Yup.string()
        .required(validations.provideLastName)
        .min(2, validations.tooShort)
        .max(32, validations.tooLong)
        .matches(/^[a-zA-Z\s]+$/, validations.onlyAlpha),
      birthDate: Yup.date().required(validations.enterBday).typeError(validations.validBday),
      message: Yup.string().required(validations.provideMsg),
      batchNumber: Yup.string().when(["inquiryTypeID", "extraFieldsCheckbox"], {
        is: (inquiryTypeID, extraFieldsCheckbox) => inquiryTypeID === "100000003" && extraFieldsCheckbox === false,
        then: Yup.string().required(validations.provideBatchCode),
        otherwise: Yup.string().notRequired(),
      }),
      caProvinceList: Yup.string().when("inquiryTypeID", {
        is: (val) => val === "100000003",
        then: Yup.string().required(validations.pleaseSelectStateOfPurchase),
        otherwise: Yup.string().notRequired(),
      }),
      packageType: Yup.string().when("extraFieldsCheckbox", {
        is: (val) => val === true,
        then: Yup.string().required(validations.pleaseSelectPackageType),
        otherwise: Yup.string().notRequired(),
      }),
      packageQuantity: Yup.string().when("extraFieldsCheckbox", {
        is: (val) => val === true,
        then: Yup.string().required(validations.selectPackageQuantity),
        otherwise: Yup.string().notRequired(),
      }),
      flavorName: Yup.string().when("extraFieldsCheckbox", {
        is: (val) => val === true,
        then: Yup.string().required(validations.provideFlavorName),
        otherwise: Yup.string().notRequired(),
      }),
    });

    const [birthDayValue, setBirthDayValue] = useState("")
    const [storeStateOptions, setStoreStateOptions] = useState([])
    const [showBatchImage, setShowBatchImage] = useState(false)
    const [extraFieldsContainer, setExtraFieldsContainer] = useState(false)
    const [extraFieldsBtnText, setExtraFieldsBtnText] = useState(validations.dontHaveBatchNumber)

    // show success fail modal functions
    let [userName, setUserName] = useState(false)
    let [modalBodyCopy, setModalBodyCopy] = useState("")
    const [showSuccessFailModal, setShowSuccessFailModal] = useState(false)
    const closeSuccessFailModal = () => setShowSuccessFailModal(false)

    // toggle btn
    const toggleExtraFieldsContainer = (e) => {
        setExtraFieldsContainer(!extraFieldsContainer)
        setExtraFieldsBtnText(!extraFieldsContainer ? validations.enterProductDetails : validations.dontHaveBatchNumber)
    }

    // batch image modal functions
    const closeBatchImage = () => setShowBatchImage(false)
    const openBatchImage = (e) => {
        e.preventDefault()
        setShowBatchImage(true)
    }

    const buildUSAstatesList = async () => {
        try {
            let options = []
            options.push({ "value": "", "label": validations.selectState })
            for (var key in caProvinceList) {
                options.push({ "value": caProvinceList[key], "label": caProvinceList[key] })
            }
            setStoreStateOptions(options)
        } catch (error) {
            console.log('responseData: ', 'failed')
        }
    }

    // birthDay Field Validation
    const checkValue = (str, max) => {
        if (str.charAt(0) !== '0' || str == '00') {
            let num = parseInt(str)
            if (isNaN(num) || num <= 0 || num > max) num = 1
            str = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '0' + num : num.toString()
        }
        return str
    }

    const checkYear = (str, max) => {
      const min = max - 90
      if (str.length === 4) {
        return str > max || str.charAt(0) === "0" ? max : str < min || str.charAt(0) === "0" ? min : str
      }
      return str;
    }

    const handleBirthdDateChange = (e) => {
        let input = e.target.value
        if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3)
        let values = input.split('/').map(function(v) {
            return v.replace(/\D/g, '')
        })

        if (values[0]) values[0] = checkValue(values[0], 12)
        if (values[1]) values[1] = checkValue(values[1], 31)
        if (values[2]) values[2] = checkYear(values[2], new Date().getFullYear())
        let output = values.map(function(v, i) {
            return v.length == 2 && i < 2 ? v + '/' : v
        })
        setBirthDayValue(output.join('').substr(0, 10))
    }
    const isBrowser = typeof window !== "undefined"
    // get ? query value
    const queryParams = isBrowser && new URLSearchParams(window.location.search)
    const subject = isBrowser && queryParams.get("subject")

    // preset default inquiryTypeID value
    const presetInquiryTypeID = subject ? "100000013" : ""

    useEffect(() => {
      buildUSAstatesList()
    }, [])

    return (
    <>
        {/* Form */}
        <Formik
            initialValues={ // initial form values
                {
                    email: "",
                    gender: "",
                    postalCode: "",
                    inquiryTypeID: presetInquiryTypeID,
                    firstName: "",
                    lastName: "",
                    birthDate: "",
                    message: "",
                    optInCheckBox: false,
                    batchNumber: "",
                    caProvinceList: "",
                    extraFieldsCheckbox: false,
                    packageType: "",
                    packageQuantity: "",
                    flavorName: ""
                }
            }

            validationSchema={ContactUsFormSchema}

            onSubmit={(values, { setSubmitting, resetForm }) => {

                let extraDetails = [];

                if(values.inquiryTypeID === '100000003') {
                    extraDetails.push({ KeyName: 'BatchCode', Value: values.batchNumber.trim() });
                    extraDetails.push({ KeyName: 'StoreState', Value: values.caProvinceList.trim() });
                    if (values.extraFieldsCheckbox === true) {
                        extraDetails.push({ KeyName: 'PackageType', Value: values.packageType.trim() });
                        extraDetails.push({ KeyName: 'PackageQuantity', Value: values.packageQuantity.trim() });
                        extraDetails.push({ KeyName: 'PackageFlavor', Value: values.flavorName.trim() });
                    }
                }

                let data = {
                    ApplicationID: 912,
                    FirstName: values.firstName.trim(),
                    LastName: values.lastName.trim(),
                    Email: values.email.trim(),
                    PostalCode: values.postalCode.trim(),
                    BirthDate: values.birthDate.trim(),
                    Gender: values.gender.trim(),
                    InquiryTypeID: values.inquiryTypeID,
                    Message: values.message.trim(),
                    ExtraDetails: extraDetails
                }

                //data layer data
                const dlData = {
                  topicId: values.inquiryTypeID,
                  topicName: FORM_OPTIONS.get(values.inquiryTypeID),
                  batchn: values.batchNumber.trim(),
                  sop: values.caProvinceList.trim(),
                  pt: values.packageType.trim(),
                  pq: values.packageQuantity.trim(),
                  contact_us_flavor_name: values.flavorName.trim()
                }

                axios({
                    method: "POST",
                    // PROD
                    url: CONTACT_US_BASE_URL.PROD,
                    data: JSON.stringify(data),
                    cache: false,
                    headers: {
                        "Content-Type": "application/json; charset=utf-8"
                    }
                }).then(function (response) {
                    // console.log('response  ', response)
                    // set data to data-layer
                    setContactUsFormSubmittedEvent(dlData)
                    // Set data for success fail modal
                    setUserName(values.firstName.trim())
                    setModalBodyCopy(validations.messageYes)
                    setShowSuccessFailModal(true)
                    // Reset birthday
                    setBirthDayValue("")
                    // Reset form
                    resetForm()
                }).catch(function () {
                    // Set data for success fail modal
                    setUserName(values.firstName.trim())
                    setModalBodyCopy(validations.messageNo)
                    setShowSuccessFailModal(true)
                    // Reset birthday
                    setBirthDayValue("")
                    // Reset form
                    resetForm()
                })

                setSubmitting(false)
            }}
            >

            {({ values, errors, submitCount, handleChange, setFieldValue }) => {
                return (
                  <Form id="frm-contact-form" autoComplete="off">
                    <div className="row">
                      <div className="col-md-6 form-group control-group">
                        {/* Email */}
                        <Field type="email" id="form-email" className="form-control" name="email" placeholder={validations.email} />

                        {submitCount > 0 && errors.email !== "" ? (
                          <>
                            <div className="help-block with-errors">
                              <ul role="alert" className={"list-unstyled " + (!errors.email ? "d-none" : "")}>
                                <li>{errors.email}</li>
                              </ul>
                            </div>
                          </>
                        ) : null}
                      </div>

                      <div className="col-md-6 form-group">
                        <label className="select">
                          {/* Inquiry Type */}
                          <Field
                            as="select"
                            className="form-control"
                            name="inquiryTypeID"
                            onChange={(e, event) => {
                              const value = e.target.value;
                              setFieldValue("inquiryTypeID", value);
                              if (value !== "100000003") {
                                setFieldValue("batchNumber", "");
                                setFieldValue("caProvinceList", "");
                                setFieldValue("packageType", "");
                                setFieldValue("packageQuantity", "");
                                setFieldValue("flavorName", "");
                                setFieldValue("extraFieldsCheckbox", false);
                              }
                            }}
                          >
                            <option value="">{validations.pleaseSelectTopic}</option>
                            <option value="100000003" data-subject={validations.qualityConcern}>
                              {validations.qualityConcern}
                            </option>
                            <option value="100000005" data-subject={validations.sponsorRequest}>
                              {validations.sponsorRequest}
                            </option>
                            <option value="100000007" data-subject={validations.partnerMedia}>
                              {validations.partnerMedia}
                            </option>
                            <option value="100000002" data-subject={validations.ingredientNutr}>
                              {validations.ingredientNutr}
                            </option>
                            <option value="100000001" data-subject={validations.prodFlavFeedback}>
                              {validations.prodFlavFeedback}
                            </option>
                            <option value="100000000" data-subject={validations.other}>
                              {validations.other}
                            </option>
                          </Field>
                          <FontAwesomeIcon icon={faChevronDown} className="fa-solid" aria-hidden="false" />
                          {submitCount > 0 && errors.inquiryTypeID !== "" ? (
                            <>
                              <div className="help-block with-errors">
                                <ul role="alert" className={"list-unstyled " + (!errors.inquiryTypeID ? "d-none" : "")}>
                                  <li>{errors.inquiryTypeID}</li>
                                </ul>
                              </div>
                            </>
                          ) : null}
                        </label>
                      </div>
                    </div>

                    <div className={"row hidden-block batch-code " + (values.inquiryTypeID === "100000003" ? "" : "toggle-hide")}>
                      <div className="col-12">
                        <div className="row">
                          <div className="col-12">
                            <p>
                              {validations.batchNumberCanBeFound}
                              <a href="#" onClick={openBatchImage} onKeyDown={openBatchImage}>
                                {validations.viewImage}
                              </a>
                              .
                            </p>
                            {/* Batch Image Component */}
                            <BatchImage showBatchImage={showBatchImage} closeBatchImage={closeBatchImage} activeDocMeta={activeDocMeta}/>
                          </div>

                          <div className="col-12 col-md-4 form-group">
                            {/* Batch Number */}
                            <Field type="text" id="form-batchcode" className="form-control" name="batchNumber" placeholder={validations.batchNumberDD} disabled={extraFieldsContainer} />

                            {submitCount > 0 && errors.batchNumber !== "" ? (
                              <>
                                <div className="help-block with-errors">
                                  <ul role="alert" className={"list-unstyled " + (!errors.batchNumber ? "d-none" : "")}>
                                    <li>{errors.batchNumber}</li>
                                  </ul>
                                </div>
                              </>
                            ) : null}
                          </div>

                          <div className=" col-12 col-md-4 form-group">
                            <label className="select">
                              {/* CA Provinces */}
                              <Field as="select" className="form-control" name="caProvinceList">
                                {storeStateOptions.map((d) => (
                                  <option key={d.value}>{d.label}</option>
                                ))}
                              </Field>
                              <FontAwesomeIcon icon={faChevronDown} className="fa-solid" aria-hidden="false" />
                              {submitCount > 0 && errors.caProvinceList !== "" ? (
                                <>
                                  <div className="help-block with-errors">
                                    <ul role="alert" className={"list-unstyled " + (!errors.caProvinceList ? "d-none" : "")}>
                                      <li>{errors.caProvinceList}</li>
                                    </ul>
                                  </div>
                                </>
                              ) : null}
                            </label>
                          </div>

                          <div className="col-12 col-md-4 form-group">
                            {/* Extra Fields Checkbox */}
                            <label className="btn btn-block extra-fields-checkbox">
                              <Field
                                type="checkbox"
                                name="extraFieldsCheckbox"
                                id="extra-fields-checkbox"
                                className="visually-hidden "
                                onClick={toggleExtraFieldsContainer}
                                onChange={(e) => {
                                  if (!extraFieldsContainer) {
                                    setFieldValue("batchNumber", validations.naDD);
                                  } else {
                                    setFieldValue("batchNumber", "");
                                  }
                                  handleChange(e);
                                }}
                              />
                              {extraFieldsBtnText}
                            </label>
                            {submitCount > 0 && errors.extraFieldsCheckbox !== "" ? (
                              <>
                                <div className="help-block with-errors">
                                  <ul role="alert" className={"list-unstyled " + (!errors.extraFieldsCheckbox ? "d-none" : "")}>
                                    <li>{errors.extraFieldsCheckbox}</li>
                                  </ul>
                                </div>
                              </>
                            ) : null}
                          </div>

                          {/* Extra Fields */}
                          <div className="col-12">
                            <div className="row">
                              <div className="col-12">
                                <div id="extra-fields-container" className={"row " + (extraFieldsContainer ? "" : "d-none")}>
                                  <div className="col-md-6 form-group">
                                    <label className="select">
                                      {/* Package Type */}
                                      <Field as="select" id="form-package-type" className="extra-fields-js form-control" name="packageType">
                                        <option value="">{validations.selectPackageTypeDD}</option>
                                        <option value={validations.canDD} data-subject={validations.canDD}>
                                          {validations.canDD}
                                        </option>
                                      </Field>
                                      <FontAwesomeIcon icon={faChevronDown} className="fa-solid" aria-hidden="false" />
                                      {submitCount > 0 && errors.packageType !== "" ? (
                                        <>
                                          <div className="help-block with-errors">
                                            <ul role="alert" className={"list-unstyled " + (!errors.packageType ? "d-none" : "")}>
                                              <li>{errors.packageType}</li>
                                            </ul>
                                          </div>
                                        </>
                                      ) : null}
                                    </label>
                                  </div>

                                  <div className="col-md-6 form-group">
                                    <label className="select">
                                      {/* Package Quantity */}
                                      <Field as="select" id="form-package-quantity" className="extra-fields-js form-control" name="packageQuantity">
                                        <option value="">{validations.packageQuantityDD}</option>
                                        <option value={validations.singleDD} data-subject={validations.singleDD}>
                                          {validations.singleDD}
                                        </option>
                                        <option value={validations.sixPackDD} data-subject={validations.sixPackDD}>
                                          {validations.sixPackDD}
                                        </option>
                                        <option value={validations.eightPackDD} data-subject={validations.eightPackDD}>
                                          {validations.eightPackDD}
                                        </option>
                                        <option value={validations.twelvePackDD} data-subject={validations.twelvePackDD}>
                                          {validations.twelvePackDD}
                                        </option>
                                        <option value={validations.twentyFourPackDD} data-subject={validations.twentyFourPackDD}>
                                          {validations.twentyFourPackDD}
                                        </option>
                                      </Field>
                                      <FontAwesomeIcon icon={faChevronDown} className="fa-solid" aria-hidden="false" />
                                      {submitCount > 0 && errors.packageQuantity !== "" ? (
                                        <>
                                          <div className="help-block with-errors">
                                            <ul role="alert" className={"list-unstyled " + (!errors.packageQuantity ? "d-none" : "")}>
                                              <li>{errors.packageQuantity}</li>
                                            </ul>
                                          </div>
                                        </>
                                      ) : null}
                                    </label>
                                  </div>

                                  <div className="col-md-6 form-group">
                                    {/* Flavor Name */}
                                    <Field type="text" id="form-flavor-name" className="extra-fields-js form-control" name="flavorName" placeholder={validations.flavorNameDD} />

                                    {submitCount > 0 && errors.flavorName !== "" ? (
                                      <>
                                        <div className="help-block with-errors">
                                          <ul role="alert" className={"list-unstyled " + (!errors.flavorName ? "d-none" : "")}>
                                            <li>{errors.flavorName}</li>
                                          </ul>
                                        </div>
                                      </>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Hidden Fields Component */}
                    <HiddenFields inquiryTypeID={values.inquiryTypeID} />

                    <div className="row">
                      <div className="col-md-6 form-group">
                        {/* First Name */}
                        <Field type="text" id="form-firstname" className="form-control" name="firstName" placeholder={validations.firstNameDD} />

                        {submitCount > 0 && errors.firstName !== "" ? (
                          <>
                            <div className="help-block with-errors">
                              <ul role="alert" className={"list-unstyled " + (!errors.firstName ? "d-none" : "")}>
                                <li>{errors.firstName}</li>
                              </ul>
                            </div>
                          </>
                        ) : null}
                      </div>

                      <div className="col-md-6 form-group">
                        {/* Last Name */}
                        <Field type="text" id="form-lastname" className="form-control" name="lastName" placeholder={validations.lastNameDD} />

                        {submitCount > 0 && errors.lastName !== "" ? (
                          <>
                            <div className="help-block with-errors">
                              <ul role="alert" className={"list-unstyled " + (!errors.lastName ? "d-none" : "")}>
                                <li>{errors.lastName}</li>
                              </ul>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>

                    <div className='row'>
                      <div className="col-md-4 form-group">
                        {/* Birthday */}
                        <Field
                          type="text"
                          id="form-birthdate"
                          className="form-control"
                          name="birthDate"
                          placeholder={validations.birthDateDD}
                          onChange={handleBirthdDateChange}
                          value={(values.birthDate = birthDayValue)}
                        />

                        {submitCount > 0 && errors.birthDate !== "" ? (
                          <>
                            <div className="help-block with-errors">
                              <ul role="alert" className={"list-unstyled " + (!errors.birthDate ? "d-none" : "")}>
                                <li>{errors.birthDate}</li>
                              </ul>
                            </div>
                          </>
                        ) : null}
                      </div>
                      <div className="col-md-4 form-group">
                        <label className="select">
                          {/* Gender */}
                          <Field
                            as="select"
                            className="form-control"
                            name="gender"
                            onChange={(e, event) => {
                              const value = e.target.value;
                              setFieldValue("gender", value);
                            }}
                          >
                            <option value="">{validations.provideGender}</option>
                            <option value="1" data-subject={validations.genderM}>
                              {validations.genderM}
                            </option>
                            <option value="2" data-subject={validations.genderF}>
                              {validations.genderF}
                            </option>
                            <option value="3" data-subject={validations.genderO}>
                              {validations.genderO}
                            </option>
                          </Field>
                          <FontAwesomeIcon icon={faChevronDown} className="fa-solid" aria-hidden="false" />
                          {submitCount > 0 && errors.gender !== "" ? (
                            <>
                              <div className="help-block with-errors">
                                <ul role="alert" className={"list-unstyled " + (!errors.gender ? "d-none" : "")}>
                                  <li>{errors.gender}</li>
                                </ul>
                              </div>
                            </>
                          ) : null}
                        </label>
                      </div>
                      <div className="col-md-4 form-group">
                        {/* Postal Code */}
                        <Field type="text" id="form-postalcode" className="form-control" name="postalCode" placeholder={validations.postalCode} />

                        {submitCount > 0 && errors.postalCode !== "" ? (
                          <>
                            <div className="help-block with-errors">
                              <ul role="alert" className={"list-unstyled " + (!errors.postalCode ? "d-none" : "")}>
                                <li>{errors.postalCode}</li>
                              </ul>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 form-group form-text-field">
                        {/* Message */}
                        <Field as="textarea" id="form-message" className="form-control" name="message" rows="5" type="text" placeholder={validations.msgDD} />

                        {submitCount > 0 && errors.message !== "" ? (
                          <>
                            <div className="help-block with-errors">
                              <ul role="alert" className={"list-unstyled " + (!errors.message ? "d-none" : "")}>
                                <li>{errors.message}</li>
                              </ul>
                            </div>
                          </>
                        ) : null}
                      </div>

                      <div className='col-12 form-group form-text-field form-btn-container d-flex justify-content-end'>
                        {/* Submit Btn */}
                        <button className="form-btn" type="submit" id="submit-contact-form">
                          {validations.submitBtn}
                        </button>
                      </div>
                    </div>
                    {/* Success Fail Component */}
                    <SuccessFailModal showSuccessFailModal={showSuccessFailModal} closeSuccessFailModal={closeSuccessFailModal} userName={userName} modalBodyCopy={modalBodyCopy} activeLang={lang}/>
                  </Form>
                );
            }}
        </Formik>
        {/* Eof form */}
    </>
    )
}

export default ContactUsForm





