// core
import React from 'react'
import Modal from 'react-bootstrap/Modal'

// components
import ImgWithFallback from '../ImgWithFallback'

// assets
import batchSrc from '../../assets/images/contact-page/batch.webp';
import batchSrcFallback from '../../assets/images/contact-page/batch.png';

const BatchImage = ({ showBatchImage, closeBatchImage, activeDocMeta }) => {
  const { lang } = activeDocMeta ? activeDocMeta : "en-ca"

  return (
    <>
      <Modal show={showBatchImage} onHide={closeBatchImage} animation={false} className="contact-us">
        <Modal.Body>
          <div className="form-group">
            <ImgWithFallback
              classNamePicture={""}
              classNameImg={"img-fluid d-block mx-auto"}
              src={batchSrc}
              fallbackSrc={batchSrcFallback}
              alt="White Claw Logo"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn modal-close-btn mr-0" type="button" onClick={closeBatchImage} onKeyDown={closeBatchImage}>
            {lang === 'en-ca' ? 'Close' : 'Fermer'}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default BatchImage