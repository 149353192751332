import React from 'react'
import parse from "html-react-parser";

import * as regExp from '../constants/regexpPatterns'

const replaceTrademark = (text) => {
  if(text.includes("MC")){
    return  parse(text.replace('"MC"', '<sup className="mc-trademark">MC</sup>'))
  } else if(text.includes("MD")){
    return parse(text.replace('"MD"', '<sup className="mc-trademark">MD</sup>'))
  } else {
    return parse(text.replace(regExp.tradeMarks, '<sup className="trademark">$&</sup>'))
  }
}


export const htmlSerializer = {
  paragraph: ({text}) => replaceTrademark(text),
  heading1: ({text}) =>  replaceTrademark(text),
  heading2: ({text}) =>  replaceTrademark(text),
  heading3: ({text}) =>  replaceTrademark(text),
  heading4: ({text}) =>  replaceTrademark(text),
  heading5: ({text}) =>  replaceTrademark(text),
  heading6: ({text}) =>  replaceTrademark(text),
}