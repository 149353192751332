const CAREER_FORM_VALIDATION_EN = {
  firstName: "Please provide a first name.",
  lastName: "Please provide a last name.",
  tooShort: "Too Short!",
  tooLong: "Too Long!",
  onlyAlpha: "Only alphabetic characters are allowed.",
  phoneNumber: "Please provide a phone number.",
  email: "Please provide an email.",
  validEmail: "Please provide a valid email",
  message: "Please provide a message.",
  topic: "Please select a topic.",
  captcha: "Captcha is required.",
  msgYes: "Your message has been received <br> and we'll respond as soon as we can.",
  msgNo: "It seems that our mail server is not responding. <br> Please try again later!",
  firstNameField: "First Name*",
  lastNameField: "Last Name*",
  phoneField: "Phone Number*",
  emailField: "Email*",
  contactReason: "Reason for contact*",
  carNotLoad: "The careers site isn't loading",
  resume: "Unable to attach resume",
  login: "Login/Password",
  otherTech: "Other technical issue",
  formMessage: "Message*",
  submitBtn: "SUBMIT",
}

const CAREER_FORM_VALIDATION_FR = {
  firstName: "Please provide a first name.",
  lastName: "Please provide a last name.",
  tooShort: "Too Short!",
  tooLong: "Too Long!",
  onlyAlpha: "Only alphabetic characters are allowed.",
  phoneNumber: "Please provide a phone number.",
  email: "Please provide an email.",
  validEmail: "Please provide a valid email",
  message: "Please provide a message.",
  topic: "Please select a topic.",
  captcha: "Captcha is required.",
  msgYes: "Your message has been received <br> and we'll respond as soon as we can.",
  msgNo: "It seems that our mail server is not responding. <br> Please try again later!",
  firstNameField: "First Name*",
  lastNameField: "Last Name*",
  phoneField: "Phone Number*",
  emailField: "Email*",
  contactReason: "Reason for contact*",
  carNotLoad: "The careers site isn't loading",
  resume: "Unable to attach resume",
  login: "Login/Password",
  otherTech: "Other technical issue",
  formMessage: "Message*",
  submitBtn: "SUBMIT",
}

export { CAREER_FORM_VALIDATION_EN, CAREER_FORM_VALIDATION_FR }