import React,{useState} from 'react'
import useWindowSize from '../assets/js/useWindowSize';

const AwardListComponent=({awardPageData}) => {
    const {width}=useWindowSize()
    const [loadMore,setLoadMore]=useState(false);
    const loadCount=loadMore? awardPageData.length:3;
    const loadCountMobile=loadMore? awardPageData.length:2;

    const showMoreAwards=() => {
        setLoadMore(!loadMore)
    }
    return (
        <section className='awards-section'>
            <h2 className='award-heading'>AWARDS</h2>
            <div className='row'>
                {
                    awardPageData?.slice(0,width>=991? loadCount:loadCountMobile)?.map((data,index) => (
                        <div className='col-lg-4 col-6'>
                            <div>
                                <img src={data?.award_image?.url} className='award-image-common award_image' />
                            </div>
                            <div>
                                <img src={data?.award_sub_image?.url} className='award-image-common award_sub_image' />
                            </div>
                            <div className='award-title-container'>
                                <h4 className='award-title'>{data?.award_title?.text}</h4>
                            </div>
                            <div className='award-subtitle-container'>
                                <p className='award-subtitle'>{data?.award_sub_title?.text}</p>
                            </div>
                        </div>
                    ))
                }
            </div>
            {
                width>=991?
                    awardPageData?.length>3?
                        <div className='text-center see-more-btn'>
                            <a onClick={() => showMoreAwards()}>{loadMore? "SEE LESS":"SEE MORE"}</a>
                        </div>
                        :null
                    :awardPageData?.length>2?
                        <div className='text-center see-more-btn'>
                            <a onClick={() => showMoreAwards()}>{loadMore? "SEE LESS":"SEE MORE"}</a>
                        </div>
                        :null
            }
        </section>
    )
}

export default AwardListComponent
