import React, { useState } from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import LanguageSwitcher from './LanguageSwitcher'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebook,
  faXTwitter,
  faInstagram
} from '@fortawesome/free-brands-svg-icons'

// components
import CountrySelector from './CountrySelector'

// assets
import globeWhite from '../assets/images/icons/globe-white.svg'

// preview
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

// data-layer
import { setSocialIconClickedEvent } from "../assets/js/data-layer";

// constants
import { SOCIAL_PLATFORM } from "../constants/socials";

const Footer = ({disabledMenu, activeDocMeta}) => {
  const { lang } = activeDocMeta
  // console.log('footer lang', lang)
  const queryData = useStaticQuery(graphql`
  {
    EN: prismicFooterMenu(lang: { eq: "en-ca" }) {
      id
      _previewable
      data {
        body {
          ... on PrismicFooterMenuDataBodyFooterLegal {
            primary {
              footer_text {
                text
              }
            }
            slice_type
          }
        }
        button_text
        menu_links {
          label {
            text
          }
          link {
            id
            url
          }
        }
      }
    }
    FR: prismicFooterMenu(lang: { eq: "fr-ca" }) {
      id
      _previewable
      data {
        body {
          ... on PrismicFooterMenuDataBodyFooterLegal {
            primary {
              footer_text {
                text
              }
            }
            slice_type
          }
        }
        button_text
        menu_links {
          label {
            text
          }
          link {
            id
            url
          }
        }
      }
    }
  }
  `)

  const { data } = useMergePrismicPreviewData(queryData)
  const pageData = lang === 'en-ca' ?  data.EN.data :  data.FR.data
  // console.log('pageData', pageData)
  const menuLinks = pageData.menu_links
  // const menuLinksLength = menuLinks.length
  const footerLinks = menuLinks.filter(item => !["Privacy","Terms & Conditions","Conditions Générales","Politique de Confidentialité"].includes(item.label.text));
  const subFooterLinks = menuLinks.filter(item => ["Privacy","Terms & Conditions","Conditions Générales","Politique de Confidentialité"].includes(item.label.text));

  // country select functions
  const [showCountrySelector, setShowCountrySelector] = useState(false)
  const closeCountrySelector = () => setShowCountrySelector(false)
  const openCountrySelector = () => setShowCountrySelector(true)

  const onSocialIconClick = (platform) => {
    const dl = {
      platform,
      location: 'footer'
    }
    setSocialIconClickedEvent(dl)
  }

  return (
    <footer className="us-site">
      <div className="footer-top d-flex flex-column">
        <div className="footer-country-selector d-flex justify-content-between">
          {/* Country Selector */}
          <Link to="/" title="White Claw" className="footer-logo"></Link>
          <LanguageSwitcher activeDocMeta={activeDocMeta} />
          <CountrySelector showCountrySelector={showCountrySelector} closeCountrySelector={closeCountrySelector} activeDocMeta={lang} />
        </div>
        <div className="footer-social">
          <ul className="list-unstyled d-flex flex-lg-row align-content-center footer-menu">
            <li className="text-center">
              <a
                  href="https://www.facebook.com/whiteclawcanada"
                  target="_blank"
                  rel='noopener noreferrer'
                  onClick={()=> {
                    onSocialIconClick(SOCIAL_PLATFORM.FACEBOOK)
                  }}
              >
                <FontAwesomeIcon icon={faFacebook} className="fa-icons" aria-hidden="false"/>
              </a>
            </li>
            <li className="text-center">
              <a
                  href="https://www.instagram.com/whiteclawcanada"
                  target="_blank"
                  rel='noopener noreferrer'
                  onClick={()=>onSocialIconClick(SOCIAL_PLATFORM.INSTAGRAM)}
              >
                <FontAwesomeIcon icon={faInstagram} className="fa-icons" aria-hidden="false"/>
              </a>
            </li>
            <li className="text-center">
              <a
                  href="https://www.twitter.com/whiteclawcanada"
                  target="_blank"
                  rel='noopener noreferrer'
                  onClick={()=>onSocialIconClick(SOCIAL_PLATFORM.TWITTER)}
              >
                <FontAwesomeIcon icon={faXTwitter} className="fa-icons" aria-hidden="false"/>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="footer-bottom">

          {(disabledMenu) ? [] :
          <ul className="list-unstyled d-flex flex-column justify-content-center align-content-center footer-menu">
              {
                footerLinks.map((navItem, index) => {
                  return (
                    <li key={`link-${index}`} className="nav-item web">
                      <Link
                      to={(navItem.label.text === "Do Not Sell My Personal Information")
                        ?  navItem.link.url + "?subject=do-not-sell-my-personal-information"
                        : navItem.link.url || '#'
                      }
                      target="_self"
                      title={"White Claw " + (navItem.label.text)}
                      >
                        {navItem.label.text}
                      </Link>
                    </li>
                  )
                })
              }
            </ul>
          }
        <br />

        <div className="footer-terms text-white d-flex justify-content-between">
        <div className='privacy-text'>
          {lang === "en-ca" ?
            <><span>Please Drink Responsibly.</span><br /><small>© 2024, TM and ® – trademarks of White Claw Seltzer Co.</small></>
            :
            <><span>Consommez avec modération.</span><br /><small>© 2024, MC et MD – marques de commerce de White Claw Seltzer Co.</small></>
        }


        </div>
        <div className="align-content-flex-end">
          {(disabledMenu) ? [] :
          <ul className="list-unstyled d-flex flex-row justify-content-end footer-menu">
              {
                subFooterLinks.map((navItem, index) => {
                  return (
                    <li key={`link-${index}`} className="nav-item web">
                      <Link
                      to={(navItem.label.text === "Do Not Sell My Personal Information")
                        ?  navItem.link.url + "?subject=do-not-sell-my-personal-information"
                        : navItem.link.url || '#'
                      }
                      target="_self"
                      title={"White Claw " + (navItem.label.text)}
                      >
                        {navItem.label.text}
                      </Link>
                    </li>
                  )
                })
              }
            </ul>
          }
      </div>
    </div>

  </div>

</footer>
  )
}

export default Footer