// core
import React from "react";
import { Link } from 'gatsby'
import checkMissingData from '../../utils/checkMissingData'
import displayMissingFieldWarning from '../../utils/displayMissingDataWarning'

// data-layer
import { setProductFlavorClickedEvent } from "../../assets/js/data-layer";

const FeaturedProducts = ({ slice }) => {
  const data = slice.primary;
  const items = slice.items;
  const bgImageUrl = {
    screen : data?.background_image?.url || ''
  }

  const title = slice.primary.header_text?.richText.map((item)=>{ return item.text})
  const titleSubText = slice.primary.header_subtext?.richText.map((item)=>{ return item.text})

  return (
      <section className='featured-products__section' style={{backgroundImage: `url(${bgImageUrl.screen})`}}>
        <div className="featured-products">
          <h2 className="featured-products__header">
            {title?.length !== 0 && title.map((row, index)=> {
              return  <span key={row} className='text-center featured-products__header-wrap'>
                        <span className="featured-products__header-text">{row}&ensp;</span><br/>
                { titleSubText[index] && <span className='featured-products__header-subtext'>{titleSubText[index]}&ensp;</span> }
                      </span>
            })
            }
          </h2>
          <div className="featured-products__list">
            {data.product_category.document ?
                data.product_category.document?.data.category_item.map((element, index) => {
                  if(checkMissingData(element.category_item_link.document)) {
                    return displayMissingFieldWarning('element.category_item_link.document')
                  } else {
                    const itemType = element.category_item_link.document?.data.product_type;
                    const flavor = element.category_item_link.document?.data.taxonomy_product_flavor
                    const size = element.category_item_link.document?.data.taxonomy_product_size
                    const category = data.product_category.document?.data.category_name.toLowerCase() || ''
                    if (itemType !== "Pack") {
                      return (
                          <Link
                              key={index}
                              to={element.category_item_link.document?.url}
                              _target="self"
                              className="featured-products__item"
                              onClick={() => {
                                // data-layer
                                const dl = {
                                  flavor,
                                  category,
                                  position: index + 1,
                                  url: element.category_item_link.document?.url,
                                  location: 'explore more section',
                                  size
                                }
                                setProductFlavorClickedEvent(dl)
                              }}
                          >
                            <img
                                className="featured-products__item-image"
                                src={element.category_item_link.document?.data.product_image_png.url}
                                alt={
                                  element.category_item_link.document?.data.product_image_png.alt
                                      ? element.category_item_link.document?.data.product_image_png.alt
                                      : element.category_item_link.document?.data.product_name
                                }
                            />
                            <span className="featured-products__item-name">{element.category_item_link.document?.data.product_name.text}</span>
                            <span className="featured-products__item-tag">{
                              data.product_category.document?.data.category_new_text
                                  ? data.product_category.document?.data.category_new_text
                                  : element.category_item_link.document?.data.product_new_text
                                      ? element.category_item_link.document?.data.product_new_text
                                      : null
                            }</span>
                          </Link>
                      );
                    }
                  }
                })
                : items.map((element, index) => {
                  const flavor = element.item_link.document?.data.taxonomy_product_flavor
                  const size = element.item_link.document?.data.taxonomy_product_size

                  return (
                      <Link
                          key={index}
                          to={element.item_link.url}
                          _target="self"
                          className="featured-products__item"
                          onClick={() => {

                            // data-layer
                            // TODO add sizes and names (product taxonomy)
                            const dl = {
                              flavor,
                              category: '',
                              position: index + 1,
                              url: element.item_link.url,
                              location: 'explore more section',
                              size
                            }
                            setProductFlavorClickedEvent(dl)
                          }}
                      >
                        <img className="featured-products__item-image" src={element.item_link.document?.data.product_image_png.url} alt={element.item_link.document?.data.product_image_png.alt ? element.item_link.document?.data.product_image_png.alt : element.item_link.document?.data.product_name.text} />
                        <span className="featured-products__item-name">{element.item_link.document?.data.product_name.text}</span>
                        <span className="featured-products__item-tag">{element.item_link.document?.data.product_new_text}</span>
                      </Link>
                  );
                })}
          </div>
        </div>
      </section>
  );
};

export default FeaturedProducts