import React, { useState } from 'react'
import { Link, graphql } from 'gatsby'

// assets
import * as images from "../../assets/images/icons";
import { downloadMedia } from '../../assets/js/downloadMedia';
import { getButtonCustomStyles } from '../../assets/js/getButtonCustomStyles';
import { getFontSize } from '../../assets/js/getFontSize';

import useWindowSize from "../../hooks/useWindowSize";

// constants
import { COLOR } from "../../constants/colors";
import {AnchorLink} from "../../components/AnchorLink";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpRightFromSquare} from "@fortawesome/free-solid-svg-icons/faUpRightFromSquare";
import {faDownload} from "@fortawesome/free-solid-svg-icons/faDownload";


export const YouTubeVideoSection = ({ slice }) => {
  // console.log("YouTubeVideoSection Slice", slice)
  const { width } = useWindowSize()
  const [isPlay, setIsPlay] = useState(false)

  const sectionId = slice.primary.section_id?.text || slice.id
  const anchorLink = slice.primary.anchor_link?.text || ''

  // Toggles
  const showTitle = slice.primary.show_title
  const showParagraph = slice.primary.show_paragraph
  const showCTAButton = slice.primary.show_cta_button

  // Links
  const isDownloadLink = slice.primary.cta_download_button
  const isTargetBlank = slice.primary.cta_button_open_in_new_tab
  const target = isTargetBlank ? '_blank' : '_self'

  // Text
  const title = slice.primary.title?.richText || []
  const titleTextColor = slice.primary.title_text_color || COLOR.BLACK
  const titleTextSizeDesktop = slice.primary.title_font_size_desktop || '66'
  const titleTextSizeMobile = slice.primary.title_font_size_mobile || '40'
  const titleTextFontSize = getFontSize(width, titleTextSizeDesktop, titleTextSizeMobile)

  const paragraph = slice.primary.paragraph?.richText || []
  const paragraphTextColor = slice.primary.paragraph_text_color || COLOR.BLACK
  const paragraphTextSizeDesktop = slice.primary.paragraph_font_size_desktop || '24'
  const paragraphTextSizeMobile = slice.primary.paragraph_font_size_mobile || '24'
  const paragraphTextSizeSize = getFontSize(width, paragraphTextSizeDesktop, paragraphTextSizeMobile)

  const titleStyles = {
    color: titleTextColor,
    fontSize: titleTextFontSize
  }

  const paragraphStyles = {
    color: paragraphTextColor,
    fontSize: paragraphTextSizeSize
  }

  // Video
  const videoContainerWidth = `${slice.primary.video_width || 100}%`
  const youtubeSrc = slice.primary.video_link || ''


  const videoPoster = {
    imageMobile: slice.primary.video_poster_image?.thumbnails.mobile?.url || '',
    imageScreen: slice.primary.video_poster_image?.url || ''
  }

  // CTA Button
  const ctaButton = {
    text: slice.primary.cta_button_text?.text || '',
    url: slice.primary.cta_button_link?.url || ''
  }

  const ctaDownloadButtonFileName = slice.primary.cta_download_file_name || ''
  const ctaButtonDownloadLink = slice.primary.cta_download_button_file?.url || ''

  // CTA Styles
  const buttonColorSet = {
    color: slice.primary.cta_button_text_color,
    bg: slice.primary.cta_button_bg_color,
    bgT: slice.primary.cta_button_bg_color_transparency,
    border: slice.primary.cta_button_border_color,
    colorHover: slice.primary.cta_button_text_color_hover,
    bgHover: slice.primary.cta_button_bg_color_hover,
    bgHoverT: slice.primary.cta_button_bg_color_hover_transparency,
    borderHover: slice.primary.cta_button_border_color_hover,
  }

  const { ctaButtonStyle } = getButtonCustomStyles(buttonColorSet)

  // Play Video Button
  const playButton = {
    text: slice.primary.video_play_button_text?.text || '',
    textColor: slice.primary.video_play_button_text_color || COLOR.WHITE,
    image: slice.primary.video_play_button_image?.url || images.playVideoBtnPng
  }

  const playButtonStyles = {
    color: playButton.textColor || COLOR.WHITE
  }
  

  const onPlayBtnClick = () => {
    setIsPlay(true)
  }

  return (
      <section id={`youtube-video-${sectionId}`} className="youtube-video"
      >
        { anchorLink && <AnchorLink link={anchorLink}/> }
        <div className='youtube-video__text-wrap'>
          { showTitle && <h3 className='youtube-video__title'>
            { title.map((row) => {
              return  <span key={row.text} style={titleStyles}>{row.text}<br/></span>})
            }
          </h3> }

          { showParagraph && <p className='youtube-video__paragraph'>
            { paragraph.map((row) => {
              return  <span key={row.text} style={paragraphStyles}>{row.text}<br/></span>})
            }
          </p>}

          {showCTAButton && <>
            { !isDownloadLink && <a href={ctaButton.url} title={ctaButton.text} className="youtube-video__cta-button mt-4" style={ctaButtonStyle} target={target}>
              { ctaButton.text }
              { isTargetBlank && <FontAwesomeIcon icon={faUpRightFromSquare} className='ml-2'/>}
            </a> }

            { isDownloadLink && <button title={ctaButton.text} className="youtube-video__cta-button mt-4" style={ctaButtonStyle}
                                        onClick={()=>downloadMedia(ctaButtonDownloadLink, ctaDownloadButtonFileName)}
            >
              { ctaButton.text }
              <FontAwesomeIcon icon={faDownload} className='ml-2'/>
            </button> }
          </>
          }
        </div>

        <div className="youtube-video__content-wrap"
             style={{ backgroundImage: isPlay && youtubeSrc ? "unset" : `url(${videoPoster.imageScreen})`, width: `${videoContainerWidth}`}}
        >
          { !isPlay && <div className="youtube-video__content">
            <div>
              {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions*/}
              <a className="youtube-video__video-btn"
                 onKeyDown={onPlayBtnClick}
                 onClick={onPlayBtnClick}
              >
                <img className='w-100' src={playButton.image} alt='Play button'/>
              </a>

              <p className="youtube-video__button-text" style={playButtonStyles}>
                { playButton.text }
              </p>
            </div>
          </div>  }

          { isPlay && youtubeSrc && <div className="youtube-video__inline-video">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                  id="video"
                  title={youtubeSrc}
                  className="embed-responsive-item"
                  src={`${youtubeSrc}?autoplay=1&amp;modestbranding=1&amp;showinfo=0&amp;rel=0`}
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen
              >
              </iframe>
            </div>
          </div> }
        </div>
      </section>
  )
}

export const fragment = graphql`
  fragment PageDataBodyYoutubeVideoSection on PrismicGenericPageDataBodyYoutubeVideoSection  {
    id
    primary {
      section_id{
        text
      }
      anchor_link{
        text
      }
      cta_button_bg_color
      cta_button_bg_color_hover
      cta_button_border_color
      cta_button_border_color_hover
      cta_button_link {
        url
      }
      cta_button_text {
        text
        richText
      }
      cta_button_text_color
      cta_button_text_color_hover
      cta_button_bg_color_transparency
      cta_button_bg_color_hover_transparency
      cta_button_open_in_new_tab
      cta_download_button
      cta_download_button_file {
        url
      }
      cta_download_file_name
      paragraph {
        text
        richText
      }
      paragraph_text_color
      paragraph_font_size_desktop
      paragraph_font_size_mobile
      show_cta_button
      show_paragraph
      show_title
      title {
        text
        richText
      }
      title_text_color
      title_font_size_desktop
      title_font_size_mobile
      video_link
      video_play_button_image {
        url
        alt
      }
      video_play_button_text {
        text
        richText
      }
      video_play_button_text_color
      video_poster_image {
        thumbnails {
          mobile {
            alt
            url
          }
        }
        url(imgixParams: {q: 100, dpi: 300, dpr: 2, auto: "enhance"})
        alt
      }
      video_width
    }
  }
`;