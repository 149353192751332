import NutritionAndIngredientsTable from "./NutritionAndIngredientsTable";

import NutritionTable from './NutritionTable'
import AllFlavors from './AllFlavors'
import FeaturedProducts from './FeaturedProducts'

export const pdpComponents = {
    nutrition_table: NutritionTable,
    all_flavors: AllFlavors,
    featured_products: FeaturedProducts,
    nutrition_and_ingredients_table: NutritionAndIngredientsTable,
}