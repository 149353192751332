import React, {useEffect, useRef} from 'react'
import { useLocation } from "@reach/router";

// assets
import useWindowSize from "../assets/js/useWindowSize";
import * as desktopVideo from '../../static/videos/desktop'
import * as mobileVideo from '../../static/videos/mobile'

// data-layer
import { setLearnMoreClickedEvent } from "../assets/js/data-layer";

// components
import CustomLink from "./CustomLink";

// constants
import { LANG } from "../constants";

const HomeHero = ({pageQuery, activeDocMeta}) => {
  const { width } = useWindowSize()
  const playerRef = useRef()
  const location = useLocation()


  const PAGE_KEY = {
    THE_DIFFERENCE_IS_CLEAR: "the-difference-is-clear"
  }

  const pageKey = pageQuery.key

  const button = {
    href: pageQuery.button.url,
    text: pageQuery.button_text.text
  }
  // console.log('home-hero', pageQuery)

  const video = {
    desktop: activeDocMeta.lang === LANG.EN ? desktopVideo.tdicEn : desktopVideo.tdicFr,
    mobile: activeDocMeta.lang === LANG.EN ? mobileVideo.tdicEn : mobileVideo.tdicFr
  }


  useEffect(()=>{
    if (!playerRef?.current) return

    playerRef.current.addEventListener('suspend', () => {
      playerRef.current.play()
    });

  },[])

  return <>
    { pageKey === PAGE_KEY.THE_DIFFERENCE_IS_CLEAR && <section className='video-hero-section'>
      <a href={button.href}>
        <video className='video-hero-section__video'
               ref={playerRef}
               src={`${ width <= 768 ? video.mobile : video.desktop}`}
               width="100%"
               height="100%"
               playsInline
               autoPlay
               muted
               loop
               poster=""/>
      </a>
      <div className='helper-right'></div>
      <CustomLink
          to={button.href}
          title={button.text}
          className="button-white video-hero-section__button"
          onClick={()=> {
            const dl = {
              url: button.href,
              referrer: location.pathname,
              name: button.text.toLowerCase()
            }

            setLearnMoreClickedEvent(dl)
          } }
      >
        {button.text}
      </CustomLink>
    </section> }
  </>
}

export default HomeHero