import React, {useState} from 'react'
import { Link } from 'gatsby'

import { linkResolver } from '../utils/linkResolver'
import { defaultLanguage } from '../../prismic-configuration'

import caFlag from '../assets/images/flags/CA.svg'

// data-layer
import {setGlobalLangAndCountryEvent} from "../assets/js/data-layer";

// constants
import {LANG} from "../constants/languages";

// components
import CountrySelector from './CountrySelector'

const LanguageSwitcher = ({ activeDocMeta }) => {

  const currentDoc = activeDocMeta
  const currentType = currentDoc.type
  const currentLang = currentDoc.lang
  const alternateDoc = activeDocMeta.alternateLanguages[0]
  const { lang } = activeDocMeta

  // country select functions
  const [showCountrySelector, setShowCountrySelector] = useState(false)
  const closeCountrySelector = () => setShowCountrySelector(false)
  const openCountrySelector = () => setShowCountrySelector(true)


  return (
    <>
        <div className="d-flex flex-column text-right">
          <button className="btn d-inline-flex" data-toggle="modal" data-target="#country-selector"
          onClick={openCountrySelector} onKeyDown={openCountrySelector}>
            <img src={caFlag} alt="CA Flag" className="mr-2" width="60px" />
            <strong>CANADA</strong>
          </button>
          <div  className="language-switch">
                {
                  currentType === 'error_page' ?
                  <ul className="language-selection">
                    <Link to='/' title="White Claw Canada EN" onClick={
                      ()=>setGlobalLangAndCountryEvent(LANG.EN)
                    }>
                      <li className={currentLang === 'en-ca' ? 'active' : ''}>EN</li>
                    </Link>
                    <Link to='/fr/' title="White Claw Canada FR" onClick={
                      ()=>setGlobalLangAndCountryEvent(LANG.FR)
                    }>
                      <li className={currentLang === 'fr-ca' ? 'active' : ''}>FR</li>
                    </Link>
                  </ul>
                  :
                  currentDoc.lang == defaultLanguage ? 
                  <ul className="language-selection">
                    <Link to={currentDoc.url} title="White Claw Canada EN" onClick={
                      ()=>setGlobalLangAndCountryEvent(LANG.EN)
                    }>
                      <li className='active'>EN</li>
                    </Link>
                    <Link to={alternateDoc ? linkResolver(alternateDoc)  :  `/404`} title="White Claw Canada FR" onClick={
                      ()=>setGlobalLangAndCountryEvent(LANG.FR)
                    }>
                      <li>FR</li>
                    </Link>
                  </ul>
                  :
                  <ul className="language-selection">
                    <Link to={alternateDoc ? linkResolver(alternateDoc) :  `/404`} title="White Claw Canada EN" onClick={
                      ()=>setGlobalLangAndCountryEvent(LANG.EN)
                    }>
                      <li>EN</li>
                    </Link>
                    <Link to={currentDoc.url} title="White Claw Canada FR" onClick={
                      ()=>setGlobalLangAndCountryEvent(LANG.FR)
                    }>
                      <li className='active'>FR</li>
                    </Link>
                  </ul>
                }
          </div>
        </div>
        <CountrySelector showCountrySelector={showCountrySelector} closeCountrySelector={closeCountrySelector} activeDocMeta={lang} />
    </>
  )
}

export default LanguageSwitcher