// core
import React from 'react'
import { SliceZone } from '@prismicio/react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

//  slices
import { components } from '../slices/all-new-products-page'

// components
import Layout from '../components/Layout'
import Seo from '../components/Seo'


const AllNewProductsPage = ({data}) => {
// console.log(data)
  const pageContent = data.prismicAllNewProductsPage
  const { meta_title, meta_description, social_card, body: slices } = data.prismicAllNewProductsPage.data


  const { lang, type, url, uid } = pageContent
  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }
  return (
      <Layout currentPage={uid} activeDocMeta={activeDoc}>
        <Seo
            title={ meta_title?.text }
            description={ meta_description?.text }
            image={ social_card.url }
            currentPage={uid}
        />
        <SliceZone slices={ slices } components={ components } />
      </Layout>
  )
}

export const query = graphql`
query AllNewProductsPageQuery ($uid: String, $id: String, $lang: String) {
    prismicAllNewProductsPage (uid: { eq: $uid }, id: { eq: $id }, lang: { eq: $lang }) {
        _previewable
        url
        uid
        type
        id
        lang
        alternate_languages {
          id
          type
          lang
          uid
        }
        data {
            social_card {
                url
            }
            meta_description {
                text
            }
            meta_title {
                text
            }
            body {
        ... on PrismicAllNewProductsPageDataBodyNewProductSection {
          id
          slice_type
          primary {
            reverse
            rtb_text {
              text
              richText
            }
            rtb_subtext {
              text
              richText
            }
            product_image {
              url
              alt
            }
            product_category
            content_description {
              text
              richText
            }
            content_background_image {
              alt
              url
            }
            button_text {
              text
              richText
            }
            button_link {
              url
            }
            title {
               text
              richText
            }
            subtitle {
              text
              richText
            }
            color
            accent_color
            text_color
            button_color
            button_hover_text_color
          }
        }
      }
     }
    }
}
`

export default withPrismicPreview(AllNewProductsPage)