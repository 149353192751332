exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-fr-404-js": () => import("./../../../src/pages/fr/404.js" /* webpackChunkName: "component---src-pages-fr-404-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-all-new-products-page-js": () => import("./../../../src/templates/all-new-products-page.js" /* webpackChunkName: "component---src-templates-all-new-products-page-js" */),
  "component---src-templates-careers-help-js": () => import("./../../../src/templates/careers-help.js" /* webpackChunkName: "component---src-templates-careers-help-js" */),
  "component---src-templates-contact-us-js": () => import("./../../../src/templates/contact-us.js" /* webpackChunkName: "component---src-templates-contact-us-js" */),
  "component---src-templates-faq-page-js": () => import("./../../../src/templates/faq-page.js" /* webpackChunkName: "component---src-templates-faq-page-js" */),
  "component---src-templates-generic-page-js": () => import("./../../../src/templates/generic-page.js" /* webpackChunkName: "component---src-templates-generic-page-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-new-product-js": () => import("./../../../src/templates/new-product.js" /* webpackChunkName: "component---src-templates-new-product-js" */),
  "component---src-templates-our-story-js": () => import("./../../../src/templates/our-story.js" /* webpackChunkName: "component---src-templates-our-story-js" */),
  "component---src-templates-our-vodka-story-js": () => import("./../../../src/templates/our-vodka-story.js" /* webpackChunkName: "component---src-templates-our-vodka-story-js" */),
  "component---src-templates-privacy-js": () => import("./../../../src/templates/privacy.js" /* webpackChunkName: "component---src-templates-privacy-js" */),
  "component---src-templates-product-details-js": () => import("./../../../src/templates/product-details.js" /* webpackChunkName: "component---src-templates-product-details-js" */),
  "component---src-templates-products-js": () => import("./../../../src/templates/products.js" /* webpackChunkName: "component---src-templates-products-js" */),
  "component---src-templates-rules-page-js": () => import("./../../../src/templates/rules-page.js" /* webpackChunkName: "component---src-templates-rules-page-js" */),
  "component---src-templates-terms-and-conditions-js": () => import("./../../../src/templates/terms-and-conditions.js" /* webpackChunkName: "component---src-templates-terms-and-conditions-js" */),
  "component---src-templates-wishpond-page-js": () => import("./../../../src/templates/wishpond-page.js" /* webpackChunkName: "component---src-templates-wishpond-page-js" */)
}

