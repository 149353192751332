import React, { useState, useEffect,createRef,useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useWindowSize from "../hooks/useWindowSize";
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'gatsby'



const SecondLevelProductNav = ({isActive,element,index, currentDropdownIndex,handleSecondLevelMenuClick,categories,closeOurProductNavBar,hoverMenu}) => {
    const productNavRef = createRef()
    const { width } = useWindowSize()
    useEffect( () => {
        if ( productNavRef?.current && width >= 991 && productNavRef.current.scrollWidth !== productNavRef.current.clientWidth) { //desktop
          const right = productNavRef.current.scrollWidth - productNavRef.current.clientWidth === Math.round( productNavRef.current.scrollLeft );
          if ( right ) {
            document.getElementById( 'product-prev-nav' ).style.visibility = 'visible'
            document.getElementById("product-prev-nav").classList.add("arrow-visible");
            document.getElementById( 'product-next-nav' ).style.visibility = 'hidden'
            document.getElementById("product-next-nav").classList.remove("arrow-visible");
          }
          const left = productNavRef.current.scrollLeft === 0
          if ( left ) {
            document.getElementById( 'product-next-nav' ).style.visibility = 'visible'
            document.getElementById( 'product-prev-nav' ).style.visibility = 'hidden'
            document.getElementById("product-next-nav").classList.add("arrow-visible");
            document.getElementById("product-prev-nav").classList.remove("arrow-visible");

          }
        }
        else{
          if(document.getElementById( 'product-next-nav' ) != null )
            {
              document.getElementById( 'product-next-nav' ).style.visibility = 'hidden'
              document.getElementById("product-next-nav").classList.remove("arrow-visible");
            }
          if(document.getElementById( 'product-prev-nav' ) != null )
            {
              document.getElementById( 'product-prev-nav' ).style.visibility = 'hidden'
              document.getElementById("product-prev-nav").classList.remove("arrow-visible")
            }
        }
      }, [ productNavRef, width] )

      const handleScrollProduct = ( e ) => {
        if ( productNavRef?.current && width >= 991) {
          //desktop
        const right = e.target.scrollLeft === e.target.scrollWidth - e.target.clientWidth
        if ( right ) {
          document.getElementById( 'product-prev-nav' ).style.visibility = 'visible'
          document.getElementById( 'product-next-nav' ).style.visibility = 'hidden'
          document.getElementById("product-prev-nav").classList.add("arrow-visible");
          document.getElementById("product-next-nav").classList.remove("arrow-visible")

        }

        const left = e.target.scrollLeft === 0;

        if ( left ) {
          document.getElementById( 'product-next-nav' ).style.visibility = 'visible'
          document.getElementById( 'product-prev-nav' ).style.visibility = 'hidden'
          document.getElementById("product-prev-nav").classList.remove("arrow-visible");
          document.getElementById("product-next-nav").classList.add("arrow-visible")
        }
      }
      }

    return (
        <li key={ "child-products" + index } className="nav-item"  onClick={(e)=>handleSecondLevelMenuClick(e,index)}
        onMouseOver={(e) => {
          if(width>=991&&hoverMenu===true) {
            handleSecondLevelMenuClick(e,index)
          }
        }}
        >
          <button className="nav-item__dd"
             onClick={(e)=>handleSecondLevelMenuClick(e,index)}
          onMouseOver={(e) => {
            if(width>=991&&hoverMenu===true) {
              handleSecondLevelMenuClick(e,index)
            }
          }}
          >
            <span className="nav-item__dd-trigger">{ element.primary?.nav_text }</span>
            <span>
                              {
                                currentDropdownIndex===index?
                                  <i className="arrow up"></i>
                                  :<i className="arrow down"></i>
                              }
                            </span>
          </button>
          { isActive &&
           <div className='sub-menu mega-menu mega-menu-column-4 product' >
            <button id="product-prev-nav" className="product-nav-scroll-arrow product-nav-scroll-arrow--prev"
                  onClick={ ( e ) => {
                    e.stopPropagation()
                    productNavRef.current.scrollLeft = 0
                    e.currentTarget.style.visibility = 'hidden'
                    e.currentTarget.classList.remove("arrow-visible");
                    if(document.getElementById( 'product-next-nav' ) != null){
                        document.getElementById( 'product-next-nav' ).classList.add('arrow-visible')
                    }
                  } }>
                  <span className="visually-hidden">Prev</span>
                  <FontAwesomeIcon icon={ faChevronLeft } className="fa-solid" size="2xl" />
                </button>
                <ul  key={ 'product nav' + index } id={ 'product nav' }ref={productNavRef}  className='sub-menu mega-menu mega-menu-column-4 product nav-scroll'  onScroll={ handleScrollProduct }  >
              {

               element.primary?.second_level_products && categories.length>0 && categories.map((childElm, childElmIndex) => {
                return (
                  <div key={"product_sections"+childElmIndex}>
                    {/* //only on mobile */ }

                    { childElmIndex === 0 && width < 991 &&
                         (
                          <div className='category-separator'>
                            <div key={ 'child' + childElmIndex  } className='product-section-container '>
                              <div>
                                <Link
                                  to={ element.primary?.all_category_link?.url }
                                  target="_self"
                                  title="White Claw"
                                  onClick={ () => {closeOurProductNavBar()} }
                                >
                                  <img src={ element?.primary?.view_all_category_image?.url } alt={ element?.primary?.view_all_category_image?.alt } />
                                </Link>
                              </div>
                              <div>
                                <h4 className='product-category-title'>
                                  <Link
                                    to={element.primary?.all_category_link?.url }
                                    target="_self"
                                    title="White Claw"
                                    onClick={ () => {
                                        closeOurProductNavBar()
                                    } }
                                  >
                                    { element.primary?.all_category_name }
                                  </Link>
                                </h4>
                              </div>
                            </div>
                          </div>
                        )
                       }
                    <li key={ 'child product' + childElmIndex } className={`list-item ${childElmIndex!== categories.length-1 ? 'product' : ''}`}>
                      <div  className='item'>
                        <h4 className='category-title'>
                          {childElm[0]?.category_name}
                        </h4>
                        <div className="vertical-line"></div>
                        <h4 className='category-title view-all-text'>
                          <Link
                            to= { "/products#" + ( childElm[0]?.product_name?.replace( '+', '').replace( ' ', '' ).toLowerCase() ) }
                            target="_self"
                            title="View All"
                            onClick={ () => {
                                closeOurProductNavBar()
                            } }
                          >
                            <div className='view-all-container'>{element?.primary?.all_category_name}</div>
                          </Link>
                        </h4>
                      </div>

                      <ul className="category-separator">
                        {
                          childElm.map( ( subCategory, subCategoryIndex ) => {
                            return (
                              <li className='product-item-desktop' key={ 'child' + subCategoryIndex }>
                                <div key={ 'child' + subCategoryIndex } className='list-item product-item '>
                                  <div>
                                    <Link
                                      to={ "/products#" + ( subCategory?.product_name && subCategory.product_name.replace( '+', '').replace( ' ', '' ).toLowerCase() ) }
                                      target="_self"
                                      title="White Claw"
                                      onClick={ () => {
                                        closeOurProductNavBar()
                                      } }
                                    >
                                      <img src={ subCategory.product_image.url } alt={ subCategory.product_image.alt } className='product-section' />
                                    </Link>
                                  </div>
                                  <div>
                                    <h4 className='product-category-title' >
                                      <Link
                                        to={ "/products#" + ( subCategory?.product_name && subCategory.product_name.replace( '+', '').replace( ' ', '' ).toLowerCase() ) }
                                        target="_self"
                                        title="White Claw"
                                        onClick={ () => {closeOurProductNavBar()} }
                                      >
                                        {
                                        subCategory.product_name.includes("+") ?
                                          subCategory.product_name.replace(/\+/g, ' +')
                                          :subCategory.product_name
                                         }
                                      </Link>
                                    </h4>
                                  </div>
                                </div>
                                {subCategory?.label &&
                                 <div className='new-tag-container'>
                                    <div className='new-tag-text'>
                                    {subCategory?.label}
                                    </div>
                                 </div>
                                 }

                              </li>
                            )
                          } )
                        }
                      </ul>
                    </li>
                  </div>
                )
                })
              }
                {/* //only on desktop */ }

            </ul>
            { width >= 991 &&
                    <div className='nav-item__button ul-all-product-link'>
                      <Link
                        to={ "/products" }
                        target="_self"
                        title="VIEW ALL PRODUCTS"
                        onClick={ () => {
                            setTimeout(()=>{
                                closeOurProductNavBar()
                            },1000)

                        } }
                      >
                        { "VIEW ALL PRODUCTS" }
                      </Link>
                    </div>
                  }
            <button id="product-next-nav" className="product-nav-scroll-arrow product-nav-scroll-arrow--next"
                  style={ {
                    visibility: productNavRef?.current &&
                      ( productNavRef?.current?.scrollWidth > productNavRef?.current?.clientWidth &&
                        productNavRef?.current?.scrollWidth - productNavRef?.current?.clientWidth !== Math.round( productNavRef?.current?.scrollLeft ) ) ? "visible" : "hidden"
                  } }
                  onClick={ ( e ) => {
                    e. stopPropagation()
                    productNavRef.current.scrollLeft = productNavRef.current.scrollWidth
                    e.currentTarget.style.visibility = 'hidden'
                    e.currentTarget.classList.remove("arrow-visible");
                    if(document.getElementById( 'product-prev-nav' ) != null){
                        document.getElementById( 'product-prev-nav' ).classList.add('arrow-visible')
                    }
                  } }>
                  <span className="visually-hidden">Next</span>
                  <FontAwesomeIcon icon={ faChevronRight } className="fa-solid" size="2xl" />
            </button>
           </div>
           }

          <FontAwesomeIcon icon={ faChevronRight } className="fa-solid nav-item__chevron_right" size="xl" aria-hidden="false"/>
        </li>
      )
}
export default SecondLevelProductNav