const CAREER_HELP_BASE_URL = {
  PROD: "https://api.markanthony.com/ConsumerResponses/CareersContact",
  DEV: "https://consumerresponses-api-dev.markanthony.com/CareersContact",
  LOCAL: "http://localhost:56432/CareersContact",
  TEST: ''
}

const CONTACT_US_BASE_URL = {
  PROD: "https://api.markanthony.com/ConsumerResponses/response",
  DEV: "https://consumerresponses-api-dev.markanthony.com/response",
  LOCAL: "http://localhost:56432/response",
  TEST: ''
}


export  { CAREER_HELP_BASE_URL, CONTACT_US_BASE_URL }