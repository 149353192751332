import React, { createContext, useContext, useState, useEffect } from 'react';
import useConsentCheck from '../hooks/useConsentCheck';

const ConsentContext = createContext();

const ConsentProvider = ({ children }) => {
  const consentAccepted = useConsentCheck();
  
  return (
    <ConsentContext.Provider value={consentAccepted}>
      {children}
    </ConsentContext.Provider>
  );
};

const useConsent = () => {
  const consentAccepted = useContext(ConsentContext);

  const [mounted, setMounted] = useState(true);

  useEffect(() => {
    return () => {
      setMounted(false);
    };
  }, []);

  return mounted ? consentAccepted : null;
};

export { ConsentProvider, useConsent };