// core
import React, {useEffect, useState} from 'react'

// prop hasTrackAnonymousId = true if embedded script has class  'wp-container' and attr 'data-wishpond-script'
const WishpondPage = ({id, hasTrackAnonymousId = true, type, isMicrosite = false}) => {
  const [wpHref, setWpHref] = useState('')

  const wpScript = "//cdn.wishpond.net/connect.js?merchantId=1484505&amp;writeKey=086605ba1b25"
  const wpCurHref = `https://embedded.wishpondpages.com/lp/${id}/`

  const wpAnalyticsScript = "https://cdn.segment.com/analytics.js/v1/qBn58ZgspH0wo5Ya3Lc0IZRT0tg4X8q4/analytics.min.js"

  const isBrowser = typeof window !== "undefined"
  const analytics = isBrowser ? window.analytics : undefined

  useEffect(() => {
    setWpHref(wpCurHref)

    //append WP script
    const script = document.createElement("script")
    script.src = wpScript
    script.async = true
    document.body.appendChild(script)

    //append segment analytics script
    const analyticScript = document.createElement("script")
    analyticScript.src = wpAnalyticsScript
    analyticScript.async = true
    document.body.appendChild(analyticScript)

    //add scroll to embedded page
    if(!isMicrosite) {
      setTimeout(() => {
        const iframe = document.getElementById(`wp-page-${id}`);
        iframe.scrolling = 'yes';
      }, 2000);
    }
  },[])


  useEffect(() => {
    // trackAnonymousId
    if(!analytics){
      return
    }

    const  anonymous_id = analytics.user().anonymousId()

    if(hasTrackAnonymousId){
      const hrefWithAnonymousID =`${wpCurHref}?anonymous_id=${anonymous_id}`
      setWpHref(hrefWithAnonymousID)

    } else {
      const message = JSON.stringify({ anonymous_id });
      document.getElementById(`wp-page-${id}`).contentWindow.postMessage(message, "*");

    }

  },[analytics])

  return (
      <div
          className="wishpond-campaign"
          data-wishpond-id={id}
          data-wishpond-href={wpHref}
          style={type !== "form" || undefined ? {height: "100vh"} : null}>
      </div>
  )
}

export default WishpondPage