export const getFontSize = (width, desktopSize, mobileSize, desktopBp = 768, mobileBp = 390, desktopDw = 1400, mobileDw = 390) =>{
    const desktopDynamic = (desktopSize / desktopDw ) * 100
    const mobileDynamic = (mobileSize / mobileDw ) * 100
    const mobileSm = mobileSize * 0.7
  
    if(!desktopSize || !mobileSize){
      return 'inherit'
    }
  
    if(width > (desktopBp - 1)){
      return `max(${desktopDynamic}vw, ${mobileSize}px)`
    }
  
    if(width => mobileBp && width <= desktopBp )  {
      return `${mobileDynamic}vw`
    }
  
    if(width < mobileBp) {
      return `${mobileSm}px`
    }
  
  }