import React from 'react'
import { useLocation } from "@reach/router";

import { Link } from 'gatsby'

//components
import ImgWithFallback from "../../components/ImgWithFallback";

// data-layer
// import { setBuyNowClickedEvent, setLearnMoreClickedEvent } from "../../assets/js/data-layer";
import { setLearnMoreClickedEvent } from "../../assets/js/data-layer";

const PromoModule = ({ slice }) => {
  const location = useLocation()
   const promoItems = slice.items
  // console.log(promoItems)
  const {title} = slice.primary

  return (
      <section className='promo-module pb-5'>
          <div className='promo-module__content'>
            <h4 className='promo-module__title'>
              {title.text}
            </h4>
            <div className='row promo-module__cards'>
            {promoItems.map(({ image,
                               subtitle,
                               button_link,
                               button_text}) => {
              return <div key={subtitle.text} className='text-center'>
                <Link
                  to={button_link.url || ''}
                  target="_blank"
                  title={subtitle.text}
                >
                  <ImgWithFallback
                    classNameImg='w-100 promo-module__image'
                    src={image.url}
                    alt={image.alt}
                    fallbackSrc={image.url}
                  />
                </Link>
                <div className='promo-module__content-wrap'>
                  <h5 className='promo-module__subtitle'>
                    {subtitle.text}
                  </h5>
                  <Link
                      to={button_link.url || ''}
                      target="_blank"
                      title={subtitle.text}
                      className="button-white promo-module__button"
                      // onClick={()=>{

                      //   if( button_link.url.includes('locator') &&
                      //       button_link.link_type === "Web") {
                      //     setBuyNowClickedEvent({ flavor: '', category: '' }, consentAccepted)
                      //   }

                      //   if(button_link.link_type === 'Document') {
                      //     const dl = {
                      //       url: button_link.url,
                      //       referrer: location.pathname,
                      //       name: button_text.text.toLowerCase()
                      //     }
                      //     setLearnMoreClickedEvent(dl, consentAccepted)
                      //   }
                      // }}
                      onClick={()=>{
                        const dl = {
                          url: button_link.url,
                          referrer: location.pathname,
                          name: button_text.text.toLowerCase()
                        }
                        setLearnMoreClickedEvent(dl)
                      }}
                  >
                    {button_text.text}
                  </Link>
                </div>
              </div>
            })}
            </div>
          </div>
      </section>
  )
}

export default PromoModule